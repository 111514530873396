<div *ngIf="badgesDefault && badgesDefault.length>0">
    <div #filterBadgesDiv style="padding:10px 0px;" class="widget-space-remove text-content scrollBarForBadges du-filter-badges-laptop"  style="display: flex;align-items: center;margin: 0px 10px 0 0;overflow-x: auto !important;">
        <span *ngIf="label" style="font-weight: 600;background-color: transparent !important;padding-left:0px;" class="chipsTag">{{label || ""}}</span>
        <span (click)="scrollLeft()" style="cursor: pointer;padding: 5px;" *ngIf="flagOnSide">
            <svg width="16" height="16" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.79059 7.11381C6.81139 7.06178 6.79965 7.00195 6.76179 6.96215L3.92262 3.99979L6.76151 1.03744C6.79965 0.997648 6.81111 0.93727 6.79058 0.885787C6.77005 0.833757 6.72125 0.799805 6.66739 0.799805L4.26742 0.799805C4.23196 0.799805 4.19808 0.814557 4.17302 0.840435L1.23972 3.9013C1.21386 3.92857 1.20078 3.96418 1.20078 3.9998C1.20078 4.03543 1.21384 4.07103 1.23972 4.09831L4.17302 7.15917C4.19808 7.18505 4.23196 7.1998 4.26742 7.1998L6.66739 7.1998C6.72125 7.19979 6.77005 7.16584 6.79059 7.11381Z" fill="#1363DF"/>
            </svg>                
        </span>
        <!-- <mat-icon (click)="scrollLeft()" style="cursor: pointer;color: grey;" *ngIf="flagOnSide">arrow_back_ios</mat-icon> -->
        <span #widgetsContent [style.width]="this.dynItemWidth"  style="overflow: auto;" class="scrollBarForBadgesInHarizandal">
            <span class="d-flex align-items-center" >
                <span *ngFor="let badge of badgesDefault" [ngClass]="{'chipsTag': badge.showBy !='no'}">
                    <span class="dropdo" style="font-weight: 600;font-size: 10px;cursor: pointer !important;" 
                        [cdkMenuTriggerFor]="badge.key == 'date_range' || badge.key == 'quarter_select' ? '' : badge.key == 'quarter_range_select' ? '' : badge.key == 'time_date' || badge.key == 'time_single_date' ? '' : menu"
                        *ngIf="badge.showBy !='no'"
                        [ngClass]="{'dropdown-toggle': badge.key != 'date_range' && badge.key != 'quarter_select' && badge.key != 'quarter_range_select' && badge.key != 'time_date' && badge.key != 'time_single_date' }"
                        (click)="selectedValues(badge)"
                    >
                        {{ badge.key == "date_range" ? "" : badge.key == 'time_date' || badge.key == 'time_single_date' ? '' : badge.key == "quarter_select" ? badge.path == "quarterSelection" ? badge.label : '' : badge.label}} 
                        {{badge.key == "quarter_select" ? (badge.selectedData?.['quarter,year'])  : ""}}
                        {{badge.key == "quarter_range_select" ? (badge.selectedData?.['quarter,year'])  : ""}}
                        {{badge.key == "time_date" ? (badge.selectedData?.['date'])  : ""}}
                        {{badge.key == "time_single_date" ? (badge.selectedData?.['date'])  : ""}}
                    
                        <span *ngIf="badge.length=='singleDateSelection'">{{badge.value}}</span>
                        <span *ngIf="badge.length=='newMultiSelect'" class="chipBgColor">{{ badge.value }}</span>


                        <span 
                            *ngIf="badge.length != '' && badge.key != 'quarter_select' && badge.key != 'quarter_range_select' && badge.length!='singleDateSelection' && badge.length!='newMultiSelect'"
                            [ngClass]="{'chipBgColor' : (badge['key'] != 'date_range') && (badge.key != 'quarter_select') && (badge.key != 'quarter_range_select') && (badge.key != 'time_date') && (badge.key != 'time_single_date')}" 
                            [style.background-color]="badge.value!=badge.length && (badge['key'] != 'date_range') && (badge.key != 'quarter_select') && (badge.key != 'quarter_range_select') && (badge.key != 'time_date') && (badge.key != 'time_single_date') ? '#FF9A3D' : ''" 
                            class="chipBgColor" 
                        >
                            {{badge.value}}{{badge.length!=""? (badge['key'] == 'date_range' ? " - " : badge['key'] == 'time_date' || badge['key'] == 'time_single_date' ? ' - ' : "/") + badge.length : ""}}
                        </span>
                        
                        <!-- <ul class="dropdown-menu" style="margin: 5px 0 !important;" [ngStyle]="{'border' : localData.length == 0 ? 'none' :''}" >
                            <li class="p-1" style="background-color: #F5F8FA;padding: 5px !important;margin: 2px;" *ngIf="localData.length != 0">Selected Values</li>
                            <li class="dropdown-item disabled" *ngFor="let a of localData">
                                {{ a.value ? a.value : '' }}
                            </li>
                        </ul> -->
            
                    </span>
                </span>
            </span>
        </span>
        <span (click)="scrollRight()" style="cursor: pointer;padding: 5px;" *ngIf="flagOnSide">
            <svg width="16" height="16" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.20941 7.11381C1.18861 7.06178 1.20035 7.00195 1.23821 6.96215L4.07738 3.99979L1.23849 1.03744C1.20035 0.997648 1.18889 0.93727 1.20942 0.885787C1.22995 0.833757 1.27875 0.799805 1.33261 0.799805L3.73258 0.799805C3.76804 0.799805 3.80192 0.814557 3.82698 0.840435L6.76028 3.9013C6.78614 3.92857 6.79922 3.96418 6.79922 3.9998C6.79922 4.03543 6.78616 4.07103 6.76028 4.09831L3.82698 7.15917C3.80192 7.18505 3.76804 7.1998 3.73258 7.1998L1.33261 7.1998C1.27875 7.19979 1.22995 7.16584 1.20941 7.11381Z" fill="#1363DF"/>
            </svg>                
        </span>
        <!-- <mat-icon (click)="scrollRight()" style="cursor: pointer;color: grey;" *ngIf="flagOnSide"> arrow_forward_ios</mat-icon> -->
        <span *ngIf="this.filterService.badgeCheck" style="background-color: rgba(209, 16, 68, 0.1);font-size: 10px;font-weight: 600;" class="chipsTag">
            <span style="position: relative;color: #D11044;cursor: pointer;">
                <mat-icon (click)="resetFIlter()" class="matColorstyle" [svgIcon]="xMark"></mat-icon>
                <span (click)="resetFIlter()">Reset</span>
            </span>
        </span>
    </div>
    <div #filterBadgesDiv style="padding:10px 0px;" class="widget-space-remove text-content scrollBarForBadges du-filter-badges-mobile"  style="display: flex;align-items: center;margin: 0px 10px 0 0;overflow-x: auto !important;">
        <span *ngIf="label" style="font-weight: 600;background-color: transparent !important;padding-left:0px;" class="chipsTag">{{label || ""}}</span>
        <span (click)="scrollLeft()" style="cursor: pointer;padding: 5px;" *ngIf="flagOnSide">
            <svg width="16" height="16" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.79059 7.11381C6.81139 7.06178 6.79965 7.00195 6.76179 6.96215L3.92262 3.99979L6.76151 1.03744C6.79965 0.997648 6.81111 0.93727 6.79058 0.885787C6.77005 0.833757 6.72125 0.799805 6.66739 0.799805L4.26742 0.799805C4.23196 0.799805 4.19808 0.814557 4.17302 0.840435L1.23972 3.9013C1.21386 3.92857 1.20078 3.96418 1.20078 3.9998C1.20078 4.03543 1.21384 4.07103 1.23972 4.09831L4.17302 7.15917C4.19808 7.18505 4.23196 7.1998 4.26742 7.1998L6.66739 7.1998C6.72125 7.19979 6.77005 7.16584 6.79059 7.11381Z" fill="#1363DF"/>
            </svg>                
        </span>
        <!-- <mat-icon (click)="scrollLeft()" style="cursor: pointer;color: grey;" *ngIf="flagOnSide">arrow_back_ios</mat-icon> -->
        <span #widgetsContent [style.width]="this.dynItemWidth"  style="overflow: auto;" class="scrollBarForBadgesInHarizandal">
            <span class="d-flex align-items-center" >
                <span *ngFor="let badge of badgesDefault" [ngClass]="{'chipsTag': badge.showBy !='no'}">
                    <span class="dropdo" style="font-weight: 600;font-size: 10px;cursor: pointer !important;" 
                        [cdkMenuTriggerFor]="badge.key == 'date_range' || badge.key == 'quarter_select' ? '' : badge.key == 'quarter_range_select' ? '' : badge.key == 'time_date' || badge.key == 'time_single_date' ? '' : menu"
                        *ngIf="badge.showBy !='no'"
                        [ngClass]="{'dropdown-toggle': badge.key != 'date_range' && badge.key != 'quarter_select' && badge.key != 'quarter_range_select' && badge.key != 'time_date' && badge.key != 'time_single_date' }"
                        (click)="selectedValues(badge)"
                    >
                        {{ badge.key == "date_range" ? "" : badge.key == 'time_date' || badge.key == 'time_single_date' ? '' : badge.key == "quarter_select" ? badge.path == "quarterSelection" ? badge.label : '' : badge.label}} 
                        {{badge.key == "quarter_select" ? (badge.selectedData?.['quarter,year'])  : ""}}
                        {{badge.key == "quarter_range_select" ? (badge.selectedData?.['quarter,year'])  : ""}}
                        {{badge.key == "time_date" ? (badge.selectedData?.['date'])  : ""}}
                        {{badge.key == "time_single_date" ? (badge.selectedData?.['date'])  : ""}}
                    
                        <span *ngIf="badge.length=='singleDateSelection'">{{badge.value}}</span>
                        <span *ngIf="badge.length=='newMultiSelect'" class="chipBgColor">{{ badge.value }}</span>

                        <span 
                            *ngIf="badge.length != '' && badge.key != 'quarter_select' && badge.key != 'quarter_range_select' && badge.length!='singleDateSelection' && badge.length!='newMultiSelect'"
                            [ngClass]="{'chipBgColor' : (badge['key'] != 'date_range') && (badge.key != 'quarter_select') && (badge.key != 'quarter_range_select') && (badge.key != 'time_date') && (badge.key != 'time_single_date')}" 
                            [style.background-color]="badge.value!=badge.length && (badge['key'] != 'date_range') && (badge.key != 'quarter_select') && (badge.key != 'quarter_range_select') && (badge.key != 'time_date') && (badge.key != 'time_single_date') ? '#FF9A3D' : ''" 
                            class="chipBgColor" 
                        >
                            {{badge.value}}{{badge.length!=""? (badge['key'] == 'date_range' ? " - " : badge['key'] == 'time_date' || badge['key'] == 'time_single_date' ? ' - ' : "/") + badge.length : ""}}
                        </span>
                        
                        <!-- <ul class="dropdown-menu" style="margin: 5px 0 !important;" [ngStyle]="{'border' : localData.length == 0 ? 'none' :''}" >
                            <li class="p-1" style="background-color: #F5F8FA;padding: 5px !important;margin: 2px;" *ngIf="localData.length != 0">Selected Values</li>
                            <li class="dropdown-item disabled" *ngFor="let a of localData">
                                {{ a.value ? a.value : '' }}
                            </li>
                        </ul> -->
            
                    </span>
                </span>
            </span>
        </span>
        <span (click)="scrollRight()" style="cursor: pointer;padding: 5px;" *ngIf="flagOnSide">
            <svg width="16" height="16" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.20941 7.11381C1.18861 7.06178 1.20035 7.00195 1.23821 6.96215L4.07738 3.99979L1.23849 1.03744C1.20035 0.997648 1.18889 0.93727 1.20942 0.885787C1.22995 0.833757 1.27875 0.799805 1.33261 0.799805L3.73258 0.799805C3.76804 0.799805 3.80192 0.814557 3.82698 0.840435L6.76028 3.9013C6.78614 3.92857 6.79922 3.96418 6.79922 3.9998C6.79922 4.03543 6.78616 4.07103 6.76028 4.09831L3.82698 7.15917C3.80192 7.18505 3.76804 7.1998 3.73258 7.1998L1.33261 7.1998C1.27875 7.19979 1.22995 7.16584 1.20941 7.11381Z" fill="#1363DF"/>
            </svg>                
        </span>
        <!-- <mat-icon (click)="scrollRight()" style="cursor: pointer;color: grey;" *ngIf="flagOnSide"> arrow_forward_ios</mat-icon> -->
        <span *ngIf="this.filterService.badgeCheck" style="background-color: rgba(209, 16, 68, 0.1);font-size: 10px;font-weight: 600;" class="chipsTag">
            <span style="position: relative;color: #D11044;cursor: pointer;">
                <mat-icon (click)="resetFIlter()" class="matColorstyle" [svgIcon]="xMark"></mat-icon>
                <span (click)="resetFIlter()">Reset</span>
            </span>
        </span>
    </div>
</div>

<!-- {{badgesDefault | json}} -->

<ng-template #menu>
    <div class="dropdownMenu" cdkMenu>
        <div style="background-color: #F5F8FA;padding: 10px 5px !important;margin: 2px;font-weight: 600;">Selected Values</div>
        <div class="p-1 m-1">
            <cdk-virtual-scroll-viewport  class="overScrollMenu dynamicHeigh" itemSize="20" style="width: 200px;" [style.height]="localData.length > 9 ? '250px' : (localData.length * 26)+'px'">
                <ng-container *cdkVirtualFor="let alt of localData; let i = index;trackBy: trackByFnMessage;">
                    <div class="disabled">
                        {{ alt.value ? alt.value : '' }}
                    </div>
                </ng-container>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</ng-template>