<div #areaLine style="font-family: Poppins !important">
    <main>
      <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">
        <!-- <button mat-raised-button color="primary" (click)="closeFullscreen()" *ngIf="isFullscreen">Exit</button> -->
        

        <div style="background-color: #ffff;">
          <icon-chart 
            [headerConfig]="headerConfig"
            [selectedTypeTimeCyle]="selectedType"
            [iconList]="iconList" 
            [data]="data" 
            [pageKey]="pageKey" 
            [heading]="heading"
            chartName="stackedComboBar" 
            [config]="areaLineChart" 
            [expand]="fs" 
            [fullscreen]="isFullscreen"
            (screenChanges)="closeFullscreen($event)"
            (timeCycleChanges)="timecycleChange($event)"
            [item]="item"
          ></icon-chart>

        </div>
        <loading *ngIf="isLoading"> </loading>
        <div #areaLineChart [ngClass]="{'fullScreenScroll' : this.isFullscreen}">
            <div [style.display]="noData?'none':''" id="areaLineChartDiv"></div>
      <div style="height: 500px; width: auto; " *ngIf="noData">
       <span *ngIf="!isLoading" class="bg-text" >There is no data for this time period</span></div>
            <div id="d3AreaLineTooltip">
              <div class="container chart_tooltip"  *ngIf="isTooltip">
                <div class="row chart_tooltip_header">
                  <div class="col-12 d-flex flex-column mt-1 mb-1">
                    
                    <div class="chart_tooltip_header_heading"> Defects By Brand ({{ mytooltipData['date'] | date: 'dd-MMM-yyyy' }})</div>
                  </div>
                </div>
                <div class="mt-2 mb-5 pb-1" *ngIf="height > 200" [ngClass]="(dataTurn < 350) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
                    <div class=" col-12 d-flex m-auto align-items-center pl-0" *ngFor="let data of mytooltipData.data ">
                     
                      <div>
                        <div class="tooltip_outlay" [style.background]="data.color" ></div>
                      </div>  
                      <div class="chart_tooltip_body_data ml-2"> {{data.name}} : {{data?.value ? numbedPipe(data?.value) : 0}}   </div>
                    </div> 
                  </div> 

                <div class="mt-2 mb-5 pb-1" *ngIf="height < 200" [ngClass]="(dataTurn < 350) ? 'chart_tooltip_body-after100' :'chart_tooltip_body100'">
                  <div class=" col-12 d-flex m-auto align-items-center pl-0" *ngFor="let data of mytooltipData.data ">
                     
                    <div>
                      <div class="tooltip_outlay" [style.background]="data.color" ></div>
                    </div>  
                    <div class="chart_tooltip_body_data ml-2"> {{data.name}} : {{data?.value ? numbedPipe(data?.value) : 0}}   </div>
                  </div> 
                    <!-- <div class="col-12 d-flex m-auto align-items-center pl-0">
                     
                      <div>
                        <div class="tooltip_outlay" [style.background]="'#FF7715'" ></div>
                      </div>  
                      <div class="chart_tooltip_body_data ml-2"> {{'Failures '}} : {{mytooltipData?.['F']  ? numbedPipe(mytooltipData?.['F'] )  : 0 }}   </div>
                    </div>  
                      <div class="col-12 d-flex m-auto align-items-center pl-0">
                      <div>
                        <div class="tooltip_outlay" [style.background]="'#FFD951'" ></div>
                      </div> 
                      <div class="chart_tooltip_body_data ml-2"> {{'Errors '}}  :  {{mytooltipData?.['E']  ? numbedPipe(mytooltipData?.['E'] )  : 0 }}  </div>
                    </div> 
                    <div class=" col-12 d-flex m-auto align-items-center pl-0">
                      <div>
                        <div class="tooltip_outlay" [style.background]="'#0BBF1D'"></div>
                      </div>
                      <div class="chart_tooltip_body_data ml-2"> {{'AVG Resolution time-Failures (IN DAYS) '}} : {{mytooltipData?.['AVG RT-F']  ? numbedPipe(mytooltipData?.['RT-F'] )  : 0 }}   </div>
                    </div>
                    <div class="col-12 d-flex m-auto align-items-center pl-0">
                       <div>
                        <div class="tooltip_outlay" [style.background]="'#C90F0F'"></div>
                      </div> 
                     
                      <div class="chart_tooltip_body_data ml-2"> {{'AVG Resolution time-Errors (IN DAYS) '}} : {{mytooltipData?.['AVG RT-E']  ? numbedPipe(mytooltipData?.['RT-E'] )  : 0 }}  </div>
                    </div> -->
                </div>
      
              </div>
            </div>

       </div>
      </article>
    </main>
  </div>