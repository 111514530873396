<div class="container" style="width: 400px;">
    <div class="my-2">
        <div class="d-flex justify-content-between align-items-center">
            <div>
              <h2 style="font-family: 'poppins';margin:0px !important;line-height: 15px;font-weight: 600;font-size: 16px;">{{"Select"}}</h2>
            </div>
            <div class="p-2" style="margin: 0 0 -5px 0px !important;">
              <mat-icon (click)="closeInst()" class="close-icon" style="color: rgba(138, 152, 171, 1);cursor: pointer;" >close</mat-icon>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-md-12 col-12">
                <ul class="list-group">
                    <li class="list-group-item d-flex align-items-center" style="gap: 10px; cursor: pointer;">
                        <input type="checkbox" style="cursor: pointer;" [(ngModel)]="masterSelected"
                            name="list_name" value="m1"
                            (change)="checkUncheckAll()" /> 
                        <strong (click)="masterSelected=!masterSelected; checkUncheckAll()">Select/Unselect All</strong>
                    </li>
                </ul>
                <ul class="list-group du-server-list-group">
                    <li class="list-group-item d-flex align-items-center" *ngFor="let item of checklist" style="gap: 10px; cursor: pointer;">
                        <input type="checkbox" style="cursor: pointer;" [(ngModel)]="item['isExportSelected']"
                            name="list_name" [value]="item.field"
                            (change)="isAllSelected()" />
                        <span (click)="item['isExportSelected']=!item['isExportSelected']; isAllSelected()">{{item.headerName}}</span> 
                    </li>
                </ul>
            </div>
            <div style="text-align: right;margin: 10px 0;">
                <button mat-raised-button (click)="selectedExport()">Export</button>
            </div>
        </div>
    </div>
</div>