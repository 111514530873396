import { Component, ElementRef, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { catchError, of } from 'rxjs'
import * as _ from 'lodash'
import { StatusOverview , Legend, Tree } from '@dataunveil/ducharts'

import dem from './dem/config'
import dpm from './dpm/config'
import { Modal as ModalDPM } from './dpm/modal'
import { Modal as ModalDEM } from './dem/modal'
import { TooltipDEM } from './dem/tooltip'
import { TooltipDPM } from './dpm/tooltip'
import moment from 'moment'
import { ChartsModule } from '../../shared/charts/charts.module'
import { ProgressIndefinite } from '../../shared/progress-indicator/indefinite'
import Widget from '../widget'
import { RouterHandlerService } from 'src/app/services/router-handler.service'
import { NavigationService } from 'src/app/services/navigation.service'

const widgetConfigs: { [key: string]: any } = {
  dem: {
    config: dem,
    modal: ModalDEM,
    tooltip: TooltipDEM,
    dateFormat: 'yyyy-MM-DD',
  },
  dpm: {
    config: dpm,
    modal: ModalDPM,
    tooltip: TooltipDPM,
    dateFormat: 'DD-MM-YY',
  },
  ffm: {
    config: dpm,
    modal: ModalDPM,
    tooltip: TooltipDPM,
    dateFormat: 'DD-MM-YY',
  }
}

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule,StatusOverview, Tree, Legend,
    MatButtonModule, MatInputModule, MatSelectModule, MatFormFieldModule, MatTooltipModule, MatIconModule,
    TooltipDEM, TooltipDPM, ChartsModule, ProgressIndefinite],
  selector: 'app-status-overview',
  templateUrl: './status-overview.html',
  styleUrls: ['./status-overview.scss', '../widget.scss']
})
export class StatusOverviewWidget extends Widget {
  static override key = 100707
  dialog: any
  @ViewChild("dataGridDiv", { static: true }) dataGridDiv!: ElementRef;
  @ViewChild('fs') fs!: ElementRef;
  isActive = false;
  window=window;
 
  source: any
  clientKey: any
  moduleKey: any
  moduleName:any
  dashboardKey: any
  sourceName: any
  historyFlag:any;
  staticLegend = false;
  staticLegendItems:any=[];

  constructor(private routerHandlerService: RouterHandlerService) {
    super();

    this.route.params.subscribe((p: any) => {

      this.moduleName = p['module'] || '';
      this.source = p["cardName"] || '';

      this.route.queryParams.subscribe((params: any) => {
        let decryptedParams = this.navigationService.decryptData(params);

        this.clientKey = decryptedParams["cl_key"];
        this.moduleKey = decryptedParams["md_key"];
        this.dashboardKey = decryptedParams["ds_key"];
        this.historyFlag = decryptedParams?.["hist_flg"] || undefined;
      });
    });

  }

  override ngOnInit () {
    super.ngOnInit()
    const type = this.item.config['tool_tip_type'].value
    const chartType = this.item.config['type_of_page'].value
    
    this.staticLegend = this.item.config?.['static_legend']?.value == 'true' || false;
    
    if(this.staticLegend) {
      this.staticLegendItems = this.item.config?.['legend_items'] || [];
      this.staticLegendItems = this.staticLegendItems.filter((f:any)=> f.selected);
    } else {
      this.staticLegendItems = [];
    }
    
    this.config = widgetConfigs[type].config
    this.subs.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.widgetService.set(this.item, { name: 'resetState' })
        this.isLoading = true
        this.ViewTypeChanges()
        this.loadApiData()
        this.loadOrder()
      })
    )
    
    const itemAccessors = []
    for (let i = 1; i < 4; i++) {
      const element = this.item.config['l' + i + '_col']?.['api_resp_column_nm']
      if (element != undefined) {
        itemAccessors.push(element)
      }
    }
    const colorScheme: string[] = []
    const statuses: string[] = []
    this.item.config['expected_values'].map((e: any) => {
      if (e.isSelected) {
        if (this.item.config.list_of_api.api_key == 200101) statuses.push(e.from_values.toUpperCase())
        else statuses.push(e.from_values)
        colorScheme.push(e.color)
      }
    })

    _.merge(this.config.statusOverview.view, {
      colorScheme,
      statuses,
      itemAccessors,
      timeAccessor: this.item.config['x_axis_column']['api_resp_column_nm'],
      statusAccessor: this.item.config['color_column']['api_resp_column_nm'],
    });
  }

  // View Type changes for calender or table
  ViewTypeChanges():any{
    // debugger
    if(this.filterService.isEmptyObject(this.filterService.baseQuery)) return;
    let filterPayload:any = this.filterService.baseQuery;
    
    const startDate:any    =  new Date(filterPayload.from_date);
    const endDate:any      =  new Date(filterPayload.to_date);
    const differenceInMs   =  endDate - startDate;
    const differenceInDays:number = ( differenceInMs / (1000 * 60 * 60 * 24))+1;
    const tableCount:number = parseInt(this.item?.config?.['table_count']||16)

    if(this.item.config['type_of_page'].value=="ffm" || this.historyFlag=='H'){
      this.config.tree = _.merge({}, this.config.tree, { view: { selectable: (differenceInDays <= tableCount) ? false : true } })
      this.config.statusOverview = _.merge({}, this.config.statusOverview, {view: { viewType: (differenceInDays <= tableCount) ? 'table' : 'calendar'}});
      // calendarThreshold: (differenceInDays <= 15) ? 15 : undefined 
    } else{
      this.config.tree = _.merge({}, this.config.tree, { view: { selectable: false } })  
      this.config.statusOverview = _.merge({}, this.config.statusOverview, {view: { viewType: (differenceInDays <= tableCount) ? 'table' : 'calendar'}});
    }
    // console.log(differenceInDays,this.config.statusOverview.view.viewType, this.config.tree.view.selectable);

  }

  loadApiData() {
    const apiConfig = this.item.config.list_of_api
    if (!apiConfig || this.filterService.isEmptyObject(this.filterService.baseQuery)) return

    const query = Object.assign({}, this.filterService.baseQuery, {
      api_key: this.item.config.list_of_api.api_key,
      report_typ: this.filterService.report_type,
    })

    this.subs.push(
      this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).pipe(catchError(e => {
        this.data = [];
        this.isLoading = false;
        return of([]);
      }),)
      .subscribe((data: any) => {
        // debugger
        const type = this.item.config['tool_tip_type'].value;
        const commentType = this.item.config['tool_tip_type'].name;
        const dateFormat = widgetConfigs[type].dateFormat
        
        data.forEach((r: any) => {
         let key = commentType == 'DPM' ? 'cal_day' : 'cycle_dt'
          Object.assign(r, {[key]: moment( commentType == 'DPM' ?r.cal_day:r.cycle_dt).format(dateFormat) })
        })

        this.data = commentType == 'DPM' ? data :this.trendFormater(data);
        this.isLoading = false;
        this.config.tree = _.merge({}, this.config.tree, { view: { collapsedAll: true } });
        this.onChartLoad();
      })
    )
  }

  // click event for tiles single or double
  onChartLoad () {
    // click contant
    this.subs.push(this.chartService.get(this.config.statusOverview.id, 'selectedCells').subscribe((state: any) => this.toggleModal(state.selectedCells)))

    // dblclick contant
    this.subs.push(this.chartService.get(this.config.statusOverview.id, 'dblselectedCells').subscribe((state: any) =>  this.dbltoggleModal(state.dblselectedCells)))
  }

  loadOrder () {
    let obj = { api_key: '100280' }
    let query = Object.assign({}, this.filterService.baseQuery, obj)
    query['report_typ'] = this.filterService.report_type;
    query['comment_typ'] = this.item.config["type_of_page"].name || 'DPM';
    
    this.httpClient.post(`${ this.apiService.apiBaseUrl }/${ this.apiService.executionUrl }`, query)
      .pipe(catchError(e => of([])))
      .subscribe((res: any) => {
        if (!res.length) return
        
        const mergeDesignWithApi:any = [
          {'colDesigner': 'l1_col', 'colResponse': 'level_1'},
          {'colDesigner': 'l2_col', 'colResponse': 'level_2'},
          {'colDesigner': 'l3_col', 'colResponse': 'level_3'},
          {'colDesigner': 'l4_col', 'colResponse': 'level_4'},
          {'colDesigner': 'l5_col', 'colResponse': 'level_5'},
          {'colDesigner': 'l6_col', 'colResponse': 'level_6'},
          {'colDesigner': 'l7_col', 'colResponse': 'level_7'},
        ]

        const itemAccessors:any = [];
        const itemsOrder:any = {};

        mergeDesignWithApi.forEach((f:any)=> {
          if(this.item.config[f.colDesigner]) {
            itemAccessors.push(this.item.config[f.colDesigner].api_resp_column_nm);
            
            if(res[0][f.colResponse]) {
              itemsOrder[this.item.config[f.colDesigner].api_resp_column_nm] = res[0][f.colResponse].split(',');
            }
          }
        })

        // console.log(itemAccessors, itemsOrder)
        this.config.statusOverview.view = Object.assign(this.config.statusOverview.view, { itemsOrder }, {itemAccessors});
      })
  }

  // fucntion for single click
  toggleModal (cells: any) {
  
    if((this.item.config['type_of_page'].value==="ffm" || this.historyFlag=='H') && this.config.statusOverview.view.viewType!='table'){cells=[cells]};
    
    if(cells==undefined)return;
    this.dialogServiceMat.closeAll();
    if (!cells?.length) return;
    if (_.isEmpty(cells[0].details)) return this.onModalClose();
    
    // if(this.item.config?.['navigation'] && this.item.config?.['navigation']?.value=='true'){ this.detailNav(cells[0].details); return }

    const type = this.item.config['tool_tip_type'].value;

    this.dialog = this.dialogServiceMat.open(widgetConfigs[type].modal, {
      disableClose: true,
      data: {
        data: cells[0],
        item: this.item,
        dateFormat: widgetConfigs[type].dateFormat,
      }
    })
    this.dialog.afterClosed().subscribe(this.onModalClose.bind(this))
  }
  
  // function for double clicks
  dbltoggleModal (cells: any) {
    if(cells==undefined)return;
    this.dialogServiceMat.closeAll();
    if (!cells?.length) return;
    if (_.isEmpty(cells[0].details)) return this.onModalClose();
    if(this.item.config?.['navigation'] && this.item.config?.['navigation']?.value=='true'){ this.detailNav(cells[0].details); return };
  }

  // navigation method
  detailNav(record: any) {
    let  key:any = Object.keys(record)

    const chartType :string = this.item.config['tool_tip_type'].value
    const dateFormat = widgetConfigs[chartType].dateFormat

      record = record[key[0]]
   
    let myData:any = record;
    myData['file_type_dim_key'] = record.file_type_key;
    myData['cycle_dt'] = record.period;
    myData['file_nm'] = key[0] || record.file_nm;

    let header:any={
      'cycle_dt':moment(record.period ,dateFormat).format('DD MMM ')+' ',
      'file_type_nm':record.file_type+' - ',
      'source_name':record.source_name+' - '
    }
    
    let strQuery = '';
    for(let key in header) {
      if(header[key]) {
        strQuery += header[key]
      }
    }

    // Set Navigation
    const config = this.item.config?.['nav_configuration'].controls?.[0];
    let nav = {
      dbJSON: config?.dbJSON,
      s3JSON: config?.s3JSON,
      pageName: config?.pageName,
      isDashboardLevel: config?.isDashboardLevel,
      isNavSelected:config?.isNavSelected,
      navpageName:config?.navpageName,//router var keys
      navpageNameLeft:config?.navpageNameLeft|| '',
      navpageNameRight:config?.navpageNameRight|| ''
    }
    sessionStorage.setItem("navDetailInfo", JSON.stringify(nav));
    sessionStorage.setItem("navHeaderData", JSON.stringify(header));
    this.routerHandlerService.storingPayloadAndFilterForGrid(config, myData, '');
    // this.sourceName = myData['source_nm'];

    let query = {
      tab_id: 0,
      cl_key: this.clientKey,
      md_key: this.moduleKey,
      ds_key: this.dashboardKey,
      hd_flg:record.show_grid_flg||'Y',
      f_q: strQuery
    }

    this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${nav.pageName}`, query);
  }

  // expand & collapse   method 
  toggleAll(event:any) {
    this.config.tree = _.merge({}, this.config.tree, { view: { collapsedAll: !event }});
    // { view: { collapsedAll: !this.config.tree.view?.collapsedAll } })
  }

  // refresh the grid when closed;
  onModalClose () {
    this.config.statusOverview = _.merge({}, this.config.statusOverview, { view: { selectedCells: [] } });
    
    const commentType = this.item.config['tool_tip_type'].name;
    
    if (this.filterService.currentMsgOnDM?.length > 0) {
      let flg_Da = this.filterService.currentMsgOnDM[0]
    
      this.data.forEach((res: any) => {
        if (res?.source_key == flg_Da?.data_source_key && moment((commentType == 'DPM' ?  res?.cal_day : res?.cycle_dt), commentType == 'DPM' ? 'DD-MM-YY' : 'yyyy-MM-DD').format('yyyy-MM-DD') == moment(flg_Da?.period).format('yyyy-MM-DD') && res?.['file_type_key'] == flg_Da?.['file_type_key']) {
          Object.assign(res, { 'comment_flg': 'Y' })
        }
      })
    } else {
      let flg = this.filterService.currentMsgOnDM
    
      this.data.forEach((res: any) => {
        if (res?.source_key == flg?.source_key && moment(commentType == 'DPM' ?  res?.cal_day : res?.cycle_dt, commentType == 'DPM' ? 'DD-MM-YY' : 'yyyy-MM-DD').format('yyyy-MM-DD') == moment(flg?.period, commentType == 'DPM' ? 'DD-MM-YY' : 'yyyy-MM-DD').format('yyyy-MM-DD') && res?.['file_type_key'] == flg?.['file_type_key']) {
          Object.assign(res, { 'comment_flg': 'N' })
        }
      })
    }
  }

  trendFormater(data:any):any{
    // Sort data by file_type and cycle_dt
    data.sort((a: any, b: any) => {
      if (a.file_type === b.file_type) {
        let dat: number = +new Date(a.cycle_dt) - +new Date(b.cycle_dt);
        return dat;
      }
      return a.file_type.localeCompare(b.file_type);
    });

    for (let i = 0; i < data.length; i++) {
      if (i > 0 && data[i].file_type === data[i - 1].file_type) {
        const prevSentRecords = data[i - 1].sent_records;
        const prevOnHold = data[i - 1].on_hold;

        const currentSentRecords = data[i].sent_records;
        const currentOnHold = data[i].on_hold;

        // Determine the trend for sent_records
        if (currentSentRecords > prevSentRecords) {
          data[i].sent_records_trend = 'up';
        } else if (currentSentRecords === prevSentRecords) {
          data[i].sent_records_trend = 'nochange';
        } else {
          data[i].sent_records_trend = 'down';
        }

        // Determine the trend for on_hold
        if (currentOnHold > prevOnHold) {
          data[i].on_hold_trend = 'up';
        } else if (currentOnHold === prevOnHold) {
          data[i].on_hold_trend = 'nochange';
        } else {
          data[i].on_hold_trend = 'down';
        }
      } else {
        data[i].sent_records_trend = 'no trend';
        data[i].on_hold_trend = 'no trend';
      }
    }
    // console.log(data);
    return data
  }
}