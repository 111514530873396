<mat-toolbar style="background: #fff;" class="d-flex justify-content-between DU_topbar">
    <button mat-icon-button class="example-icon mr-3 du-side-bar-menu-talet" aria-label="Example icon-button with menu icon">
      <mat-icon (click)="menuBar()">menu</mat-icon>
    </button>

    <img *ngIf="modulePage && clientLogo" class="du-topnav-logo-desktop" style="width: 150px;aspect-ratio: 1/0.35;" [src]="styleLogo(clientLogo)" alt="logo"  (click)="logoclicktoBack()">
    <img *ngIf="!modulePage && clientLogo" class="du-topnav-logo-desktop" style="width: 150px;aspect-ratio: 1/0.35;" [src]="styleLogo(clientLogo)" alt="logo"  (click)="logoclicktoBack()">

    <span *ngIf="!modulePage" class="d-flex justify-content-between align-items-center">
        <mat-icon class="arrow_back_css du-topnav-arrow-back" (click)="previousPage()">arrow_back</mat-icon>
        <span class="title mx-2" class="nameOfThePage nameOfThePageIN du-topnav-page-name du-header-font-size" *ngIf="locationName ? false : true"> {{ pagename  || '' }}</span>
    </span>
    
    <span class="du-topnav-bar-desktop">
        <span *ngIf="routerLoader && locationName ? false : true && prName ? true : false" class="verticalLine mx-4"></span>
    </span>

    <span *ngIf="routerLoader" class="du-topnav-bar-desktop">
        <span class="titleprcard mx-2" *ngIf="locationName ? true : false"> {{locationName}}</span>
        <span class="titleprcard mx-2" *ngIf="locationName ? false : true"> {{ prName | titlecase}}</span>
    </span>

    <span *ngIf="selectedBrandName">
        <span class="verticalLine mx-3"></span>
        <span class="title mx-2" > {{ selectedBrandName }}</span>
    </span>

    <span *ngIf="this.refreshTheDashboard" class="refreshedLastdate">
        <button  matTooltip="Refresh" mat-button style="min-width: 0 !important; padding: 0 !important;line-height: 0px;color: #1363DF;" (click)="getRefreshData()">
            <mat-icon [class.rotate]="refreshPageData">
                refresh
            </mat-icon>
        </button>
    </span>
    
    <span class="example-spacer"></span>

    <!-- <button mat-raised-button (click)="pptExport()">Export PPT</button> -->

    <span *ngIf="this.filterService.refreshData && moduleKey != 100101" class="refreshedLastdate du-topnav-bar-refreshed-date">
        <mat-icon style="width: 17px; height: 17px;" svgIcon="data_refreshed"></mat-icon>        
        <span class="mx-1">
            Last refreshed:
        </span>
        <span style="color: #000 !important">
            {{refreshDate ? refreshDate + ' ET' : 'No Date'}}
        </span>
    </span>
    
    <span *ngIf="this.filterService.refreshData && moduleKey != 100101" class="refreshedLastdate du-topnav-bar-refreshed-date-mobile">
        <mat-icon style="width: 17px; height: 17px;" [matTooltip]="refreshDate ? refreshDate + ' ET' : 'No Date'" svgIcon="data_refreshed"></mat-icon>
    </span>
    
    <span *ngIf="this.filterService.refreshData && moduleKey == 100101" class="refreshedLastdate du-topnav-bar-refreshed-date">
        <mat-icon style="width: 17px; height: 17px;" svgIcon="data_refreshed"></mat-icon>        
        <span class="mx-1">
            Last refreshed:
        </span>
        <span style="color: #000 !important">
            {{ lastRefreshDate ?  lastRefreshDate + ' ET' : 'No Date'}}
        </span>
    </span>
    
    <span *ngIf="this.filterService.refreshData && moduleKey == 100101" class="refreshedLastdate du-topnav-bar-refreshed-date-mobile">
        <mat-icon style="width: 17px; height: 17px;" [matTooltip]="lastRefreshDate ?  lastRefreshDate + ' ET' : 'No Date'" svgIcon="data_refreshed"></mat-icon>        
    </span>
    
    <!-- <span style="padding: 5px;">
        <mat-icon class="Du-notification-icon-alert-notes" (click)="loadAlertView()" [matTooltip]="'Alert'" svgIcon="notification_alert" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightAlertView"  aria-controls="offcanvasRightAlertView"></mat-icon>
    </span>
    
    <span style="padding: 5px;">
        <mat-icon class="Du-notification-icon-alert-notes" (click)="loadAnnotation()" [matTooltip]="'Notes'" svgIcon="notification_notes" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightAnnotationNotes"  aria-controls="offcanvasRightAnnotationNotes"></mat-icon>
    </span> -->

    <span class="outer_img_layout du-topnav-bar-desktop">
        <img  *ngIf="gender=='M'" src="assets/man.png" alt="Avatar" class="avatar">
        <img  src="assets/women.png" *ngIf="gender=='F'" alt="Avatar" class="avatar">
    </span>
    
    <span class="outer_img_layout du-side-bar-menu-talet">
        <img data-bs-toggle="modal" data-bs-target="#exampleModalProfile" *ngIf="gender=='M'" src="assets/man.png" alt="Avatar" class="avatar">
        <img data-bs-toggle="modal" data-bs-target="#exampleModalProfile" *ngIf="gender=='F'" src="assets/women.png" alt="Avatar" class="avatar">
    </span>
    
    <span class="user_name du-topnav-bar-desktop">{{ userName || ''}}</span>

    <!-- <button *ngIf="deviceDetectionService.isDesktop" mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
        <mat-icon [matMenuTriggerFor]="afterMenu" style="color:#8A98AB">keyboard_arrow_down</mat-icon>
    </button> -->

    <div class="dropdown du-topnav-bar-desktop">
        
        <button mat-icon-button class="example-icon du-topnav-bar-desktop" aria-label="Example icon-button with share icon" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <mat-icon style="color:#8A98AB">keyboard_arrow_down</mat-icon>
        </button>

        <div class="dropdown-menu login_menu mr-4" style="padding: 0px;" aria-labelledby="dropdownMenu2">
            <!-- <button *ngIf="environment == 'DEV' || environment == 'QA'" class="dropdown-item login_menu_item d-flex align-items-center" type="button" (click)="toggleAlertSettings()" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAlertSettings" aria-controls="offcanvasExample">
                <span class="material-icons" style="color: #1363DF;">notification_important</span>
                <span class="pl-3"> Alert Settings </span>
            </button> -->
            <button *ngIf="userInfo?.alert_setting_flg=='Y'" class="dropdown-item login_menu_item d-flex align-items-center" type="button" (click)="openAlertsPopupDQ()">
                <span class="material-icons" style="color: #1363DF;">notification_important</span>
                <span class="pl-3"> Alert Settings</span>
            </button>

            <button class="dropdown-item login_menu_item d-flex align-items-center" *ngIf="userInfo?.impersonation_flg=='Y' && !usrImp" (click)="impersonation()">
                <span class="material-icons">autorenew</span>
                <span class="pl-3">Impersonate User</span>
            </button>

            <button class="dropdown-item login_menu_item d-flex align-items-center" *ngIf="usrImp" (click)="impersonation()">
                <span class="material-icons">autorenew</span>
                <span class="pl-3">Switch User</span>
            </button>
            
            <button *ngIf="this.clientLength.length>1" class="dropdown-item login_menu_item d-flex align-items-center" (click)="switchClient()">
                <span class="material-icons">autorenew</span>
                <span class="pl-3">Switch Client</span>
            </button>
            
            <button class="dropdown-item login_menu_item d-flex align-items-center" type="button" (click)="logout()">
                <span class="material-icons text-danger">logout</span>
                <span class="pl-3">Logout</span>
            </button>
        </div>

    </div>

</mat-toolbar>

<div id="offcanvasAlertSettings" class="offcanvas offcanvas-end alterts_settings" tabindex="-1"
    aria-labelledby="offcanvasExampleLabel" style="width:40%">
    <app-alert-settings></app-alert-settings>

</div>

<mat-menu #menu="matMenu" class="topNav_user_menu">
    <button class="menu_setting_topnav" mat-menu-item (click)="toggleAlertSettings()"
        data-bs-toggle="offcanvas" data-bs-target="#offcanvasAlertSettings" aria-controls="offcanvasExample">Alert Settings</button>
    <button class="menu_setting_topnav" *ngIf="userInfo?.impersonation_flg=='Y' && !usrImp" mat-menu-item (click)="impersonation()">{{ 'Impersonate User' }}</button>
    <button class="menu_setting_topnav" *ngIf="usrImp" mat-menu-item (click)="impersonation()">{{ 'Switch User' }}</button>
    <button class="menu_setting_topnav" mat-menu-item (click)="logout()">Logout</button>
</mat-menu>
<!-- <mat-menu #afterMenu="matMenu" class="topNav_user_menu mt-3">
    <a class="menu_setting_topnav" style="text-decoration:'none'" mat-menu-item (click)="toggleAlertSettings()"
        data-bs-toggle="offcanvas" data-bs-target="#offcanvasAlertSettings" aria-controls="offcanvasExample">Alert Settings</a>
    <a class="menu_setting_topnav" style="text-decoration:'none'" mat-menu-item (click)="logout()">Logout</a>
</mat-menu> -->

<mat-menu #beforeMenu="matMenu" [hasBackdrop]="false" xPosition="before">
    <div style="width: 284px;height: 327px;" (click)="$event.stopPropagation()">
        <div class="d-flex justify-content-between flex-container fontStyle" style="align-items: center;">
            <div style="color: #101D42 !important">
                Change avatar
            </div>
            <div>
                <mat-icon 
                    (click)="closeMenu()" 
                    [svgIcon]="cancel_x"
                    style="cursor: pointer;width: 15px;color: #8A98AB !important;">
                </mat-icon>
            </div>        
        </div>
        <div style="padding: 20px 0;text-align: center;">
            <div class="mx-auto" class="outer_img_layout1">
                <img src="assets/man.png" *ngIf="gender=='M'" alt="Avatar" class="avatar1">
                <img src="assets/women.png" *ngIf="gender=='F'" alt="Avatar" class="avatar1">
            </div>
            <div style="padding: 10px;">
                <mat-icon style="width: 20px;cursor: pointer;margin: 0px 10px -7px 0;"
                  [svgIcon]="edit" >
                </mat-icon>
                <span class="fontColoText" style="color: #1363DF;">
                    Change photo
                </span>
            </div>
            <div style="padding: 5px;">
                <mat-icon style="width: 20px;cursor: pointer;margin: 0px 10px -7px 0;"
                  [svgIcon]="trash" >
                </mat-icon>
                <span class="fontColoText" style="color: #D11044;">
                    Delete photo
                </span>
            </div>
        </div>
        <hr style="color: #E8EAEE;">
        <div class="d-flex justify-content-evenly">
            <div>
                <button (click)="closeMenu()" mat-raised-button class="basicBtn btnFonts">Cancel</button>
            </div>
            <div>
                <button mat-raised-button class="primaryBtn btnFonts">Save</button>
            </div>
        </div>
        

    </div>
</mat-menu>


<!-- Modal -->
  <div class="modal fade" id="exampleModalProfile" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog du-top-modal-dialog" style="margin-top: 50px;">
      <div class="modal-content">
        <div class="modal-header justify-content-start">
            <span class="outer_img_layout_profile">
                <img src="assets/man.png" *ngIf="gender=='M'" alt="Avatar" class="avatarProfile">
                <img src="assets/women.png" *ngIf="gender=='F'" alt="Avatar" class="avatarProfile">
            </span>
            <span style="margin: 5px 10px 0;">
                <span class="username_profile">{{userName || 'Test'}}</span>
                <br>
                <span class="userName_profile_email email_address_CutOF">{{userEmail}}</span>
            </span>
        </div>
        <div class="modal-body" style="margin-left: 15px">
            <div class="d-flex justify-content-start" style="padding-top: 15px;">
                <div style="margin: 0 15px 0 0;">
                    <mat-icon style="width: 20px;"[svgIcon]="userProfile_manage" ></mat-icon>
                </div>
                <div>
                    <span class="userProfile_body">Manage Profile</span>
                </div>
            </div>
            <div class="d-flex justify-content-start" style="padding-top: 15px;">
                <div style="margin: 0 15px 0 0;">
                    <mat-icon style="width: 20px;"[svgIcon]="userProfile_bell" ></mat-icon>
                </div>
                <div>
                    <span class="userProfile_body">Alerts Settings</span>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-start" style="margin-left: 20px">
            <div style="margin: 0 15px 0 0;">
                <mat-icon (click)="logout()" style="width: 20px;"[svgIcon]="userProfile_logout" ></mat-icon>
            </div>
            <div>
                <span (click)="logout()" class="userProfile_body">Log out</span>
            </div>
        </div>
      </div>
    </div>
  </div>
  
<!-- Alert Settings DQ -->
<ng-template #alertsDQ>
    <div>
        <!-- <app-alert-settings-dq></app-alert-settings-dq> -->
        <!-- <app-alerts-dq-dynamic></app-alerts-dq-dynamic> -->
        <app-alert-subscription-dq></app-alert-subscription-dq>
    </div>
</ng-template>


<!-- Annotation -->
<div class="offcanvas offcanvas-end container" style="padding: 0;min-width: 320px;max-width: calc(100% - 180px)" #offcanvasRightAnnotationNotes [style.width.px]="data?.width" tabindex="-1" id="offcanvasRightAnnotationNotes" aria-labelledby="offcanvasRightAnnotationNotesLabel">
    <app-annotation-view [offcanvasRightAnnotationNotes]="offcanvasRightAnnotationNotes" [annotationIndication]="annotationIndication" (onResizeSideWindow)="onResizeSideWindow($event)"></app-annotation-view>
    
    <!-- Close -->
    <div class="btn-close-bg">
        <mat-icon class="mat-btn-close" data-bs-dismiss="offcanvas">clear</mat-icon>
     </div>
</div>

<!-- Alert -->
<div class="offcanvas offcanvas-end container" style="padding: 0;min-width: 320px;max-width: calc(100% - 180px)" #offcanvasRightAlertView [style.width.px]="data?.width" tabindex="-1" id="offcanvasRightAlertView" aria-labelledby="offcanvasRightAlertViewLabel">
    <app-alert-view [offcanvasRightAlertView]="offcanvasRightAlertView" [alertIndication]="alertIndication" (onResizeSideWindow)="onResizeSideWindow($event)"></app-alert-view>
    
    <!-- Close -->
    <div class="btn-close-bg">
       <mat-icon class="mat-btn-close" data-bs-dismiss="offcanvas">clear</mat-icon>
    </div>
</div>
