import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Injectable, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
// import { NetworkChartService } from 'src/app/services/network-chart.service';
import { DataService } from 'src/app/services/data.service';
import * as d3 from "d3";
import { Subscription } from 'rxjs';
import { NewFilterService } from 'src/app/services/new-filter.service';
import { ActivatedRoute } from '@angular/router';
import { FilterService } from 'src/app/services/filter.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { NumberPipe } from 'src/app/pipe/number.pipe';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-network-chart',
  templateUrl: './network-chart.component.html',
  styleUrls: ['./network-chart.component.scss']
})
export class NetworkChartComponent implements OnInit {
  networkChartData: any = [];
  isPendingAndActive: any;
  divId: any = 'networkChartDiv';
  width: number = 0;
  height: number = 0;
  networkchart: any;
  tooltipType: any;
  popupHeading: any;
  toolTipData: any = [];
  props: any = {};
  collapse: boolean = false
  isActive: boolean = false
  Right: boolean = false
  Left: boolean = false
  @ViewChild('fs') fs!: ElementRef;
  @Input('hideLinkTooltip') hideLinkTooltip: boolean = false
  @Input('hideUpdateTooltip') hideUpdateTooltip: boolean = false
  @Input('refreshNetworkChart') refreshNetworkChart: boolean = false
  @Input('pieColorObject') pieColorObject: any = {}
  @Input('dropdownArray') dropdownArray: any = []
  @Input('item') item: any = []
  @Input('sliderValue') sliderValue: any = 1;
  @Input('listStyle') listStyle: any = 'default';
  @Input('sortBySelectedData') sortBySelectedData: any = 1;
  reqSubcription: Subscription[] = [];
  clientKey: any;
  alertObjArray: any = []
  scrollLeft: any = 0;
  scrollTop: any = 0;
  scrollRight: any = 0;
  variableTop: any = '';
  variableBottom: any = '';
  variableTopLabel: any = '';
  variableBottomLabel: any = '';
  myX: any;
  myY: any;
  gapWidthMultiple: any = 5.48;
  difference: number = 0;
  scrollWidth: any = 0;
  patientReasonCode: any = [];
  heightDifference: number = 0;
  patientLevelLabels: any = ["DAYS 1-5", "DAYS 6-10", "DAYS 11-12", "DAYS > 21"];
  paitentLevelKey: any = "no_of_days_in_status";
  patientLevelColors: any = ["#F64242", "#FA8E8E", "#FBB3B3", "#FDD9D9"];
  patientLevelValues: any = ["patientLevel0", "patientLevel1", "patientLevel2", "patientLevel3"];

  dynamicDonutVariable:any;
  @Output() emitLoader = new EventEmitter();

  constructor(
    public dataService: DataService, 
    private navigationService: NavigationService, 
    private filterServiceOld: FilterService, 
    private router: ActivatedRoute, 
    private changeDetectorRef: ChangeDetectorRef,
    public numberPipe: NumberPipe
  ) {
    
    this.router.params.subscribe((p) => {
      this.router.queryParams.subscribe((params:any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = decryptedParams["cl_key"];
      })
    })

  }
  ngOnInit(): void {
    // console.log(this.item)
    this.reqSubcription.push(this.filterServiceOld.filterQuery.subscribe((query: any) => {
      this.initiateCharts();
      this.getNetworkChartData();
    }))
    this.dataService.expandCollapseChart.subscribe((resp: any) => {
      if (resp) {
        this.collapse = true
        // this.getNetworkChartData()
      } else {
        this.collapse = false
        // this.getNetworkChartData()
      }
    })

    document.addEventListener('fullscreenchange', (event) => {

      if (document.fullscreenElement) {
        document.getElementById('completeNetworkChart')?.setAttribute('style', 'height:85vh;overflow-y:auto;overflow-x:hidden;background:#ffff;')
        this.hideLinkTooltip = false
        this.hideUpdateTooltip = false
        this.gapWidthMultiple = 6.2
        // this.initiateCharts();
        // this.getNetworkChartData();
      } else {
        document.getElementById('completeNetworkChart')?.setAttribute('style', 'height:auto;overflow-y:unset;background:#ffff;padding-top:auto;overflow-x:unset')
        this.hideLinkTooltip = false
        this.hideUpdateTooltip = false
        this.gapWidthMultiple = 5.48
        // this.initiateCharts();
        // this.getNetworkChartData();
      }
    })

    window.addEventListener('orientationchange', (event: any) => {
      this.getNetworkChartData()
    });
  }


  ngAfterViewInit(): void {
    document.getElementById('completeNetworkChart')?.addEventListener("scroll", (event: any) => {
      this.scrollLeft = event?.target?.scrollLeft
      this.scrollTop = event?.target?.scrollTop
      this.scrollWidth = event?.target?.scrollWidth
      this.scrollRight = this.scrollWidth - this.scrollLeft
      if (this.Left) {
        d3.select("#NetworkChartTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('z-index', '99999')
          .style('top', (this.myY + 100) - this.scrollTop + 'px')
          .style('left', (this.myX + 20) - this.scrollLeft + 'px')
          .style('right', 'unset')
      } else if (this.Right) {
        d3.select("#NetworkChartTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('z-index', '99999')
          .style('top', (this.myY + 100) - this.scrollTop + 'px')
          .style('right', (this.myX + 20) - this.scrollRight + 'px')
          .style('left', 'unset')

      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.networkChartData = changes['data'].currentValue;
    }
    // debugger
    if (this.dropdownArray || this.filterServiceOld.sliderValue) {
      this.dropdownArray?.find((i: any) => {
        this.paitentLevelKey = ""
        this.patientLevelColors = []
        this.patientLevelLabels = []
        this.patientLevelValues = []

        if (this.listStyle == 'default') {
          this.hideLinkTooltip = false;
          this.hideUpdateTooltip = false;

          this.dynamicDonutVariable = i.values;

          this.variableTop = i.values[0].keyName
          this.variableTopLabel = i.values[0].name
          this.variableBottom = i.values[1].keyName
          this.variableBottomLabel = i.values[1].name
        
        } else {
          this.hideLinkTooltip = false
          this.hideUpdateTooltip = false
          i.values.map((i: any, j: any) => {
            this.paitentLevelKey = i.keyName
            this.patientLevelColors.push(i.color)
            this.patientLevelLabels.push(i.name)
            this.patientLevelValues.push('patientLevel' + j)
          })
        }
      })

      this.changeDetectorRef.detectChanges()
      this.initiateCharts();
      this.getNetworkChartData();
    }
  }

  private showTooltip(myType: any, myData: any, myX: any, myY: any): void {
    this.hideLinkTooltip = false;
    this.hideLinkTooltip = false;
    const chartWidth: any = document.getElementById('networkChartnetworkChartDiv')?.clientWidth
    this.difference = 0
    this.myX = ''
    this.myY = ''
    this.myX = myX;
    this.myY = myY;
    this.toolTipData = []
    this.tooltipType = myType;
    this.patientReasonCode = [];

    if (this.tooltipType == 'circle') {
      this.hideLinkTooltip = true
      this.hideUpdateTooltip = false

    }
    else if (this.tooltipType == 'alert') {
      this.hideUpdateTooltip = true
      this.hideLinkTooltip = false
    }

    this.popupHeading = myData?.name ? myData?.name : myData?.bottomNode?.data.name ? myData?.bottomNode?.data.name : myData?.data?.name
    this.difference = chartWidth - myX

    const pointer = myData?.pieData?.find((i: any) => i.label.includes('DAY')) || myData?.data?.pieData?.find((i: any) => i.label.includes('DAY')) || myData?.bottomNode?.data?.pieData?.find((i: any) => i.label.includes('DAY'))

    if (this.difference < 280) {
      if (myData.pieData) {
        this.Right = true
        this.Left = false
        this.patientReasonCode = myData.patientReasonCodes
        if (pointer) {
          myData.pieData.map((i: any) => {
            const obj = {
              count: i.label + ":" + " " + i.value,
              status: 'PATIENTS',
              change: '',
              color: i.color
            }
            this.toolTipData.push(obj)

          })

        } else {

          myData.pieData.forEach((i: any) => {

            const obj = {
              count: i.value,
              status: i.label,
              change: i.extraPieCount,
              color: i.color
            }

            this.toolTipData.push(obj)
          })
        }

        d3.select("#NetworkChartTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('z-index', '99999')
          .style('top', (myY + 100) - this.scrollTop + 'px')
          .style('right', ((this.difference) + 20) + 'px')
          .style('left', 'unset')
        this.hideLinkTooltip = true
      }
      else if (myData?.bottomNode) {
        this.Right = true
        this.Left = false
        this.patientReasonCode = myData?.bottomNode?.data?.patientReasonCodes
        if (pointer) {
          myData?.bottomNode?.data?.pieData?.map((i: any) => {
            const obj = {
              count: i.label + ":" + " " + i.value,
              status: 'PATIENTS',
              change: '',
              color: i.color
            }
            this.toolTipData.push(obj)
          })

        } else {
          myData?.bottomNode?.data?.pieData?.map((i: any) => {

            const obj = {
              count: i.value,
              status: i.label,
              change: i.extraPieCount,
              color: i.color
            }

            this.toolTipData.push(obj)
          })
        }

        d3.select("#NetworkChartTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('z-index', '99999')
          .style('top', (myY + 100) - this.scrollTop + 'px')
          .style('right', ((this.difference) + 20) + 'px')
          .style('left', 'unset')
      }
      else {
        this.Right = true
        this.Left = false
        this.patientReasonCode = myData?.data.patientReasonCodes
        if (pointer) {
          myData?.data?.pieData?.map((i: any) => {
            const obj = {
              count: i.label + ":" + " " + i.value,
              status: 'PATIENTS',
              change: '',
              color: i.color
            }
            this.toolTipData.push(obj)
          })

        } else {
          myData?.data?.pieData?.map((i: any) => {

            const obj = {
              count: i.value,
              status: i.label,
              change: i.extraPieCount,
              color: i.color
            }

            this.toolTipData.push(obj)
          })
        }

        d3.select("#NetworkChartTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('z-index', '99999')
          .style('top', (myY + 100) - this.scrollTop + 'px')
          .style('right', ((this.difference) + 20) + 'px')
          .style('left', 'unset')

      }

    }

    else if (this.difference > 280) {

      if (myData.pieData) {
        this.Right = false
        this.Left = true
        this.patientReasonCode = myData.patientReasonCodes
        if (pointer) {
          myData.pieData.map((i: any) => {
            const obj = {
              count: i.label + ":" + " " + i.value,
              status: 'PATIENTS',
              change: '',
              color: i.color
            }
            this.toolTipData.push(obj)
          })

        } else {
          myData.pieData.forEach((i: any) => {

            const obj = {
              count: i.value,
              status: i.label,
              change: i.extraPieCount,
              color: i.color
            }

            this.toolTipData.push(obj)
          })
        }

        d3.select("#NetworkChartTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('z-index', '99999')
          .style('top', (myY + 100) - this.scrollTop + 'px')
          .style('left', (myX + 20) - this.scrollLeft + 'px')
          .style('right', 'unset')
        this.hideLinkTooltip = true
      }
      else if (myData?.bottomNode) {
        this.Right = false
        this.Left = true
        this.patientReasonCode = myData?.bottomNode?.data?.patientReasonCodes
        if (pointer) {
          myData?.bottomNode?.data?.pieData?.map((i: any) => {
            const obj = {
              count: i.label + ":" + " " + i.value,
              status: 'PATIENTS',
              change: '',
              color: i.color
            }
            this.toolTipData.push(obj)
          })

        } else {
          myData?.bottomNode?.data?.pieData?.map((i: any) => {

            const obj = {
              count: i.value,
              status: i.label,
              change: i.extraPieCount,
              color: i.color
            }

            this.toolTipData.push(obj)
          })
        }

        d3.select("#NetworkChartTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('z-index', '99999')
          .style('top', (myY + 100) - this.scrollTop + 'px')
          .style('left', (myX + 20) - this.scrollLeft + 'px')
          .style('right', 'unset')

      }
      else {
        this.patientReasonCode = myData?.data.patientReasonCodes
        if (pointer) {
          myData?.data?.pieData?.map((i: any) => {
            const obj = {
              count: i.label + ":" + " " + i.value,
              status: 'PATIENTS',
              change: '',
              color: i.color
            }
            this.toolTipData.push(obj)
          })

        } else {
          myData?.data?.pieData?.map((i: any) => {

            const obj = {
              count: i.value,
              status: i.label,
              change: i.extraPieCount,
              color: i.color
            }

            this.toolTipData.push(obj)
          })
        }
        this.Right = false
        this.Left = true
        d3.select("#NetworkChartTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('z-index', '99999')
          .style('top', (myY + 100) - this.scrollTop + 'px')
          .style('left', (myX + 20) - this.scrollLeft + 'px')
          .style('right', 'unset')
      }
    }
  }

  private hideTooltip(): void {
    this.hideUpdateTooltip = false
    this.hideLinkTooltip = false
  }

  private showAlertTooltip(myType: any, myData: any, myX: any, myY: any): void {
    this.hideLinkTooltip = false
    const chartWidth: any = document.getElementById('networkChartnetworkChartDiv')?.clientWidth
    const chartHeight: any = document.getElementById('networkChartnetworkChartDiv')?.clientHeight
    this.difference = 0
    this.myX = ''
    this.myY = ''
    this.myX = myX;
    this.myY = myY;
    this.difference = chartWidth - myX
    this.heightDifference = chartHeight - myY
    this.alertObjArray = []
    myData.alertCount.data.forEach((i: any) => {
      if (i) {
        this.alertObjArray.push(
          {
            id: i.Patient_key,
            from: i.From,
            to: i.To,
            date: i.Date
          })
      }
    })
    if (this.difference < 500) {
      this.Right = true
      this.Left = false
      d3.select("#NetworkChartTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('z-index', '99999')
        .style('right', (this.difference) + 'px')
        .style('left', 'unset')
    } else if (this.difference > 500) {
      this.Right = false
      this.Left = true
      d3.select("#NetworkChartTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('z-index', '99999')
        .style('left', (myX + 20) - this.scrollLeft + 'px')
        .style('right', 'unset')
    }

    if ((this.heightDifference) < 250) {
      d3.select("#NetworkChartTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('z-index', '99999')
        .style('bottom', (this.heightDifference) + 'px')
        .style('top', 'unset')

    } else if ((this.heightDifference) > 250) {
      d3.select("#NetworkChartTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('z-index', '99999')
        .style('top', (myY + 100) - this.scrollTop + 'px')
        .style('bottom', 'unset')
    }

    this.hideUpdateTooltip = true

  }

  private hideAlertTooltip(myType: any): void {
    // d3.select("#d3Tooltip")
    //   .style('visibility', 'hidden');
  }

//  d3 chart initial structure
  initiateCharts(): void {
    const myClass = this.divId;
    this.height = 500;
    let mySvg: any = d3.select('#networkChart' + myClass);

    if (mySvg._groups[0][0] === null) {
      mySvg = d3.select('#' + myClass)
        .append('svg')
        .attr('id', 'networkChart' + myClass)
        .attr('width', '100%')
        .attr('height', this.height)
        .style('background-color', '#F0F0F0');
      mySvg.append("g").attr("class", "dotGroup");
      mySvg.append("g").attr("class", "linkGroup");
      mySvg.append("g").attr("class", "nodeGroup");
    }

  }
// chart svg  plotChart rendering 
  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#networkChart' + myClass);
    const circleRadius = 44;
    const margin = { left: - (circleRadius * 2.5) + 5, right: 20, top: 30, bottom: 20 };
    const labelSvg = 'M1.26958 12.116C-1.92974 6.78376 1.91117 0 8.12952 0H79.9481C86.3585 0 90.1653 7.16252 86.5788 12.4758L81.1788 20.4758C79.6915 22.6793 77.2066 24 74.5481 24H12.9295C10.1194 24 7.51537 22.5256 6.06958 20.116L1.26958 12.116Z';
    const labelViewBox = "0 0 88 24";
    const alertSvg = 'M3.33885 5.297 C3.5922 5.29753 3.72781 5.59569 3.56133 5.78666L2.90421 6.54036L1.81729 7.78712L1.51455 7.43987L0.0732603 5.78664C-0.0932343 5.59567 0.0423967 5.2975 0.295745 5.2975H0.934653C0.931352 5.22212 0.929455 5.14649 0.929455 5.07055C0.929455 3.71616 1.4569 2.44283 2.41458 1.48513C3.37229 0.527444 4.64561 0 6 0C7.27716 0 8.48211 0.469159 9.41841 1.32568L8.34882 2.57697C7.73489 1.99836 6.9082 1.64317 6 1.64317C4.11013 1.64317 2.57262 3.18068 2.57262 5.07057C2.57262 5.14684 2.57539 5.22247 2.58033 5.29753H3.33885ZM10.8518 3.58869L11.9268 4.82178C12.0932 5.01274 11.9576 5.31091 11.7043 5.31093H11.0649C11.0062 6.57493 10.4862 7.75492 9.58546 8.65567C8.62775 9.61338 7.35443 10.1408 6.00004 10.1408C4.83418 10.1408 3.72862 9.74967 2.83252 9.03004L3.90218 7.77866C4.48234 8.22906 5.21037 8.49763 6.00002 8.49763C7.80895 8.49763 9.29488 7.08891 9.41882 5.31091H8.66119C8.40784 5.31091 8.27223 5.01274 8.43871 4.82178L9.22753 3.91695L10.1827 2.82129L10.8518 3.58869Z';
    const alertViewBox = "0 0 12 11";
    const mediumCircleRadius: any = 44;
    const smallCircleRadius: any = 44;
    const downLinkGap = 30;
    const acrossLinkGap = 16;
    const subDepthWidth = ((smallCircleRadius * 2) + acrossLinkGap);
    const subDepthHeight = ((smallCircleRadius * 2) + downLinkGap);
    const svgBounds: any = document.getElementById('networkChart' + myClass)?.getBoundingClientRect();
    const width = svgBounds.width;
    let expandedDownChildren: any = [];
    const patientVariableScale = d3.scaleThreshold().domain(myChart.props.patientLevelThresholds).range(myChart.props.patientLevelValues);
    const patientVariableColor = d3.scaleOrdinal().domain(myChart.props.patientLevelValues).range(myChart.props.patientLevelColors);

    generateDots(this.height, width);

    mySvg.attr("font-family", "Poppins");
    const pieColors = myChart.props.patientLevelView ? patientVariableColor : myChart.props.pieColors;
    const linkColors = myChart.props.linkColors;
    //process of converting the complicated data structure into
    //a) top level hierarchy (including transfer children which behave differently)
    //b) their children (actual data)

    let depth0Set: any = new Set(), depth1Set: any = new Set();
    const allTotals: any = {};
    const topTotals: any = {};
    const bottomTotals: any = {};
    const alertTotals: any = {};
    const averageTotals: any = {};
    const reasonTotals: any = {};
    const patientLevelTotals: any = {};
    let depth2Children: any = {};
    let transferChildren = myChart.networkChartData.filter((f: any) => f.process === null);
    transferChildren.map((m: any) => m.node_4 = (m.node_2 === "SP" ? "HUB" : "SP"));
    transferChildren.map((m: any) => m.name = m.node_3);

    const otherChildren = myChart.networkChartData.filter((f: any) => f.process !== null);
    otherChildren.forEach((d: any) => {
      d.patient_level_data = (d.patient_level_data === undefined || d.patient_level_data === null ? "[]" :
        d.patient_level_data);
      d.patient_level_data = JSON.parse(d.patient_level_data);
      d.patient_level_data.map((m: any) => m.levelBin = patientVariableScale(m[myChart.props.patientLevelVar]))
      const patientLevelGroup = Array.from(d3.group(d.patient_level_data, (g: any) => g.levelBin));
      myChart.props.patientLevelValues.forEach((p: any) => {
        const matchingData = patientLevelGroup.find((f: any) => f[0] === p);
        d[p] = matchingData === undefined ? 0 : matchingData[1].length;
      })
      d.alerts = JSON.parse(d.alerts);
      if (d.alerts !== null) {
        d.alerts.forEach((a: any) => {
          a.Data.map((m: any) => m.Patient_key = a.Patient_key);
          a.Data.map((m: any) => m.Alert_Type = a.Alert_Type)
        })
      }
      depth0Set.add(d.node_1);
      depth1Set.add(d.node_2);
      addTotal(d.node_1, d);
      addTotal(d.node_1 + ":" + d.node_2, d);
      if (depth2Children[d.node_2] === undefined) {
        depth2Children[d.node_2] = [];
      }
      if (depth2Children[d.node_2].find((f: any) => f[0] === d.node_3) === undefined) {
        depth2Children[d.node_2].push([d.node_3, d.process, d.seq_no]);
      }
      addTotal(d.node_1 + ":" + d.node_2 + ":" + d.node_3, d);
    })


    //parent node
    const parentNode: any = Array.from(depth0Set)[0];//only ever one, choosing 1st in case an issue;
    const nodeData: any = {
      "name": parentNode, "node_key": 0, "pieData": getPieData(parentNode), linkLabel: null,
      children: [], alertCount: getAlertData(parentNode), patientReasonCodes: reasonTotals[parentNode]
    };
    let currentNodeKey = 1;
    //children
    depth1Set = Array.from(depth1Set);
    transferChildren = transferChildren.sort((a: any, b: any) => d3.descending(depth1Set.indexOf(a.node_2), depth1Set.indexOf(b.node_2)));
    depth1Set.forEach((d: any) => {
      const myNode: any = {
        "name": d,
        "pieData": getPieData(parentNode + ":" + d),
        linkLabel: null,
        children: [],
        alertCount: getAlertData(parentNode + ":" + d),
        patientReasonCodes: reasonTotals[parentNode + ":" + d],
        node_key: currentNodeKey
      }
      currentNodeKey += 1;
      let processGroup = Array.from(d3.group(depth2Children[d], (g: any) => g[1]));
      processGroup = processGroup.sort((a: any, b: any) => d3.ascending(a[0], b[0]));
      processGroup.forEach((p: any) => {
        let previousChild: any = {};
        const processChildren = p[1].sort((a: any, b: any) => d3.ascending(a[2], b[2]));
        for (let i: any = 0; i < processChildren.length; i++) {
          const downChildren = processDownChildren(otherChildren.filter((f: any) => f.node_1 === parentNode && f.node_2 === d && f.node_3 === processChildren[i][0]));
          const currentChild = {
            "name": processChildren[i][0],
            "pieData": getPieData(parentNode + ":" + d + ":" + processChildren[i][0]),
            linkLabel: null,
            children: undefined,
            alertCount: getAlertData(parentNode + ":" + d + ":" + processChildren[i][0]),
            node_key: currentNodeKey,
            downChildren: downChildren,
            parentNodeKey: myNode.node_key,
            processGroup: p[0],
            patientReasonCodes: reasonTotals[parentNode + ":" + d + ":" + processChildren[i][0]],
            averageDays: Object.keys(previousChild).length === 0 ? undefined : parseInt(String(d3.mean(averageTotals[parentNode + ":" + d + ":" + processChildren[i][0]])), 10)
          };
          currentNodeKey += 1;
          if (i > 0) {
            previousChild.children = [currentChild];
          } else {
            myNode.children.push(currentChild);
          }
          previousChild = currentChild
        }
      })
      nodeData.children.push(myNode);
    })

    function processDownChildren(myDownChildren: any) {
      let processedDownChildren: any = {
        "name": "root", "pieData": [], linkLabel: null, children: [], alertCount: []
        , patientReasonCodes: []
      };
      const depth4Group = Array.from(d3.group(myDownChildren, (g: any) => g.node_4)).filter(f => f[0] !== null);
      depth4Group.forEach((d: any) => {
        const myRoot: any = {
          "name": d[0], "pieData": getDownPieData(d[1]), linkLabel: null, children: []
          , alertCount: getDownAlertData(d[1]), patientReasonCodes: getDownPatientCodes(d[1])
        }
        const depth5Group = Array.from(d3.group(d[1], (g: any) => g.node_5)).filter(f => f[0] !== null);
        if (depth5Group.length === 0) {
          myRoot.children = undefined;
        }
        depth5Group.forEach((t: any) => {
          const depth5Root: any = {
            "name": t[0], "pieData": getDownPieData(t[1]), linkLabel: null, children: [],
            alertCount: getDownAlertData(t[1]), patientReasonCodes: getDownPatientCodes(t[1])
          }
          const depth6Group = Array.from(d3.group(t[1], (g: any) => g.node_6)).filter(f => f[0] !== null);
          depth6Group.forEach((p: any) => {
            depth5Root.children.push({
              "name": p[0], "pieData": getDownPieData(p[1]), linkLabel: null, children: null,
              alertCount: getAlertData(p[1]), patientReasonCodes: getDownPatientCodes(p[1])
            })
          })
          if (depth6Group.length === 0) {
            depth5Root.children = undefined;
          }
          myRoot.children.push(depth5Root)
        })
        processedDownChildren.children.push(myRoot)
      })

      processedDownChildren = d3.hierarchy(processedDownChildren);
      processedDownChildren.descendants().forEach((m: any) => {
        m.data.node_key = currentNodeKey;
        currentNodeKey += 1;
      })
      return processedDownChildren;

      function getDownPieData(myList: any) {
        let pieData: any = [];
        if (myChart.props.patientLevelView) {
          myChart.props.patientLevelValues.forEach((d: any, i: any) => {
            pieData.push({
              value: d3.sum(myList, (s: any) => s[d] === undefined ? 0 : s[d]),
              label: myChart.props.patientLevelLabels[i],
              color: pieColors(d)
            })
          })
        } else {
          // PieData
          pieData = [];

          myChart.props.dynamicDonutVariable.forEach((d:any) => {
            const obj = {
              value: d3.sum(myList, (s: any) => s[d.keyName]),
              label: d.name,
              color: myChart.props.pieColors[d.name]
            }
            pieData.push(obj)
          })
          
          // pieData.push({
          //   value: d3.sum(myList, (s: any) => s[myChart.props.variableTop]),
          //   label: myChart.props.variableTopLabel,
          //   color: myChart.props.pieColors[myChart.props.variableTopLabel]
          // })
          // pieData.push({
          //   value: d3.sum(myList, (s: any) => s[myChart.props.variableBottom]),
          //   label: myChart.props.variableBottomLabel,
          //   color: myChart.props.pieColors[myChart.props.variableBottomLabel]
          // })
        }
        return pieData;
      }

      function getDownPatientCodes(myList: any) {
        const myCodes: any = [];
        myList.forEach((d: any) => {
          myCodes.push(d.patient_reason_code);
        })
        return myCodes
      }
      function getDownAlertData(myList: any) {
        const myAlerts: any = [];
        myList.forEach((d: any) => {
          const myAlert = d.alerts;
          if (myAlert !== null) {
            myAlerts.push(myAlert);
          }
        })
        if (myAlerts.length === 0) {
          return { total: 0, data: [] };
        } else {
          const myTotal = d3.sum(myAlerts, (a: any) => d3.sum(a, (s: any) => s.Count));
          let allAlerts: any = [];
          myAlerts.forEach((d: any) => {
            let myAllAlerts: any = [];
            d.forEach((a: any) => {
              myAllAlerts = myAllAlerts.concat(a.Data)
            })
            allAlerts = allAlerts.concat(myAllAlerts);
          })
          return { total: myTotal, data: allAlerts };
        }
      }
    }

    let root: any = d3.hierarchy(nodeData);
    let dx = circleRadius * 2.5;
    let dy = circleRadius * myChart.props.gapWidthMultiple;

    // dates header group
    const markerGroup = mySvg
      .selectAll(".markerGroup" + myClass)
      .data(otherChildren)
      .join(function (group: any): any {
        const enter = group.append('g').attr('class', 'markerGroup' + myClass);
        enter.append("defs").append("marker").attr("class", "myMarker").append("polygon").attr("class", "myPolygon");
        return enter;
      });

    markerGroup.select(".myMarker")
      .attr("id", (d: any, i: any) => "arrowhead" + i)
      .attr('markerWidth', '6')
      .attr('markerHeight', '6')
      .attr('refX', '0')
      .attr('refY', '3')
      .attr('orient', 'auto');

    markerGroup.select(".myPolygon")
      .attr('fill', (d: any, i: any) => linkColors[i])
      .attr('points', '0 0, 6 3, 0 6')

    root.descendants().forEach((d: any, i: any) => {
      d.id = i;
      d._children = d.children;
    });
    root.rowOrder = -1;
    let treeWidth = 0;
    let treeHeight = 0;
    let extraDy = 0;
    const alertWidth = 28;
    drawTree();

    function resetHeight() {
      const nodeGroupBounds = mySvg.select(".nodeGroup").node().getBoundingClientRect();
      const svgHeight = nodeGroupBounds.height + margin.top + margin.bottom;
      let svgWidth = nodeGroupBounds.width + margin.left + margin.right + alertWidth + (circleRadius * 3);
      svgWidth = svgWidth < width ? width : svgWidth;
      mySvg
        .attr("width", svgWidth)
        .attr("height", svgHeight);
      generateDots(svgHeight, svgWidth);
    }
    function drawTree() {
      dy = circleRadius * myChart.props.gapWidthMultiple;
      d3.tree()
        .nodeSize([dx, dy])(root);

      if (root.children !== undefined) {
        if (root.children.length > 1) {
          if (root.children[0].children === undefined) {
            root.children[0].descendants().map((m: any) => m.x -= (circleRadius * 2));
            root.children[1].descendants().map((m: any) => m.x += (circleRadius * 2));
          }
          if (root.children[1].children === undefined) {
            root.children[0].descendants().map((m: any) => m.x -= (circleRadius * 2));
            root.children[1].descendants().map((m: any) => m.x += (circleRadius * 2));
          }
        }
        root.children.map((m: any, i: any) => m.rowOrder = i);
        root.children.forEach((c: any) => {
          c.descendants().forEach((m: any) => {
            m.rowOrder = c.rowOrder;
          });
        })
      }
      //custom shape
      const alterPath = "M 0, 14 L 0,0 A14,14 0 0,1 14,-14 A14,14 0 0,1 14,14  Z";
      root.y = -(circleRadius * 1.5);
      const heightExtent: any = d3.extent(root.descendants(), (d: any) => d.x)
      treeHeight = (heightExtent[1] - heightExtent[0]) + (circleRadius * 3);
      const widthExtent: any = d3.extent(root.descendants(), (d: any) => d.y)
      treeWidth = widthExtent[1] - widthExtent[0];

      const pie = d3.pie()
        .value((d: any) => d.value)

      const arc = getArc(circleRadius)

      function getArc(myRadius: any) {
        return d3.arc()
          .innerRadius(myRadius - 14)
          .outerRadius(myRadius - 4);
      }

      const linkData: any = [];
      root.links().forEach((m: any) => {
        linkData.push({
          source: m.source,
          target: m.target,
          linkLabel: isNaN(m.target.data.averageDays) ? null : m.target.data.averageDays + " days"
        })
      })
      // dates header group
      const linkGroup = mySvg
        .select(".linkGroup")
        .selectAll('.linkGroup' + myClass)
        .data(linkData)
        .join(function (group: any): any {
          const enter = group.append('g').attr('class', 'linkGroup' + myClass);
          enter.append('path').attr('class', 'treeLink' + myClass);
          enter.append('svg').attr('class', 'treeLabelSvg').append('path').attr('class', 'treeLabelPath');
          enter.append('text').attr('class', 'treeLinkLabel');
          return enter;
        });

      linkGroup.attr("transform", "translate(" + (margin.left + (circleRadius * 2.5)) + "," + ((treeHeight / 2) + margin.top) + ")");

      linkGroup.select('.treeLabelSvg')
        .attr("display", (d: any) => d.linkLabel === null || !myChart.props.daysLabelVisibility ? "none" : "block")
        .attr('viewBox', labelViewBox)
        .attr('width', 80)
        .attr('height', 20)
        .attr("transform", (d: any) => "translate(" + (d.source.y) + "," + (d.source.x) + ")");

      linkGroup.select('.treeLabelPath')
        .attr("display", (d: any) => d.linkLabel === null || !myChart.props.daysLabelVisibility ? "none" : "block")
        .attr('fill', (d: any) => linkColors[d.source.rowOrder])
        .attr('d', labelSvg);

      linkGroup.select('.treeLinkLabel')
        .attr('dy', -2)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr("font-family", "Poppins")
        .attr("font-size", 11)
        .attr("font-weight", 700)
        .text((d: any) => d.linkLabel === null || !myChart.props.daysLabelVisibility ? "" : d.linkLabel);

      linkGroup.select('.treeLink' + myClass)
        .attr("stroke", (d: any) => linkColors[d.target.rowOrder])
        .attr("stroke-width", 2)
        .attr("fill", "none");

      function getTreeLink(d: any) {
        if (d.source.depth === 0) {
          let stepLink: any = d3.link(d3.curveStepBefore)
            .x((d: any) => d.y + (circleRadius * 2))
            .y((d: any) => d.x + (d.extraYPos === undefined ? 0 : d.extraYPos))(d);
          const points = stepLink.split("L")
          const startPoint = (+points[0].split(",")[0].split("M")[1] + circleRadius / 2);
          let curveString = "", targetExtra = 0;
          if (d.source.x > d.target.x) {
            curveString = " q 0 -20 20 -20 ";
            targetExtra = 20;
          } else {
            curveString = " q 0 20 20 20 ";
            targetExtra = -20
          }
          const start = "M" + (startPoint) + "," +
            (d.source.extraYPos === undefined ? 0 : d.source.extraYPos);
          const middle = " L" + startPoint + "," + (+points[1].split(",")[1] + targetExtra) + curveString
          const points2 = points[2].split(",")
          return start + middle + " L" + (+points2[0] - circleRadius) + " " + +points2[1];
        } else {
          return d3.linkHorizontal()
            .x((d: any) => d.y + circleRadius + (d.depth > 2 ? (extraDy * (d.depth - 2)) : 0))
            .y((d: any) => d.x + (d.extraYPos === undefined ? 0 : d.extraYPos) + (d.extraLaterYPos === undefined ? 0 : d.extraLaterYPos))(d);
        }

      }
      //look into custom
      //https://bl.ocks.org/larsenmtl/8f26a673f66801ab34ef0fa352662627

      root.descendants().map((m: any) => m.xPos = (margin.left + m.y + (circleRadius * 2.5)));
      root.descendants().map((m: any) => m.yPos = (m.x + (treeHeight / 2) + margin.top));
      root.descendants().map((m: any, i: any) => m.index = i);

      // dates header group
      const treeGroup = mySvg
        .select(".nodeGroup")
        .selectAll('.treeGroup' + myClass)
        .data(root.descendants(), (d: any) => d.index)
        .join(function (group: any): any {
          const enter = group.append('g').attr('class', 'treeGroup' + myClass);
          enter.append('circle').attr('class', 'treeCircle');
          enter.append('text').attr('class', 'treeLabel');
          enter.append('g').attr('class', 'pieGroup');
          enter.append('path').attr('class', 'downChildrenCirclePath');
          enter.append('circle').attr('class', 'plusMinusCircle');
          enter.append('text').attr('class', 'plusMinusLabel');
          enter.append('path').attr('class', 'alertPath');
          enter.append('circle').attr('class', 'alertCircle');
          enter.append('circle').attr('class', 'alertValueCircle');
          enter.append('text').attr('class', 'alertLabel');
          enter.append('svg').attr('class', 'alertSvg').append('path').attr('class', 'alertIconPath');
          enter.append('g').attr('class', 'downChildren');
          enter.append('text').attr('class', 'patientLevelLabelTop');
          enter.append('text').attr('class', 'patientLevelLabelBottom');
          return enter;
        });

      treeGroup.select('.patientLevelLabelTop')
        .attr("y", -4)
        .attr("fill", "#8A98AB")
        .attr("font-family", "Poppins")
        .attr("font-size", 10)
        .attr("font-weight", 400)
        .attr("text-anchor", "middle")
        .text(myChart.props.patientLevelView || myChart.props.dynamicDonutVariable.length>3 ? "Total" : "")

      treeGroup.select('.patientLevelLabelBottom')
        .attr("y", 12)
        .attr("fill", "#101D42")
        .attr("font-family", "Poppins")
        .attr("font-size", 12)
        .attr("font-weight", 500)
        .attr("text-anchor", "middle")
        .text((d: any) => {
          const sumCount:any = d3.sum(d.data.pieData, (s: any) => s.value)
          return myChart.props.patientLevelView || myChart.props.dynamicDonutVariable.length>3 ?
            myChart.numberPipe.transform(sumCount) : ""
        })

      treeGroup.select('.downChildrenCirclePath')
        .attr('pointer-events', 'none')
        .attr("stroke", (d: any) => linkColors[d.rowOrder])
        .attr("stroke-width", 2)
        .attr("fill", "transparent")
        .attr("d", (d: any) => d.data.downChildren === undefined || d.data.downChildren.length === 0 || d.expanded === undefined || d.expanded === false ? "" : "M0,0 " + " q " + (acrossLinkGap / 2) + " 0 " + (acrossLinkGap / 2) + " " + (acrossLinkGap / 2))
        .attr("transform", "translate(" + circleRadius + ",0)");

      treeGroup.select('.alertSvg')
        .attr('pointer-events', 'none')
        .attr("display", (d: any) => d.data.alertCount.total === 0 || (d.depth <= 1 && d.children !== undefined) || (d.depth > 1 && d.expanded !== undefined && d.expanded === true) ? "none" : "block")
        .attr('x', circleRadius + 8)
        .attr('y', -circleRadius - 5.5)
        .attr('viewBox', alertViewBox)
        .attr('width', 12)
        .attr('height', 12)

      treeGroup.select('.alertIconPath')
        .attr('pointer-events', 'none')
        .attr('fill', '#FF9A3D')
        .attr('d', (d: any) => d.data.alertCount.total === 0 ? "" : alertSvg);

      treeGroup.select('.alertPath')
        .attr("display", (d: any) => d.data.alertCount.total === 0 || (d.depth <= 1 && d.children !== undefined) || (d.depth > 1 && d.expanded !== undefined && d.expanded === true) ? "none" : "block")
        .attr('d', (d: any) => d.data.alertCount.total === 0 ? "" : alterPath)
        .attr("transform", "translate(" + (circleRadius) + "," + (-circleRadius) + ")")
        .on('click', (event: any, d: any) => {
          const myX = svgBounds.x + d.xPos + circleRadius + 28 + 4; //14 is alertPathWidth?
          const myY = svgBounds.y + d.yPos - circleRadius - 14; //8 is Figma gap between circle + tooltip
          myChart.showAlertTooltip('alert', d.data, event.offsetX, event.offsetY);
        })
        .on('mouseout', () => {
          myChart.hideAlertTooltip('alert');
        });

      treeGroup.select('.alertCircle')
        .attr("display", (d: any) => d.data.alertCount.total === 0 || (d.depth <= 1 && d.children !== undefined) || (d.depth > 1 && d.expanded !== undefined && d.expanded === true) ? "none" : "block")
        .attr('pointer-events', 'none')
        .attr('r', (d: any) => d.data.alertCount.total === 0 ? 0 : 12)
        .attr('fill', '#FFF5DB')
        .attr("transform", "translate(" + (circleRadius + 14) + "," + (-circleRadius) + ")");

      treeGroup.select('.alertValueCircle')
        .attr("display", (d: any) => d.data.alertCount.total === 0 || (d.depth <= 1 && d.children !== undefined) || (d.depth > 1 && d.expanded !== undefined && d.expanded === true) ? "none" : "block")
        .attr('pointer-events', 'none')
        .attr('r', (d: any) => d.data.alertCount.total === 0 ? 0 : 8)
        .attr('fill', '#FF9315')
        .attr("transform", "translate(" + (circleRadius + 14 + 12) + "," + (-circleRadius - 9) + ")")

      treeGroup.select('.alertLabel')
        .attr("display", (d: any) => d.data.alertCount.total === 0 || (d.depth <= 1 && d.children !== undefined) || (d.depth > 1 && d.expanded !== undefined && d.expanded === true) ? "none" : "block")
        .attr('pointer-events', 'none')
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr("font-family", "Poppins")
        .attr("font-size", (d: any) => d.data.alertCount.total > 1000 ? 7 : 8)
        .attr("font-weight", 600)
        .text((d: any) => d.data.alertCount.total > 0 ? d.data.alertCount.total : "")
        .attr("transform", "translate(" + (circleRadius + 14 + 12) + "," + (-circleRadius + 2.5 - 8.5) + ")");

      treeGroup.select('.plusMinusCircle')
        .attr("visibility", (d: any) => d.depth <= 1 || d.data.downChildren.descendants().length > 1 ? "visible" : "hidden")
        .attr("id", (d: any, i: any) => "plusMinusCircle" + i)
        .attr("stroke-width", 2)
        .attr("cursor", "pointer")
        .attr("stroke", (d: any) => linkColors[d.rowOrder])
        .attr("fill", (d: any) => {
          if (d.expanded !== undefined && d.expanded === true) {
            return "white";
          } else {
            return d.depth <= 1 ? (d.expanded === undefined ? "white" : linkColors[d.rowOrder]) : linkColors[d.rowOrder];
          }
        })
        .attr("cx", circleRadius)
        .attr("r", (d: any) => d.depth > 1 ? (d.data.downChildren === undefined || d.data.downChildren.length === 0 ? 0 : 8) : 8)
        .on("click", function (event: any, d: any) {
          myChart.hideTooltip();
          if (d.data.downChildren !== undefined) {
            if (d.expanded === undefined || d.expanded === false) {
              d.expanded = true;
              if (d.rowOrder === (root.children.length - 1)) {
                //final row
                drawTree();
              } else {
                drawTree();
              }
            } else {
              d.visibleChildren = [];
              d.expanded = false;
              expandedDownChildren = [];
              drawTree();
            }
          } else if (d.depth <= 1) {
            if (d.expanded === undefined || d.expanded === true) {
              d.expanded = false;
              root.descendants().map((m: any) => m.expanded = (m.depth >= 2 ? false : m.expanded));
              root.descendants().map((m: any) => m.extraLaterYPos = undefined);
              root.descendants().map((m: any) => m.visibleChildren = []);
              root.descendants().map((m: any) => m.extraYPos = 0);
              d._children = d.children;
              d.children = undefined;
              expandedDownChildren = [];
              drawTree();
            } else {
              d.expanded = true;
              d.children = d._children;
              drawTree();
            }
          }
        })

      treeGroup.select('.plusMinusLabel')
        .attr("visibility", (d: any) => d.depth <= 1 || d.data.downChildren.descendants().length > 1 ? "visible" : "hidden")
        .attr("id", (d: any, i: any) => "plusMinusCircle" + i)
        .attr("pointer-events", "none")
        .attr("text-anchor", "middle")
        .attr("fill", (d: any) => {
          if (d.expanded !== undefined && d.expanded === true) {
            return linkColors[d.rowOrder];
          } else {
            return d.depth <= 1 ? (d.expanded === undefined ? linkColors[d.rowOrder] : "white") : "white"
          }
        })
        .attr("font-size", (d: any) => (d.depth <= 1 && d.expanded === undefined) || (d.expanded !== undefined && d.expanded === true) ? 25 : 20)
        .attr("x", circleRadius + 0.25)
        .attr("y", (d: any) => (d.depth <= 1 && d.expanded === undefined) || (d.expanded !== undefined && d.expanded === true) ? 9.25 : 6.75)
        .text((d: any) => d.depth > 1 ? (d.data.downChildren === undefined || d.data.downChildren.length === 0 ? "" : ((d.expanded !== undefined && d.expanded === true) ? "-" : "+")) : (d.expanded === undefined || d.expanded === true ? "-" : "+"))

      treeGroup.select('.treeCircle')
        .attr("fill", "white")
        .attr("stroke", "#E6DBFF")
        .attr("stroke-width", 2)
        .attr("r", circleRadius)
        .on('click', (event: any, d: any) => {
          const tooltipFooterHeight = 30 * d.data.pieData.length;
          const myX = svgBounds.x + d.xPos - 21; //21 is the Figma distance between left + arrow centre
          const myY = svgBounds.y + d.yPos - circleRadius - 38 - tooltipFooterHeight; //8 is Figma gap between circle + tooltip
          myChart.showTooltip('circle', d.data, event.offsetX, event.offsetY);
        });

      treeGroup.select('.treeLabel')
        .attr('pointer-events', 'none')
        .attr("dy", 0)
        .attr("transform", (d: any) => "translate(0," + (d.depth === 1 && d.rowOrder === 0 ? -circleRadius - 7 : circleRadius + 18) + ")")
        .attr("text-anchor", "middle")
        .attr("font-family", "Poppins")
        .attr("font-size", 12)
        .attr("font-weight", 700)
        .attr("fill", "#101D42")
        .text((d: any) => d.data.name)
        .call(wrap, circleRadius * 2);

      const pieLabelThreshold = d3.scaleThreshold().domain(myChart.props.pieLabelSizeThresholds).range(myChart.props.pieLabelSizeThresholdFontSize)
      const pieGroup = treeGroup.select('.pieGroup')
        .selectAll('.pieGroupItems')
        .data((d: any) => {
          const arcData = pie(d.data.pieData);
          arcData.map((m: any) => m.data.extraPieCount = d.data.pieData.length - 2);
          return arcData;

        })
        .join(function (group: any): any {
          const enter = group.append('g').attr('class', 'pieGroupItems').attr("pointer-events", "none");
          enter.append('path').attr('class', 'pieArc');
          enter.append('text').attr('class', 'pieLabel');
          return enter;
        });

      pieGroup.select('.pieArc')
        .attr('fill', (d: any) => d.data.color)
        .attr('d', arc)

      pieGroup.select('.pieLabel')
        .attr("y", (d: any, i: any) => -2 + (i * (pieLabelThreshold(d.data.value) + 2)) - (d.data.extraPieCount * pieLabelThreshold(d.data.value)) / 2)
        .attr("text-anchor", "middle")
        .attr("font-size", (d: any) => pieLabelThreshold(d.data.value))
        .attr("font-weight", 700)
        .attr('fill', (d: any) => d.data.color) // d.data.color
        .text((d: any) => myChart.props.patientLevelView || myChart.props.dynamicDonutVariable.length>3 ? "" : myChart.numberPipe.transform(d.data.value));

      transferChildren.map((m: any) => {
        m['pieData'] = [];
        myChart.props.dynamicDonutVariable.forEach((d:any) => {
          const obj = {
            value: m[d.keyName], 
            label: d.name,
            color: myChart.props.pieColors[d.name]
          }
          m.pieData.push(obj)
        })
        return m
      })
      // transferChildren.map((m: any) => m.pieData = [{
      //   value: m[myChart.props.variableTop], 
      //   label: myChart.props.variableTopLabel,
      //   color: myChart.props.pieColors[myChart.props.variableTopLabel]
      // },
      // {
      //   value: m[myChart.props.variableBottom], label: myChart.props.variableBottomLabel,
      //   color: myChart.props.pieColors[myChart.props.variableBottomLabel]
      // }]
      // )
      const middleGroup = mySvg
        .selectAll('.middleGroupItems' + myClass)
        .data(root.descendants().length === 1 ? [] : transferChildren)
        .join(function (group: any): any {
          const enter = group.append('g').attr('class', 'middleGroupItems' + myClass);
          enter.append('path').attr('class', 'middleLinkUp' + myClass);
          enter.append('path').attr('class', 'middleLinkDown' + myClass);
          enter.append('circle').attr('class', 'middleCircle');
          enter.append('text').attr('class', 'middleLabel');
          enter.append('g').attr('class', 'middlePieGroup');
          return enter;
        });

      middleGroup
        .attr("transform", (d: any, i: any) => {
          d.sourceNode = root.descendants().find((f: any) => f.data.name === d.node_4);
          d.targetNode = root.descendants().find((f: any) => f.data.name === d.node_2);
          d.xPos = (d.sourceNode.xPos + (i === 0 ? - circleRadius * 1.5 : circleRadius * 1.5))
          d.yPos = Math.abs(d.targetNode.x - d.sourceNode.x) - circleRadius;
          d.topNode = d.sourceNode.yPos > d.targetNode.yPos ? d.targetNode : d.sourceNode;
          d.bottomNode = d.sourceNode.yPos > d.targetNode.yPos ? d.sourceNode : d.targetNode;
        })

      middleGroup.select('.middleCircle')
        .attr("fill", "white")
        .attr("stroke", "#E6DBFF")
        .attr("stroke-width", 2)
        .attr("r", circleRadius)
        .on('click', (event: any, d: any) => {
          const myX = svgBounds.x + d.xPos;
          const myY = svgBounds.y + d.yPos;
          myChart.showTooltip('circle', d, event.offsetX, event.offsetY);
        });

      middleGroup.select('.middleLabel')
        .attr("pointer-events", "none")
        .attr("dy", 0)
        .attr("transform", (d: any) => "translate(" + (d.children ? -6 : 6) + "," + (circleRadius + 18) + ")")
        .attr("text-anchor", "middle")
        .attr("font-size", 14)
        .attr("font-weight", 700)
        .attr("fill", "#101D42")
        .text((d: any) => d.node_3)
        .call(wrap, circleRadius * 2.5);

      middleGroup.select('.middleLinkUp' + myClass)
        .attr("pointer-events", "none")
        .attr("stroke-width", 2)
        .attr("fill", "none")
        .attr("stroke", (d: any) => myChart.props.linkColors[d.targetNode.rowOrder])
        .attr("marker-end", (d: any) => d.topNode.data.name === d.sourceNode.data.name ? "url(#arrowhead1)" : "");

      middleGroup.select('.middleLinkDown' + myClass)
        .attr("pointer-events", "none")
        .attr("stroke-width", 2)
        .attr("fill", "none")
        .attr("stroke", (d: any) => myChart.props.linkColors[d.targetNode.rowOrder])
        .attr("marker-end", (d: any) => d.bottomNode.data.name === d.sourceNode.data.name ? "url(#arrowhead0)" : "");

      const middlePieGroup = middleGroup.select('.middlePieGroup')
        .selectAll('.middlePieGroupItems')
        .data((d: any) => {
          const arcData = pie(d.pieData);
          arcData.map((m: any) => m.data.extraPieCount = d.pieData.length - 2);
          return arcData;

        })
        .join(function (group: any): any {
          const enter = group.append('g').attr('class', 'middlePieGroupItems').attr("pointer-events", "none");
          enter.append('path').attr('class', 'middlePieArc');
          enter.append('text').attr('class', 'middlePieLabel');
          return enter;
        });

      middlePieGroup.select('.middlePieArc')
        .attr('fill', (d: any) => d.data.color)
        .attr('d', arc)

      middlePieGroup.select('.middlePieLabel')
        .attr("y", (d: any, i: any) => -2 + (i * 14) - (d.data.extraPieCount * 14) / 2)
        .attr("text-anchor", "middle")
        .attr("font-size", 12)
        .attr("font-weight", 700)
        .attr('fill', (d: any) => d.data.color)
        .text((d: any) => d.data.value);


      const downGroup = treeGroup.select('.downChildren')
        .selectAll('.downChildrenItems')
        .data((d: any) => {
          const myChildPosition = d.parent === null || d.parent.children === undefined ? 0 : d.parent.children.findIndex((f: any) => f === d);
          const laterChildren = d.parent === null || d.parent.children === undefined ? [] : d.parent.children.filter((f: any, i: any) => i > myChildPosition);
          if (d.data.downChildren === undefined || d.expanded === undefined || d.expanded === false) {
            laterChildren.map((m: any) => m.extraLaterYPos = 0);
            d.visibleChildren = [];
          } else {
            const myDownChildren = getCustomTree(d.data.downChildren);
            myDownChildren.map((m: any) => m.circleRadius = (m.depth === 1 ? mediumCircleRadius : smallCircleRadius))
            myDownChildren.map((m: any) => m.rowOrder = d.rowOrder);
            const extraY: any = d3.max(myDownChildren, (m: any) => m.y);
            laterChildren.map((m: any) => m.extraLaterYPos = extraY);
            d.visibleChildren = myDownChildren
          }
          return d.visibleChildren;
        })
        .join(function (group: any): any {
          const enter = group.append('g').attr('class', 'downChildrenItems');
          enter.append('circle').attr('class', 'downLinkCircle');
          enter.append('path').attr('class', 'downCirclePath');
          enter.append('path').attr('class', 'downCirclePathDown');
          enter.append('path').attr('class', 'upPath');
          enter.append('g').attr('class', 'downPieArcGroup');
          enter.append('text').attr('class', 'downCircleLabel');
          enter.append('circle').attr('class', 'downPlusMinusCircle');
          enter.append('text').attr('class', 'downPlusMinusLabel');
          enter.append('path').attr('class', 'alertPath');
          enter.append('circle').attr('class', 'alertCircle');
          enter.append('circle').attr('class', 'alertValueCircle');
          enter.append('text').attr('class', 'alertLabel');
          enter.append('svg').attr('class', 'alertSvg').append('path').attr('class', 'alertIconPath');
          enter.append('text').attr('class', 'downPatientLevelLabelTop');
          enter.append('text').attr('class', 'downPatientLevelLabelBottom');
          return enter;
        });

      downGroup.select('.downPatientLevelLabelTop')
        .attr("x", (d: any) => d.x)
        .attr("y", (d: any) => d.y - 4)
        .attr("fill", "#8A98AB")
        .attr("font-family", "Poppins")
        .attr("font-size", 10)
        .attr("font-weight", 400)
        .attr("text-anchor", "middle")
        .text(myChart.props.patientLevelView || myChart.props.dynamicDonutVariable.length>3 ? "Total" : "")

      downGroup.select('.downPatientLevelLabelBottom')
        .attr("x", (d: any) => d.x)
        .attr("y", (d: any) => d.y + 12)
        .attr("fill", "#101D42")
        .attr("font-family", "Poppins")
        .attr("font-size", 12)
        .attr("font-weight", 500)
        .attr("text-anchor", "middle")
        .text((d: any) => {
          const sumCount:any = d3.sum(d.data.pieData, (s: any) => s.value)
          return myChart.props.patientLevelView || myChart.props.dynamicDonutVariable.length>3 ?
            myChart.numberPipe.transform(sumCount) : ""
        })

      downGroup.select('.alertSvg')
        .attr('pointer-events', 'none')
        .attr("display", (d: any) => d.data.alertCount.total === 0 || d.expanded === true ? "none" : "block")
        .attr('x', (d: any) => circleRadius + 8 + d.x)
        .attr('y', (d: any) => -circleRadius - 5.5 + d.y)
        .attr('viewBox', alertViewBox)
        .attr('width', 12)
        .attr('height', 12)

      downGroup.select('.alertIconPath')
        .attr('pointer-events', 'none')
        .attr('fill', '#FF9A3D')
        .attr('d', (d: any) => d.data.alertCount.total === 0 ? "" : alertSvg);

      downGroup.select('.alertPath')
        .attr("display", (d: any) => d.data.alertCount.total === 0 || d.expanded === true ? "none" : "block")
        .attr('d', (d: any) => d.data.alertCount.total === 0 ? "" : alterPath)
        .attr("transform", (d: any) => "translate(" + (circleRadius + d.x) + "," + (-circleRadius + d.y) + ")")
        .on('click', (event: any, d: any) => {
          myChart.showAlertTooltip('alert', d.data, event.offsetX, event.offsetY);
        })
        .on('mouseout', () => {
          myChart.hideAlertTooltip('alert');
        });

      downGroup.select('.alertCircle')
        .attr("display", (d: any) => d.data.alertCount.total === 0 || d.expanded === true ? "none" : "block")
        .attr('pointer-events', 'none')
        .attr('r', (d: any) => d.data.alertCount.total === 0 ? 0 : 12)
        .attr('fill', '#FFF5DB')
        .attr("transform", (d: any) => "translate(" + (circleRadius + 14 + d.x) + "," + (-circleRadius + d.y) + ")");

      downGroup.select('.alertValueCircle')
        .attr("display", (d: any) => d.data.alertCount.total === 0 || d.expanded === true ? "none" : "block")
        .attr('pointer-events', 'none')
        .attr('r', (d: any) => d.data.alertCount.total === 0 ? 0 : 8)
        .attr('fill', '#FF9315')
        .attr("transform", (d: any) => "translate(" + (circleRadius + 14 + 12 + d.x) + "," + (-circleRadius - 9 + d.y) + ")")

      downGroup.select('.alertLabel')
        .attr("display", (d: any) => d.data.alertCount.total === 0 || d.expanded === true ? "none" : "block")
        .attr('pointer-events', 'none')
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr("font-family", "Poppins")
        .attr("font-size", (d: any) => d.data.alertCount.total > 1000 ? 7 : 8)
        .attr("font-weight", 600)
        .text((d: any) => d.data.alertCount.total > 0 ? d.data.alertCount.total : "")
        .attr("transform", (d: any) => "translate(" + (circleRadius + 14 + 12 + d.x) + "," + (-circleRadius + 2.5 - 8.5 + d.y) + ")");

      downGroup.select('.downPlusMinusCircle')
        .attr("stroke-width", 2)
        .attr("cursor", "pointer")
        .attr("stroke", (d: any) => linkColors[d.rowOrder])
        .attr("fill", (d: any) => {
          if (d.expanded !== undefined && d.expanded === true) {
            return "white";
          } else {
            return linkColors[d.rowOrder]
          }
        })
        .attr("r", (d: any) => d.children === undefined || d.children.length === 0 ? 0 : 8)
        .attr('transform', (d: any) => 'translate(' + (d.x + d.circleRadius) + ',' + d.y + ')')
        .on("click", (event: any, d: any) => {
          myChart.hideTooltip();
          if (d.expanded === undefined || d.expanded === false) {
            expandedDownChildren.push(d.data.node_key);
            d.expanded = true;
            if (d.rowOrder !== (root.children.length - 1)) {
            }
          } else {
            expandedDownChildren = expandedDownChildren.filter((f: any) => f !== d.data.node_key);
            d.descendants().forEach((c: any) => {
              expandedDownChildren = expandedDownChildren.filter((f: any) => f !== c.data.node_key);
            })
            d.expanded = false;
          }
          drawTree();
        });

      downGroup.select('.downPlusMinusLabel')
        .attr("pointer-events", "none")
        .attr("text-anchor", "middle")
        .attr("fill", (d: any) => {
          if (d.expanded !== undefined && d.expanded === true) {
            return linkColors[d.rowOrder];
          } else {
            return "white"
          }
        })
        .attr("font-size", (d: any) => d.expanded !== undefined && d.expanded === true ? 25 : 20)
        .attr("x", (d: any) => d.circleRadius + 0.25)
        .attr("y", (d: any) => d.expanded !== undefined && d.expanded === true ? 9.25 : 6.75)
        .text((d: any) => d.children === undefined || d.children.length === 0 ? "" : (d.expanded !== undefined && d.expanded === true ? "-" : "+"))
        .attr('transform', (d: any) => 'translate(' + (d.x) + ',' + d.y + ')');

      //update this bit
      downGroup.select('.downCirclePathDown')
        .attr("pointer-events", "none")
        .attr("stroke", (d: any) => linkColors[d.rowOrder])
        .attr("stroke-width", 2)
        .attr("fill", "transparent")
        .attr("d", (d: any) => d.children === undefined || d.expanded === undefined || d.expanded === false ? "" : "m " + (d.circleRadius)
          + " " + (acrossLinkGap / 4) + " q " + (acrossLinkGap / 2) + " 0 " + (acrossLinkGap / 2) + " " + (acrossLinkGap / 2))
        .attr('transform', (d: any) => 'translate(' + (d.x) + ',' + d.y + ')')

      downGroup.select('.upPath')
        .attr("pointer-events", "none")
        .attr("stroke", (d: any) => linkColors[d.rowOrder])
        .attr("stroke-width", 2)
        .attr("fill", "transparent")
        .attr("d", (d: any) => "m -" + (d.circleRadius + (acrossLinkGap / 2)) + "-" + (acrossLinkGap / 2) + " l 0 "
          + (d.parent.y === undefined ? -d.y + acrossLinkGap : (- (d.y - d.parent.y) + acrossLinkGap)))
        .attr('transform', (d: any) => 'translate(' + d.x + ',' + d.y + ')')

      downGroup.select('.downCirclePath')
        .attr("pointer-events", "none")
        .attr("stroke", (d: any) => linkColors[d.rowOrder])
        .attr("stroke-width", 2)
        .attr("fill", "transparent")
        .attr("d", (d: any) => "m -" + (d.circleRadius + (acrossLinkGap / 2)) + "-" + (acrossLinkGap / 2) + " q 0 " + (acrossLinkGap / 2) + " " + (acrossLinkGap / 2) + " " + (acrossLinkGap / 2))
        .attr('transform', (d: any) => 'translate(' + d.x + ',' + d.y + ')')

      downGroup.select('.downLinkCircle')
        .attr("id", (d: any) => d.totalExpandedChildren)
        .attr("r", (d: any) => d.depth === 1 ? mediumCircleRadius : smallCircleRadius)
        .attr("fill", "white")
        .attr("cx", (d: any) => d.x)
        .attr("cy", (d: any) => d.y)
        .on('click', (event: any, d: any) => {
          myChart.showTooltip('circle', d, event.offsetX, event.offsetY);
        });

      downGroup.select('.downPieArcGroup')
        .attr('transform', (d: any) => 'translate(' + d.x + ',' + d.y + ')')

      downGroup.select('.downCircleLabel')
        .attr("pointer-events", "none")
        .attr("dy", 0)
        .attr("text-anchor", (d: any) => d.data.children === undefined ? "start" : "middle")
        .attr("font-size", 12)
        .attr("font-weight", 700)
        .attr('fill', (d: any) => d.data.color)
        .text((d: any) => d.data.name)
        .attr('transform', (d: any) => 'translate(' + (d.x + (d.data.children === undefined ? d.circleRadius + 5 : 0))
          + ',' + (d.y + (d.data.children === undefined ? 0 : d.circleRadius + 12)) + ')')
        .each(function (d: any) {
          const wrapWidth = d.data.children === undefined || (d.expanded === undefined || d.expanded === false) ? d.circleRadius * 4 : d.circleRadius * 2;
          //@ts-ignore
          wrap(d3.select(this), wrapWidth, 11)
        });

      const downPieGroup = downGroup.select('.downPieArcGroup')
        .selectAll('.downPieArcGroupItems')
        .data((d: any) => {
          d.data.pieData.map((m: any) => m.depth = d.depth);
          d.data.pieData.map((m: any) => m.extraPieCount = d.data.pieData.length - 2);
          return d.data.pieData === undefined ? [] : pie(d.data.pieData)
        })
        .join(function (group: any): any {
          const enter = group.append('g').attr('class', 'downPieArcGroupItems').attr("pointer-events", "none");
          enter.append('path').attr('class', 'downPieArc');
          enter.append('text').attr('class', 'downPieLabel');
          return enter;
        });

      downPieGroup.select(".downPieArc")
        .attr('fill', (d: any) => d.data.color)
        .attr('d', (d: any) => getArc((d.data.depth === 1 ? mediumCircleRadius : smallCircleRadius))(d))

      downPieGroup.select('.downPieLabel')
        .attr("y", (d: any, i: any) => -2 + (i * 14) - (d.data.extraPieCount * 14) / 2)
        .attr("text-anchor", "middle")
        .attr("font-size", 12)
        .attr("font-weight", 700)
        .attr('fill', (d: any) => d.data.color) //d.data.color
        .text((d: any) => myChart.props.patientLevelView || myChart.props.dynamicDonutVariable.length>3 ? '' : myChart.numberPipe.transform(d.data.value));

      const maxChildDepth: any = d3.max(root.descendants(), (m: any) => {
        const visibleChildren: any = m.visibleChildren === undefined ? [] : m.visibleChildren;
        const myMax: any = visibleChildren.length === 0 ? 0 : d3.max(visibleChildren, (c: any) => c.depth);
        return myMax;
      });
      if (maxChildDepth > 1) {
        extraDy = (maxChildDepth - 1) * subDepthWidth;
      } else {
        extraDy = 0;
      }

      const depth1Nodes = root.descendants().filter((f: any) => f.depth === 1);

      depth1Nodes.forEach((d: any) => {
        const childExtraYs: any = {};
        if (d.children !== undefined) {
          d.descendants().forEach((c: any) => {
            if (c.children !== undefined) {
              c.children.forEach((v: any) => {
                if (childExtraYs[v.data.processGroup] === undefined) {
                  childExtraYs[v.data.processGroup] = 0;
                }
                if (v.visibleChildren !== undefined && v.visibleChildren.length > 0) {
                  childExtraYs[v.data.processGroup] = Math.max(v.visibleChildren.length, childExtraYs[v.data.processGroup])
                }
              })
            }
          })
        }
        let maxY = d3.sum(Object.values(childExtraYs));
        const myExtraY: any = maxY === 0 ? 0 : maxY * subDepthHeight;
        const myChildPosition = depth1Nodes.findIndex((f: any) => f === d);
        depth1Nodes.filter((m: any, i: any) => i > myChildPosition)
          .map((m: any) => {
            m.extraYPos = myExtraY;
            m.parent.extraYPos = (myExtraY === 0 ? 0 : myExtraY / 2)
            m.descendants().map((c: any) => c.extraYPos = (c.extraYPos === undefined ? myExtraY : myExtraY));
          });
      });
      mySvg.selectAll(".treeGroup" + myClass)
        .attr("transform", (d: any) => "translate(" + (d.xPos + (d.depth > 2 ? (extraDy * (d.depth - 2)) : 0) + (d.depth === 0 ? circleRadius * 2.5 : 0)) + ","
          + (d.yPos + (d.extraYPos === undefined ? 0 : d.extraYPos)
            + (d.extraLaterYPos === undefined ? 0 : d.extraLaterYPos)) + ")");

      mySvg.selectAll(".treeLabelSvg")
        .attr('x', (d: any) => d.source.y + (dy / 2) - 40 + (d.source.depth >= 2 ? ((extraDy * (d.source.depth - 2)) + (extraDy / 2)) : 0))
        .attr('y', (d: any) => d.source.x - 12 + (d.source.extraYPos === undefined ? 0 : d.source.extraYPos))

      mySvg.selectAll('.treeLinkLabel')
        .attr('x', (d: any) => d.source.y + (dy / 2) + (d.source.depth >= 2 ? ((extraDy * (d.source.depth - 2)) + (extraDy / 2)) : 0))
        .attr('y', (d: any) => d.source.x + 3 + (d.source.extraYPos === undefined ? 0 : d.source.extraYPos))

      mySvg.selectAll('.middleGroupItems' + myClass)
        .attr("transform", (d: any) => "translate(" + d.xPos + "," + (d.yPos + (d.bottomNode.extraYPos === undefined ? 0 : (d.bottomNode.extraYPos / 2))) + ")");

      mySvg.selectAll(".treeLink" + myClass).attr("d", getTreeLink);

      mySvg.selectAll(".middleLinkUp" + myClass)
        .attr("d", (c: any) => {
          if (c.topNode.data.name === c.sourceNode.data.name) {
            const extraY = c.targetNode.extraYPos / 2;
            const midPoint = [-10 + (circleRadius * 1.5), 0];
            c.start = "M0,0"
            c.curve = " L " + (midPoint[0] - 10) + "," + midPoint[1]
              + " Q" + midPoint[0] + "," + midPoint[1] + " "
              + midPoint[0] + "," + (midPoint[1] - 10);
            c.end = " L" + (-10 + (circleRadius * 1.5)) + "," + (-(c.yPos - c.topNode.yPos + extraY) + circleRadius + 12)
            return c.start + c.curve + c.end;
          } else {
            const extraY = c.sourceNode.extraYPos / 2;
            const midPoint = [10 - (circleRadius * 1.5), 0];
            c.start = "M10,0"
            c.curve = " L " + (midPoint[0] + 10) + "," + midPoint[1]
              + " Q" + midPoint[0] + "," + midPoint[1] + " "
              + midPoint[0] + "," + (midPoint[1] - 10);
            c.end = "L" + (10 - (circleRadius * 1.5)) + "," + - (c.yPos - c.topNode.yPos - circleRadius + extraY);
            return c.start + c.curve + c.end;
          }
        })

      mySvg.selectAll(".middleLinkDown" + myClass)
        .attr("d", (c: any) => {
          if (c.topNode.data.name === c.sourceNode.data.name) {
            const extraY = c.targetNode.extraYPos / 2;
            const midPoint = [10 + circleRadius, 10];
            c.start = "M0,0"
            c.curve = " L " + (midPoint[0] - 10) + "," + (midPoint[1])
              + " Q" + midPoint[0] + "," + midPoint[1] + " "
              + (midPoint[0]) + "," + (midPoint[1] + 10);
            c.end = " L" + (midPoint[0]) + "," + ((c.bottomNode.yPos - c.yPos + extraY) - circleRadius)
            return c.start + c.curve + c.end;
          } else {
            const extraY = c.sourceNode.extraYPos / 2;
            const midPoint = [-10 - circleRadius, 10];
            c.start = "M0,0"
            c.curve = " L " + (midPoint[0] + 10) + "," + (midPoint[1])
              + " Q" + midPoint[0] + "," + midPoint[1] + " "
              + (midPoint[0]) + "," + (midPoint[1] + 10);
            c.end = " L" + (midPoint[0]) + "," + ((c.bottomNode.yPos - c.yPos + extraY) - circleRadius - 12)
            return c.start + c.curve + c.end;
          }
        })

      resetHeight();

      function getCustomTree(myHierarchy: any) {
        myHierarchy.descendants().map((m: any) => m.expanded = (expandedDownChildren.indexOf(m.data.node_key) > -1 ? true : false));
        let filteredDescendants = myHierarchy.descendants().filter((f: any) => f.depth <= 1 || f.parent.expanded === true);
        let myChildren: any = filteredDescendants.filter((f: any) => f.depth === 1);
        const downRadiusDifference = mediumCircleRadius - smallCircleRadius;
        let currentY = circleRadius * 2;
        myChildren.forEach((d: any) => {
          let childFilteredDescendents = d.descendants().filter((f: any) => f.depth <= 1 || f.parent.expanded === true);
          d.totalExpandedChildren = childFilteredDescendents.length;
          d.x = acrossLinkGap + (circleRadius + mediumCircleRadius - downRadiusDifference);
          d.y = currentY
          currentY = d.y + ((childFilteredDescendents.length - 1) * (subDepthHeight));
          childFilteredDescendents.forEach((c: any) => {
            const myChildPosition = c.parent.children.findIndex((f: any) => f === c);
            let extraY = 0;
            if (myChildPosition > 0 && c.parent.children !== undefined) {
              const siblingsAbove = c.parent.children.filter((f: any) => f.childPosition !== undefined && f.childPosition < myChildPosition && f.expanded === true);
              const siblingsAboveExpandedChildren = d3.sum(siblingsAbove, (s: any) => s.children === undefined ? 0 : s.children.length);
              extraY += siblingsAboveExpandedChildren * subDepthHeight
            }
            c.totalExpandedChildren = (c.expanded !== true || c.children === undefined ? 0 : c.children.length)
            c.childPosition = myChildPosition;
            c.x = d.x + ((c.depth - 1) * subDepthWidth) + downRadiusDifference;
            c.y = subDepthHeight + (myChildPosition * subDepthHeight) + (c.parent.y !== undefined ? c.parent.y : 0) + extraY;
          })
        })
        myChildren = filteredDescendants.filter((f: any) => f.depth > 0);
        return myChildren;
      }

    }

    function wrap(text: any, wrapWidth: any, myLineHeight: number = 14) {
      text.each(function () {
        // @ts-ignore
        var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line: any = [],
          lineNumber = 0,
          lineHeight = myLineHeight, // ems
          y = 0,
          dy = 0,
          tspan: any = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "px");
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > wrapWidth) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", (++lineNumber * lineHeight) + "px").text(word);
          }
        }
      });
      return 0;
    }

    function addTotal(myVar: any, d: any) {
      if (myChart.props.patientLevelView) {
        if (patientLevelTotals[myVar] === undefined) {
          patientLevelTotals[myVar] = [];
        }
        myChart.props.patientLevelValues.forEach((p: any) => {
          if (patientLevelTotals[myVar][p] === undefined) {
            patientLevelTotals[myVar][p] = 0;
          }
          patientLevelTotals[myVar][p] += (d[p] === undefined ? 0 : d[p]);
        })
      }
      if (reasonTotals[myVar] === undefined) {
        reasonTotals[myVar] = [];
      }
      if (d.patient_reason_code !== null) {
        reasonTotals[myVar].push(d.patient_reason_code);
      }
      if (averageTotals[myVar] === undefined) {
        averageTotals[myVar] = [];
      }
      if (d.avg_days !== null) {
        averageTotals[myVar].push(d.avg_days);
      }

      // debugger

      myChart.props.dynamicDonutVariable.forEach((o:any) => {
        if(allTotals[myVar+o.keyName] == undefined) {
          allTotals[myVar+o.keyName] = 0;
        }
        allTotals[myVar+o.keyName] += d[o.keyName];
      })



      // if (topTotals[myVar] === undefined) {
      //   topTotals[myVar] = 0;
      // }
      // topTotals[myVar] += d[myChart.props.variableTop];

      // if (bottomTotals[myVar] === undefined) {
      //   bottomTotals[myVar] = 0;
      // }
      // bottomTotals[myVar] += d[myChart.props.variableBottom];
      
      
      const parsedAlerts = d.alerts;
      if (parsedAlerts !== null) {
        if (alertTotals[myVar] === undefined) {
          alertTotals[myVar] = [];
        }
        alertTotals[myVar].push(parsedAlerts);
      }
    }

    function getPieData(myName: any) {
      let pieData: any = [];
      if (myChart.props.patientLevelView) {
        myChart.props.patientLevelValues.forEach((d: any, i: any) => {
          pieData.push({
            value: patientLevelTotals[myName][d],
            label: myChart.props.patientLevelLabels[i],
            color: pieColors(d)
          })
        })
      } else {
        myChart.props.dynamicDonutVariable.forEach((d:any) => {
          const obj = {
            value: allTotals[myName + d.keyName],
            label: d.name,
            color: myChart.props.pieColors[d.name]
          }
          pieData.push(obj);
        })

        // pieData.push({
        //   value: topTotals[myName],
        //   label: myChart.props.variableTopLabel,
        //   color: myChart.props.pieColors[myChart.props.variableTopLabel]
        // })
        // pieData.push({
        //   value: bottomTotals[myName],
        //   label: myChart.props.variableBottomLabel,
        //   color: myChart.props.pieColors[myChart.props.variableBottomLabel]
        // })
      }
      return pieData;
    }

    function getAlertData(myName: any) {
      if (alertTotals[myName] === undefined) {
        return { total: 0, data: [] };
      } else {
        const myTotal = d3.sum(alertTotals[myName], (a: any) => d3.sum(a, (s: any) => s.Count));
        let allAlerts: any = [];
        alertTotals[myName].forEach((d: any) => {
          let myAllAlerts: any = [];
          d.forEach((a: any) => {
            myAllAlerts = myAllAlerts.concat(a.Data);
          })
          allAlerts = allAlerts.concat(myAllAlerts);
        })
        return { total: myTotal, data: allAlerts };
      }
    }

    function generateDots(myHeight: any, myWidth: any) {
      const dotsEvery: any = 30;
      const dotRadius = 2;
      const dotsHorizontal = parseInt(String(myWidth / dotsEvery), 10);
      const dotsVertical = parseInt(String(myHeight / dotsEvery), 10); // dates header group
      const margin = dotsEvery / 3;
      let dotData = [];
      for (let i = 0; i <= dotsHorizontal; i++) {
        for (let v = 0; v <= dotsVertical; v++) {
          dotData.push({
            x: margin + (i * dotsEvery),
            y: margin + (v * dotsEvery)
          })
        }
      }
      const dotGroup = mySvg
        .selectAll(".dotGroup")
        .selectAll(".dotGroup" + myClass)
        .data(dotData)
        .join(function (group: any): any {
          const enter = group.append('g').attr('class', 'dotGroup' + myClass);
          enter.append("circle").attr("class", "backgroundDot");
          return enter;
        });

      dotGroup.select(".backgroundDot")
        .attr("cx", (d: any) => d.x)
        .attr("cy", (d: any) => d.y)
        .attr("r", dotRadius)
        .attr("fill", "#D4D9E3");
    }
  }

// property & data for the chart
  getNetworkChartData(): void {
    this.networkChartData = []
    this.hideLinkTooltip = false
    this.hideUpdateTooltip = false
    this.loader({loader: true, data: this.networkChartData});
    const obj = {
      api_key: 100126,
      report_typ: "D",
      activity_since_days: this.filterServiceOld.sliderValue ? this.filterServiceOld.sliderValue : 1,

    }
    if(this.filterServiceOld.isEmptyObject(this.filterServiceOld.baseQuery) == false) {
      this.reqSubcription.push(this.filterServiceOld.executePatientQuery(obj).subscribe((resp: any) => {
        // this.dataService.getJson('networkchart').subscribe((resp: any) => {
        this.networkChartData = resp;
        this.dataService.chartData.next(this.networkChartData)
        this.props = {
          pieColors: this.pieColorObject,
          linkColors: { "-1": "#1363DF", "0": "#844AFF", "1": "#FFCD4A" },
          pieLabelSizeThresholds: [10, 20, 40], //remember there should always be one less in this one
          pieLabelSizeThresholdFontSize: [12, 12, 12, 12],
          
          dynamicDonutVariable: this.dynamicDonutVariable,

          variableTop: this.variableTop,
          variableBottom: this.variableBottom,
          variableTopLabel: this.variableTopLabel,
          variableBottomLabel: this.variableBottomLabel,
          
          gapWidthMultiple: this.gapWidthMultiple,
          patientLevelView: this.listStyle == 'default' || this.listStyle == undefined ? false : true,
          // patientLevelLabels: ["DAYS 1-5", "DAYS 6-10", "DAYS 11-21", "DAYS > 21"],
          patientLevelLabels: this.patientLevelLabels,
          patientLevelThresholds: [6, 11, 22],
          // patientLevelColors: ["#1363DF", "#5A92E9", "#A1C1F2", "#D0E0F9"],
          // patientLevelColors: ["#E34071", "#EB799C", "#F4B3C6", "#F9D9E3"],
          patientLevelColors: this.patientLevelColors,
          patientLevelValues: this.patientLevelValues,
          patientLevelVar: this.paitentLevelKey,

          daysLabelVisibility: false
        }
        if (this.networkChartData) {
          this.isPendingAndActive = this.networkChartData.some((item: any) =>
            Object.values(item).some((value: any) => value === "ACTIVE PENDING")
          );
          this.plotChart();
        }
        this.loader({loader: false, data: this.networkChartData});
        // })
      }, err=> {
        this.networkChartData = [];
        this.loader({loader: false, data: this.networkChartData});
      }))
    }
  }

  loader(loader:any) {
    this.emitLoader.emit(loader)
  }

  tooltipLinkHide() {
    this.hideLinkTooltip = false

  }

  handleRefreshChart(refreshChart: boolean) {
    this.refreshNetworkChart = refreshChart;
    if (this.refreshNetworkChart) {
      this.getNetworkChartData();
    }
    // call any other methods or update any other state as needed
  }


  tooltipUpdateHide(refreshChart: boolean) {
    this.hideUpdateTooltip = false;
  }

  ngOnDestroy() {
    this.networkChartData = [];
    this.dataService.chartData.next('')
    this.toolTipData = [];
    this.hideLinkTooltip = false
    this.hideUpdateTooltip = false
    this.reqSubcription.forEach((res: any) => res.unsubscribe())

  }


}

