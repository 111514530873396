  <div *ngIf="!NetworkChartTooltip">
    <div  class="container update_tooltipss overflow-auto"
      *ngIf="this.filterService.toolTipAlertData.Alert_Type == 'Status Changes'">
      <div class="row update_tooltip_header" style="cursor: move;">
        <div class="col-12 d-flex flex-row mt-2">
  
          <div class=" update_border mt-1">
            <span class="update_tooltip_header_heading ">
              {{updatesObj.length}}
            </span>
          </div>
  
          <div class="update_tooltip_header_data mt-2 ml-2">
            {{ this.filterService.toolTipAlertData.Alert_Type }}
          </div>
          <div class="ms-auto">
            <mat-icon (click)="funcTest()" [svgIcon]="cancel_x"
              style="cursor: pointer;width: 12px;height: 16px;color: #fff !important;"></mat-icon>
            <!-- <img (click)="funcTest()"  src="../../../../assets/Vector.png" /> -->
          </div>
        </div>
      </div>
  
      <hr class="update_tooltip_hr mb-2" />
  
      <div class="mt-2 update_tooltip_body mb-3">
        <div class="container-fluid overStatusChanges" [ngStyle]="{'height': updatesObj.length >= 3 ? '206px' : ''}"
          style="overflow-y: auto;">
  
          <div *ngFor="let update of updatesObj;let idx=index">
            <span style="font-size: 10px; color: #fff;">{{this.filterService.toolTipAlertData.Patient_key ||
              this.filterService.toolTipAlertData.id}}</span>
            <div class="d-flex justify-content-between flex-container">
              <div style="font-size: 10px; color: #fff;width: 170px;">
                <span style="display: block;color: #7E7E7E;font-weight: 600;">From</span>
  
                <div class="d-flex">
                  <div style="align-self: center;margin-right: 5px;">
                    <mat-icon style="color: #3AC97C;overflow: visible !important;" [svgIcon]="status_tick"></mat-icon>
                  </div>
                  <div style="line-height: 1.2;align-self: center;">
                    {{update.From}}
                  </div>
                </div>
              </div>
              <div style="font-size: 10px; color: #fff;width: 170px;">
                <span style="display: block;color: #7E7E7E;font-weight: 600;">To</span>
                <div class="d-flex">
                  <div style="align-self: center;margin-right: 5px;">
                    <mat-icon style="color: #FFCD4A;overflow: visible !important;"
                      [svgIcon]="status_pending">circle</mat-icon>
                  </div>
                  <div style="line-height: 1.2;align-self: center;">
                    {{update.To}}
                  </div>
                </div>
              </div>
              <div style="font-size: 10px; color: #fff;">
                <span style="color: #7E7E7E;font-weight: 600;">Date</span>
                <span style="white-space: nowrap;"> {{update.Date | date: 'MM/dd/YY'}} </span>
              </div>
              <div style="font-size: 10px; color: #fff;">
                <mat-icon matTooltip="Delete"
                  style="font-size: 15px !important;width: 15px;margin-top: 16px;cursor: pointer;" [svgIcon]="trash"
                  (click)="DelDialog1('300ms', '150ms', update, idx, updatesObj.length)"></mat-icon>
              </div>
  
            </div>
  
            <hr style="color: #bbb;margin: 10px 0 !important;" />
  
          </div>
        </div>
      </div>
    </div>
  
  
    <div *ngIf="this.filterService.toolTipAlertData.Alert_Type != 'Status Changes'">
      <div class="container update_tooltip overflow-hidden" style="height: auto !important;">
        <div class="row update_tooltip_header" style="background: rgba(255, 255, 255, 0.2);cursor: move;">
          <div class="col-12 d-flex flex-row">
            <span
              style="color: #fff; padding: 10px;font-size: 14px;font-weight: 600;line-height: 21px;font-family: 'Poppins';">
              {{ this.filterService.toolTipAlertData.Alert_Type}}
            </span>
            <div class="ms-auto p-2">
              <mat-icon (click)="funcTest()" [svgIcon]="cancel_x"
                style="cursor: pointer;width: 15px;height: 17px;color: #fff !important;"></mat-icon>
            </div>
          </div>
        </div>
        <div class="mt-2 update_tooltip_body mb-3">
          <div style="font-size: 12px;line-height: 16px;">
            <div class="my-1 overStatusChanges" [ngClass]="{'dynamicHeightss': updatesObj[0].length >= 5 ? true : false}">
              <div *ngFor="let a of updatesObj; let last = last;let idx = index" style="padding: 0 10px;">
                <!-- <div class="d-flex bd-highlight mt-2">
                  <div class="me-auto bd-highlight">
                    <span style="color: #858585; text-transform: capitalize" > {{"Patient ID"}} </span>
                  </div>
                  <span style="direction: rtl;color: #ffffff; width: 200px;">{{this.filterService.toolTipAlertData.Patient_key}}</span>
                </div> -->
  
                <div class="d-flex bd-highlight mt-2" *ngFor="let c of a">
                  <div class="me-auto bd-highlight">
                    <!-- <span style="color: #858585; text-transform: capitalize" > {{"Patient Id"}} </span> -->
                    <span style="color: #858585; text-transform: capitalize"> {{c.key ? c.key.replaceAll('_', ' ') : ''}}
                    </span>
                  </div>
                  <!-- <span style="direction: rtl;color: #ffffff; width: 200px;">{{this.filterService.toolTipAlertData.id}}</span> -->
                  <span style="direction: rtl;color: #ffffff; width: 200px;">{{c.value == null ? '' : c.value}}</span>
                </div>
  
                <div class="d-flex bd-highlight mt-2"
                  >
                  <div class="me-auto bd-highlight">
                    <span style="color: #858585; text-transform: capitalize"> {{"Action"}} </span>
                  </div>
  
                  <mat-icon style="font-size: 15px !important;direction: rtl;width: 15px;cursor: pointer;"
                    [svgIcon]="trash" (click)="DelDialog1('300ms', '150ms', a, idx, updatesObj.length)">
                  </mat-icon>
  
                  <!-- <span style="direction: rtl;color: #ffffff; width: 200px;">{{this.filterService.toolTipAlertData.Patient_key}}</span> -->
                </div>
  
                <!-- <div class="row" style="background: #D11044;border-radius: 10px;margin: 5px 0;"
                  *ngIf="this.filterService.toolTipAlertData.Alert_Type != 'New Prescriber' && this.filterService.toolTipAlertData.Alert_Type != 'New Patient'">
                  <div class="col-12 d-flex justify-content-center">
                    <span
                      style="color: #fff; padding: 10px;font-size: 12px;line-height: 17px;font-family: 'Poppins';font-weight: 500;">
                      <span style="cursor: pointer !important;"
                        (click)="DelDialog1('300ms', '150ms', a, idx, updatesObj.length)">
                        <mat-icon style="width: 17px;height: 17px;overflow: visible;" [svgIcon]="trash"></mat-icon>
                        <span>
                          Remove alert
                        </span>
                      </span>
                    </span>
                  </div>
                </div> -->
  
  
  
                <hr *ngIf="!last" class="update_tooltip_hr mt-1 mb-1" />
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </div>

<div class="container update_tooltip2 overflow-hidden" *ngIf="NetworkChartTooltip">
  <div class="row update_tooltip_header">
    <div class="col-12 d-flex mt-2">
      <div class="col-10 d-flex flex-row">
        <div class=" update_border mt-1">
          <span class="update_tooltip_header_heading ">
            {{updatesObj.length}}
          </span>
        </div>

        <div class="update_tooltip_header_data mt-2 ml-2">
          Status Changes
        </div>
      </div>
      <div class="col-2 text-end">
        <img src="../../../../assets/Vector.png" (click)="hideTooltip()" />
      </div>

    </div>
  </div>

  <hr class="update_tooltip_hr mb-2" />

  <div class="mt-2 update_tooltip_body mb-3">
    <div class="container-fluid overStatusChanges" [ngStyle]="{'height': updatesObj.length >= 3 ? '206px' : ''}"
      style="overflow-y: auto;">
      <div *ngFor="let update of updatesObj;let idx=index">
        <span style="font-size: 10px; color: #fff;">{{update?.id}}</span>
        <div class="d-flex justify-content-between flex-container">
          <div style="font-size: 10px; color: #fff;width: 170px;">
            <span style="display: block;color: #7E7E7E;font-weight: 600;">From</span>

            <div class="d-flex">
              <div style="align-self: center;margin-right: 5px;">
                <mat-icon style="color: #3AC97C;overflow: visible !important;" [svgIcon]="status_tick"></mat-icon>
              </div>
              <div style="line-height: 1.2;align-self: center;">
                {{update?.from}}
              </div>
            </div>
          </div>
          <div style="font-size: 10px; color: #fff;width: 170px;">
            <span style="display: block;color: #7E7E7E;font-weight: 600;">To</span>
            <div class="d-flex">
              <div style="align-self: center;margin-right: 5px;">
                <mat-icon style="color: #FFCD4A;overflow: visible !important;"
                  [svgIcon]="status_pending">circle</mat-icon>
              </div>
              <div style="line-height: 1.2;align-self: center;">
                {{update?.to}}
              </div>
            </div>
          </div>
          <div style="font-size: 10px; color: #fff;" class="d-flex flex-column">
            <span style="color: #7E7E7E;font-weight: 600;">Date</span>
            <span style="white-space: nowrap;">{{update?.date | date: 'MM/dd/YY'}}</span>
          </div>
          <div style="font-size: 10px; color: #fff;padding-left: 16px;">
            <mat-icon matTooltip="Delete"
              style="font-size: 15px !important;width: 15px;margin-top: 16px;cursor: pointer;" [svgIcon]="trash"
              (click)="DelDialog('300ms', '150ms',update,idx,updatesObj.length)"></mat-icon>
            <ng-template #DeleteDialog>
              <div class="deletePopup" style="font-family: Poppins;">
                <div class="d-flex justify-content-between align-items-center" style="background-color: #F6F8FB;    border-radius: 10px 10px 0 0;">
                    <div class="p-2">
                        <span style="font-size: 15px;font-weight: 600;">Delete Alert</span>
                    </div>
                    <div class="p-2" style="margin: 0 0 -5px 0px !important;">
                        <mat-icon mat-dialog-close class="close-icon" style="color: rgba(138, 152, 171, 1);" >close</mat-icon>
                    </div>
                </div>
                <div class="my-2" style="text-align: center;font-weight: 500; font-size: 13px;">
                    Are you sure you want to delete?
                </div>
                <div class="d-flex justify-content-around align-items-center" style="padding: 15px;">
                    <div style="margin: 5px;">
                        <button type="button" class="btn btn-outline-primary btnDelete" mat-dialog-close>Cancel</button>
                    </div>
                    <div style="margin: 5px;">
                        <button type="button" class="btn btn-danger btnDelete" mat-dialog-close (click)="updateAlertsNetwork()">Delete</button>
                    </div>
                </div>
              </div>

              <!-- <div>
                <div style="width: 100%;background-color: #E8EDF3;height: 37px;display: flex;">
                  <span style="font-size: 15px; width: inherit;padding: 10px;    font-weight: 600;">Delete Alert</span>
                  <button mat-icon-button class="close-button" [mat-dialog-close]="true" style="margin-right: 1px;">
                    <mat-icon class="close-icon"
                      style=" height: auto !important; width: auto !important; margin-top: -18px;">close</mat-icon>
                  </button>
                </div>
                <div style="height: 40px; margin: 10px auto; text-align: center;">
                  Are you sure you want to delete?
                </div>
                <div style=" margin: 0 auto; text-align: center;  padding: 10px;">
                  <button mat-raised-button style="background-color: white !important;color: #1363DF;border: 1px solid;"
                    mat-dialog-close cdkFocusInitial>Cancel</button>
                  <button mat-raised-button
                    style="background-color: white !important; color:red; border: 1px solid;margin-left: 25px;"
                    mat-dialog-close (click)="updateAlertsNetwork()">Delete</button>

                </div>
              </div> -->
            </ng-template>
          </div>

        </div>

        <hr style="color: #bbb;margin: 10px 0 !important;" />

      </div>
    </div>
  </div>
</div>


<ng-template #DeleteDialog1>
  <div class="deletePopup" style="font-family: Poppins;">
    <div class="d-flex justify-content-between align-items-center" style="background-color: #F6F8FB;border-radius: 10px 10px 0 0;">
        <div class="p-2">
            <span style="font-size: 15px;font-weight: 600;">Delete Alert</span>
        </div>
        <div class="p-2" style="margin: 0 0 -5px 0px !important;">
            <mat-icon mat-dialog-close class="close-icon" style="color: rgba(138, 152, 171, 1);" >close</mat-icon>
        </div>
    </div>
    <div class="my-2" style="text-align: center;font-weight: 500; font-size: 13px;">
        Are you sure you want to delete?
    </div>
    <div class="d-flex justify-content-around align-items-center" style="padding: 15px;">
        <div style="margin: 5px;">
            <button type="button" class="btn btn-outline-primary btnDelete" mat-dialog-close>Cancel</button>
        </div>
        <div style="margin: 5px;">
            <button type="button" class="btn btn-danger btnDelete" mat-dialog-close (click)="confirmClicked(this.update, this.idx, this.length)">Delete</button>
        </div>
    </div>
  </div>

  <!-- <div>
    <div style="width: 100%;background-color: #E8EDF3;height: 37px;display: flex;">
      <span style="font-size: 15px; width: inherit;padding: 10px;    font-weight: 600;">Delete Alert</span>
      <button mat-icon-button class="close-button" [mat-dialog-close]="true" style="margin-right: 1px;">
        <mat-icon class="close-icon"
          style=" height: auto !important; width: auto !important; margin-top: -18px;">close</mat-icon>
      </button>
    </div>
    <div style="height: 40px; margin: 10px auto; text-align: center;">
      Are you sure you want to delete?
    </div>
    <div style=" margin: 0 auto; text-align: center;  padding: 10px;">
      <button mat-raised-button style="background-color: white !important;color: #1363DF;border: 1px solid;"
        mat-dialog-close cdkFocusInitial>Cancel</button>
      <button mat-raised-button
        style="background-color: white !important; color:red; border: 1px solid;margin-left: 25px;" mat-dialog-close
        (click)="confirmClicked(this.update, this.idx, this.length);">Delete</button>

    </div>
  </div> -->
</ng-template>