import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';;
import { report } from 'process';
import { Subscription } from 'rxjs';
import { ConstantsService } from 'src/app/services/constants.service';
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';

@Component({
  selector: 'app-selection-popup',
  templateUrl: './selection-popup.component.html',
  styleUrls: ['./selection-popup.component.scss']
})
export class SelectionPopupComponent implements OnInit {
  brandKeyData:any;

  constructor(
    public filterService: FilterService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private newFilterService: NewFilterService,
    private constantsService: ConstantsService,
    private dialogRef: MatDialogRef<SelectionPopupComponent>,

  ) {
  }
  isButtonClicked=false;
  selectedBrand:any;


  ngOnInit(): void {
   this.getBrandName()
  }

  getBrandName() {
    let url = "kpi/get-brand-filter"
    this.filterService.loadData('post',url, 'client_key,module_key', '').subscribe((res:any[])=> {
      this.brandKeyData = res.length ? res : [];
    })
  }

  brandSelection(event:any) {
    if (!this.isButtonClicked) {
      this.isButtonClicked = true;
      
      if(event) this.dialogRef.close(event);
      
      // Reset the button state after a certain delay (e.g., 2 seconds)
      setTimeout(() => {
        this.isButtonClicked = false;
      }, 2000);
    }
  }
}
