import { CommonModule, CurrencyPipe } from '@angular/common'
import { Component, ElementRef, Input, OnDestroy } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import moment from 'moment'
import { debounceTime, Subscription } from 'rxjs'
import * as _ from 'lodash';

import { MatComponentsModule } from 'src/app/mat-components.module'
import { FilterService } from 'src/app/services/filter.service'
import { PopupDeleteComponent } from '../../../shared/popup-delete/popup-delete.component'
import { ActivatedRoute } from '@angular/router'
import { NavigationService } from 'src/app/services/navigation.service'
import { DashboardService } from 'src/app/services/dashboard.service'
import { DialogService } from 'src/app/services/dialog.service'

@Component({
  standalone: true,
  imports: [MatIconModule, FormsModule, MatComponentsModule, CommonModule],
  selector: 'app-status-comments',
  templateUrl: './comments.html',
  styleUrls: ['./comments.scss']
})
export class Comments implements OnDestroy {
  @Input() topic!: {
    source_key: string
    file_type_key: string
    period: string
  }
  @Input() maxMessageLength: number = 250
  @Input('newCommentType') newCommentType: any
  @Input('dateFormat') dateFormat!: string
  employee_key: any
  api_key: any = 100132
  searchValue = ''
  edit: any = false
  editIndex: any
  commentData: any
  commentsData: any
  commentIndex: any
  messages: any = []
  editedValue: string = ''
  subs: Subscription[] = []
  dashboardKey:any
  constructor (
    private elementRef: ElementRef,
    private dialog: MatDialog,
    private currency: CurrencyPipe,
    public filterService: FilterService,
    private router: ActivatedRoute,
    private navigationService: NavigationService,
    private dashboardService: DashboardService,
    private dialogService: DialogService
  ) {
    this.router.queryParams.subscribe((params: any) => {
      let decryptedParams = this.navigationService.decryptData(params);
      this.dashboardKey = decryptedParams["ds_key"];
    });
    var empkey: any = sessionStorage.getItem('employee_key')
    this.employee_key = JSON.parse(empkey)
  }

  ngOnInit () {
    const postdata: any = {
      report_typ: 'S',
      comment_typ: this.newCommentType,
      api_key: this.api_key
    }

    this.subs.push(
      this.filterService.commentsQuery(postdata).pipe(debounceTime(500)).subscribe((res: any) => {
        this.commentsData = res ? res : []
        const topicPeriod = moment(this.topic.period, this.dateFormat).toDate().toDateString()
        this.messages = _.filter(this.commentsData, comment => (
          comment.data_source_key == this.topic.source_key &&
            new Date(comment.period).toDateString() === topicPeriod &&
            comment?.file_type_key == this.topic?.file_type_key
          ))

        // find messages for refreshing grid)
        if (this.messages.length) {
          this.filterService.currentMsgOnDM = this.messages
        } else {
          this.filterService.currentMsgOnDM = this.topic
        }

        this.myFunction()
      })
    )
  }

  send (data: any) {
    console.log(data)
  }

  numbedPipe (value: any) {
    return this.currency.transform(value, '', '', '1.0-2')
  }

  editComment (message: any, event: any, i: any) {
    this.editedValue = this.commentData.comments
    this.editIndex = this.commentIndex
    this.edit = true
  }

  SaveEdit (message: any, index: any) {
    if (!this.editedValue.trim()) {
      this.editedValue = ''
      return
    }
    if (this.editedValue == '') return

    const postdata = {
      'user_comments_key': message.user_comments_key,
      'report_typ': 'U',
      'comments': this.editedValue,
    }
    this.loadCommentsData(postdata)
    this.messages[index].comments = this.editedValue
    this.messages[index].update_timestamp = moment().format('MM/DD/YYYY HH:mm')
    this.edit = false

  }

  loadCommentsData (data: any) {
    data['comment_typ'] = this.newCommentType
    data['api_key'] = this.api_key
    //  data['dashboard_key']=this.dashboardKey
     data['dashboard_key']= this.dashboardService.dashboardKey
    //  data['time_cycle']=this.filterService.report_type

    this.subs.push(
      this.filterService.commentsQuery(data).subscribe((res: any) => {
        
        if (data.report_typ == 'I') {  
          let commentsvalue = res ? res : []
          this.messages = commentsvalue
          
          this.myFunction()
          
          // Success Snackbar
          this.dialogService.customSnackBar('success', 'green', 'Message Added Successfully');
        }

        // Delete Snackbar
        if(data.report_typ == 'D') {
          this.dialogService.customSnackBar('success', 'green', 'Message Deleted Successfully');
        }

        // Update Snackbar
        if(data.report_typ == 'U') {
          this.dialogService.customSnackBar('success', 'green', 'Message Updated Successfully');
        }

        // find messages for refreshing grid)
        if (this.messages.length > 0) {
          this.filterService.currentMsgOnDM = this.messages
        } else {
          this.filterService.currentMsgOnDM = this.topic
        }
      })
    )
  }

  myFunction () {
    setTimeout(() => {
      const element: HTMLElement | null = this.elementRef.nativeElement.querySelector('#content')
      if (element) element.scrollIntoView({ block: 'end', behavior: 'smooth' })
    }, 200)
  }

  DelDialog (enterAnimationDuration: string, exitAnimationDuration: string) {
    let dialogRef = this.dialog.open(PopupDeleteComponent, {
      width: '',
      maxWidth: '90vw',
      disableClose: true,
      panelClass: 'du-custom-modalbox',
      data: 'comment'
    })

    dialogRef.afterClosed().subscribe((value: any) => {
      if (value == '1') this.deleteComment()
    })
  }

  deleteComment () {
    const postdata = {
      'report_typ': 'D',
      'user_comments_key': this.commentData.user_comments_key
    }
    this.loadCommentsData(postdata)
    this.messages.splice(this.commentIndex, 1)
  }

  editedChanges (updatedDate: any) {
    if (updatedDate == null) return false
    else {
      const start_Date: any = moment(updatedDate)
      const end_Date: any = moment(new Date())
      const totalDays: number = end_Date.diff(start_Date, 'days')
      return totalDays <= 2
    }
  }

  getCurrentDate (date: any) {
    if (date) {
      return moment(date).format('MM/DD/YYYY HH:mm')
    } else {
      return moment().format('MM/DD/YYYY HH:mm')
    }
  }

  getcomentData (message: any, i: any) {
    this.commentData = message
    this.commentIndex = i
  }

  getMSGHtml (data: any) {
    data = data.replaceAll(' ', '&nbsp;')
    data = data.replaceAll('\n', '<br>')
    return data
  }

  messageData (value: any, event: any) {
    event.preventDefault()
    event.stopPropagation()
    if (event.shiftKey && event.key === 'Enter') return
    if (event.shiftKey) return
    if (!value.trim()) {
      this.searchValue = ''
      return
    }
    if (value == '') return

    let postdata: any = {
      'report_typ': 'I',
      'comments': value,
      'data_source_key': this.topic.source_key || undefined,
      'file_type_dim_key': this.topic.file_type_key,
      'period': moment(this.topic.period, this.dateFormat).format('yyyy-MM-DD'),
    }
    this.loadCommentsData(postdata)
    this.searchValue = ''
    document.getElementById('comment')?.focus()
  }

  ngOnDestroy () {
    this.subs.forEach((res: any) => res.unsubscribe())
  }
}