import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-control-chart-card',
  templateUrl: './control-chart-card.component.html',
  styleUrls: ['./control-chart-card.component.scss']
})
export class ControlChartCardComponent implements OnInit, OnDestroy {
  static key = 100704;

  @Input("item") item: any;
  @Input("pageKey") pageKey: any;
  heading: any;
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("ControlCharDiv", { static: true }) ControlCharDiv!: ElementRef;
  config: any;
  AllcommentData: any;
  data: any={};
  isDataLoaded = false;
  reqSubcription: Subscription[] = [];
  sourceName: any;
  parentData:any
  childData:any;

  constructor(
    private filterService: FilterService,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private apiService: ApiService
  ) {

    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.getcommentsData()
        this.loadApiData();
        this.getcommentsData();
      })
    );
  }
  ngOnInit(): void {
    this.item;

    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.ControlCharDiv.nativeElement);
    this.loadlineConfig();
  }

  loadlineConfig() {
    this.heading = this.item.config["report_heading"];
    // this.config = {
    //   colours: {
    //     Biologics: "#8C23E6",
    //     Onco360: "#E223E6",
    //     Amber: "#FFCD4A",
    //     RxCrossroads: "#645DD7",
    //     TEST1: "gold",
    //     TEST2: "black",
    //     TEST3: "purple",
    //     TEST4: "orange",
    //   },
    //   period: "weekly",
    //   xAxisLabel: this.item.config["x_axis"]["api_resp_column_display_nm"],
    //   yAxisLabel: this.item.config["y_axis"]["api_resp_column_display_nm"],
    //   xAxisVar: this.item.config["x_axis"]["api_resp_column_nm"],
    //   yAxisVar: this.item.config["y_axis"]["api_resp_column_nm"],
    //   value_column: this.item.config["value_column"]["api_resp_column_nm"],
    //   secondary_value_column:
    //     this.item.config["secondary_value_column"]?.["api_resp_column_nm"] ||
    //     null,
    //   displayType: "value",
    // };
  }

  loadApiData(sourceName?: string) {
    // debugger
    let api = this.item.config["list_of_api"] || {};
    let parent_api = this.item.config["list_of_prop_api"].api_key || {};
    let child_api = this.item.config["list_of_api"].api_key || {};
    
    if(this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {
      
      // API Payload
      let obj:any = {"api_key" : parent_api }  //200213 || 100186}
      // this.item.config.list_of_api=100185;  
      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = 'D';
      query["time_cycle"] = this.filterService.report_type;
      query['report_key'] = undefined

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          this.parentData = res.length == 0 ? [] : res;
          this.getData()
        },(err:any)=>{
          this.parentData  = [];
        })
      )

      // API Payload
      let obj1:any = {"api_key" : child_api } // 200212 || 100185}
      let query1 = Object.assign({}, this.filterService.baseQuery, obj1)
      query1["report_typ"] = 'D';
      query1["time_cycle"] = this.filterService.report_type;
      query1['report_key']=undefined
      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query1).subscribe((res:any)=> {
          this.childData  = res.length == 0 ? [] : res;
          this.getData()
        },(err:any)=>{
          this.childData = [];
        })
      )
    }

    // this.reqSubcription.push(this.filterService.kpiBreakdownexecuteQuery('100186').subscribe((res: any) => {
    //     this.parentData = res == null ? [] : res;
    //     this.getData()
    //   }, (error: any) => {
    //       this.parentData  = [];
    //   })
    // )
  
    // this.reqSubcription.push(this.filterService.kpiBreakdownexecuteQuery('100185').subscribe((res: any) => {
    //     this.childData  = res == null ? [] : res;
    //     this.getData()
    //   }, (error: any) => {
    //       this.childData = [];
    //   })
    // );
  }

  getcommentsData() {
    // API Payloads
    let postdata: any = { report_typ: "S", comment_typ: (this.item.config?.comment_type?.value || 'CC'), api_key: "100132"};
    // debugger
    this.reqSubcription.push(this.filterService.commentsQuery(postdata).subscribe((res: any) => {
      this.AllcommentData = res ? res : [];
    }, err => {
      console.log("Control Chart Comment Api Error")
      this.AllcommentData = []
    }));
  }

  getData(){
     
    if (this.parentData?.length>0 && this.childData?.length>0) {
      this.parentData=this.parentData
      this.data=this.childData   
    }
     if(this.childData?.length==0){
      this.data=[]
     }
     if(this.parentData?.length==0){
      this.parentData=[]
     }

  }

  // getcommentsData() {
  //   // API Payloads
  //   let postdata: any = { report_typ: "S", comment_typ: (this.item.config?.comment_type?.value || 'CC')'CC', api_key: "100132"};
  //   // debugger
  //   this.reqSubcription.push(this.filterService.commentsQuery(postdata).subscribe((res: any) => {
  //     this.AllcommentData = res ? res : [];
  //   }, err => {
  //     console.log("Control Chart Comment Api Error")
  //     this.AllcommentData = []
  //   }));
  // }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}