import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-server-export-popup',
  templateUrl: './server-export-popup.component.html',
  styleUrls: ['./server-export-popup.component.scss']
})
export class ServerExportPopupComponent implements OnInit {
  checklist:any;
  masterSelected=false;
  checkedList:any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private passedData: any,
    private dialogRef: MatDialogRef<ServerExportPopupComponent>
  ) { 
    this.checklist = this.passedData.filter((f: any) => f.field !== "alert" && f.field !== "alert_col_value");
  }

  ngOnInit(): void {
    this.isAllSelected();
  }

  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isExportSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  isAllSelected() {
    this.masterSelected = this.checklist.every(function(item:any) {
        return item.isExportSelected == true;
      })
    this.getCheckedItemList();
  }
 
  getCheckedItemList(){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if(this.checklist[i].isExportSelected)
      this.checkedList.push(this.checklist[i]);
    }
    this.checkedList = JSON.stringify(this.checkedList);
  }

  closeInst() {
    this.dialogRef.close(undefined)
  }

  selectedExport() {
    this.dialogRef.close(this.checkedList)
  }

}
