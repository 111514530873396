import { Component, ElementRef, EventEmitter, forwardRef, HostBinding, HostListener, Injectable, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {  MapConfig, MapDataItem, StateService, albersUsa, Map, Legend, Tooltip } from '@dataunveil/ducharts'
import * as _ from 'lodash';
import { Subscription } from 'rxjs'
import { FilterService } from 'src/app/services/filter.service'

import config from './config'
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from '../../shared/charts/charts.module';
import { MatComponentsModule } from 'src/app/mat-components.module';
import Widget from '../widget';

@Injectable({
  providedIn: 'root',
})
@Component({
  standalone: true,
  imports: [CommonModule, Map,Legend,MatComponentsModule,Tooltip, FormsModule,forwardRef(() => ChartsModule) ],
  selector: 'app-territory-map',
  templateUrl: './territory-map.component.html',
  styleUrls: ['./territory-map.component.scss']
})
export class TerritoryMapComponent  extends Widget  {
  static override key = 100709;

  window:any=window
  @ViewChild("fs") fs!: ElementRef
  isActive = false
  currentVisibleData: any = []
  mapConfig!: MapConfig
  mapData!: MapDataItem
  heading: any
  @ViewChild("TerritoryDiv", { static: true }) TerritoryDiv!: ElementRef
  users: any
  iconList:any=[]
  reqSubcription: Subscription[] = []
  sourceName: any
  headerConfig:any
  noData:boolean=false 
  loader:boolean=true
  formattedData:any=[]
  constructor(protected stateService: StateService,) {
    super();
    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.loader = true
        this.loadApiData()
      })
    )
  }
 

  format (series: any, fipsByZip: any) {
    function removeLeadingZeros(str:any) {
      return str.replace(/^0+/, '');
  }
    const byEmployee: any = {}
    _.each(series, r => {
      byEmployee[r.employee_key] = {
        label: `${r.employee_first_name[0]}. ${r.employee_last_name[0]}.`,
        areas: _.union(byEmployee[r.employee_key]?.areas, fipsByZip[removeLeadingZeros(r.zip_5)]),
        data: {
          employee_key: r.employee_key,
          employee_first_name: r.employee_first_name,
          employee_last_name: r.employee_last_name,
          territory_key: r.territory_key,
        }
      }
    })
    return _.values(byEmployee)
  }
  override  ngOnInit() {
    // debugger
    this.iconList = this.item.config['icon'] ? this.item.config['icon'] : this.iconList
    this.headerConfig = this.item?.config?.['widget_header_configuration']
   this.config = config

    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height)
    })
    observer.observe(this.el.nativeElement)
    // this.subs.push(this.stateService.get(this.config.map.id, 'selected')
    //   .subscribe((state: any) => this.toggleModal(state.selected)))

  }
  compareState (a: any, b: any) {
    return (a / 1000 | 0) === (b / 1000 | 0)
  }

  // data api method

    loadApiData() {
      // this.callApis(this.apiList);
      let api = this.item.config['list_of_api'] || {}
      if (api && !this.filterService.isEmptyObject(this.filterService.baseQuery)) {
  
        let obj = { 'api_key': 100710  ||api.api_key }
        let query = Object.assign({}, this.filterService.baseQuery, obj)
        query["report_typ"] = this.filterService.report_type;
  
        this.subs.push(
          this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res: any) => {
            if (res.length == 0) { this.noData = true } else { this.noData = false }
            this.data = res.length>0?res:[]
            this.loadedData()
            return this.data
          }, (err: any) => {
            console.log('bubblemapMap Api ' + api.api_key + ' failing')
            this.loader = false
            this.noData = true
            this.data = []
          })
        )
      }
    }
  async loadedData(){ const topology = await fetch('assets/data/us-2023.counties.albers.json').then(a => a.json())
      const fipsByZip = await fetch('assets/data/zip-fips.json').then(a => a.json())
      const data = this.data //await fetch('assets/fixtures/territory-map.json').then(a => a.json())
      this.users =  this.callApis(this.apiList); //await fetch('assets/fixtures/territory-map-users.json').then(a => a.json())
  
      this.mapConfig = {
        view: {
          ...this.config.components[0].view,
          topology,
          areas: ['nation', 'states', 'counties'],
          detailsByZoom: [ {
            range: [0.8, 1.99],
            type: 'areaLabel',
            name: 'states',
            field: 'abbr',
          }, {
            range: [2, 8],
            type: 'areaLabel',
            name: 'states',
            field: 'name',
          },{
            range:[0.8, 20],
            type: 'area',
            name: 'counties',
          }, {
            range: [18, 20],
            type: 'areaLabel',
            name: 'counties',
            field: 'name',
          }],
          areasFilter: [, (a: any, b: any) => a !== b, (a: any, b: any) => a !== b && this.compareState(a.id, b.id)],
          highlight: 'counties',
        }
      }
      this.mapData = this.format(data, fipsByZip) as any
      this.loader = false
    }
    apiList = [
      { api_key: 100276 },
      { api_key: 100277 },
      { api_key: 100278 },
      { api_key: 100279 }
    ];

    callApis(apiList: any[]) {
      const apiCalls = apiList.map((api:any) => {
        // Construct the `obj` and `query` for each API call
        const obj = {'api_key': api.api_key };
        const query = Object.assign({}, this.filterService.baseQuery, obj);
        query['report_typ'] = this.filterService.report_type;
  
        // Return the HTTP POST request as a promise
        return this.httpClient
          .post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query)
          .toPromise();
      });
  
      // Use Promise.all to call the APIs concurrently
      Promise.all(apiCalls)
        .then((responses:any) => {
          let noData = false;
  // console.log(responses);
  this.formattedData = [
    {
      id: 1,
      name: "New accounts",
      count: responses[0].length,
      type: "followUp",
      data: responses[0].map((item:any, index:any) => ({
        id: index + 1,
        follow: false,
        name: "New accounts",
        username: item.hco_name,
        address:item.address,
        // address: {
        //   street: item.address.split(",")[0],
        //   suite: item.address.split(",")[1] || "",
        //   city: item.address.split(",")[2] || "",
        //   zipcode: "", // Could be parsed from address if available
        //   geo: {
        //     lat: "", // Placeholder
        //     lng: "" // Placeholder
        //   }
        // },
        phone: "", // Placeholder
        website: "", // Placeholder
        company: {
          name: "", // Placeholder
          catchPhrase: "", // Placeholder
          bs: "" // Placeholder
        }
      }))
    },
    {
      id: 2,
      name: "Highest volume",
      count: "Top "+responses[1].length,
      type: "progressBar",
      data:responses[1].map((item:any, index:any) => ({
        id: index + 1,
        follow: false,
        username: item.hco_name,
        volume: item.volume
      }))
    },
    {
      id: 3,
      name: "Most lagging patients",
      count: responses[2].length,
      type: "progressBarnumber",
      data: responses[2].map((item:any, index:any) => ({
        id: index + 1,
        follow: false,
        username: item.hco_name,
        volume: item.lagging_patients
      }))
    },
    {
      id: 4,
      name: "Longest time to conversion",
      count:responses[3].length,
      type: "progressBardays",
      data:responses[3].map((item:any, index:any) => ({
        id: index + 1,
        follow: false,
        username: item.hco_name,
        volume: item.longest_time_to_conversion
      }))
    }
  ];
 this.users=this.formattedData
  
  
          // responses.forEach((res: any) => {

          //   if (res.length === 0) {
          //     noData = true;
          //   }
          // });
  
          // this.noData = noData;
          // if (noData) {
          //   console.log('No data available for one or more API calls');
          // } else {
          //   console.log('All APIs returned data:', responses);
          // }
        })
        .catch((error) => {
          // Handle error if any of the API calls fail
          console.error('API call error:', error);
          this.noData = true; // Set noData to true if there's an error
        });
    }

    
  transform(value: number): string {
    if (value >= 1000) {
      const suffixes = ['', 'K', 'M', 'B', 'T']
      const suffixNum = Math.floor(('' + value).length / 3)
      const shortValue = parseFloat(
        (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
      )
      return shortValue + suffixes[suffixNum]
    }
    return value.toString()
  }
  override ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe())
  }


  calculatePercentage(part: number, total: any[]): number {
    // debugger;
    const maxTotal = _.maxBy(total, 'volume');
    const maxVolume = maxTotal ? maxTotal.volume : 0;
  
    if (maxVolume === 0) {
      return 0;
    }
  
    return (part / maxVolume) * 100;
  }
}