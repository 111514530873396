import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from "d3";

@Component({
  selector: 'app-small-bar',
  templateUrl: './small-bar-chart.component.html',
  styleUrls: ['./small-bar-chart.component.scss']
})
export class SmallBarComponent implements OnInit {
SmallBarChartData:any;
@ViewChild('smallBarChart') smallBarChart!: ElementRef;
@Input('data') data: any
@Input('config')  config:any = {};
@Input('isFullscreen')  isFullscreen:any = false;
  props: any;
  divId: any = "SmallBarChartDiv";
  mytooltipData: any
  initiateChart: Boolean = false
  isTooltip: Boolean = false
  noData: Boolean = false
  dataTurn: any
  myType: any | undefined

  constructor(  private currency: CurrencyPipe) { }

  ngOnInit(): void {
    this.initiateCharts();
    this.getSmallBarChartData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    
    
    if (!changes?.['isFullscreen']?.firstChange && changes?.['isFullscreen']?.currentValue != changes?.['isFullscreen']?.previousValue) {
      this.plotChart();
    }
  }
  height: any = 0
  private showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any, chartHeight: any): void {
    // debugger
    this.myType = myType
    this.dataTurn = chartWidth - myX
    this.mytooltipData = myData
    this.height = 0
    this.dataTurn = 0
    this.height = chartHeight - myY
    this.dataTurn = chartWidth - myX

    if (myType == 'groupFullName') {
      d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('bottom', 'unset')
        .style('top', (myY+95) + 'px')

      if (this.dataTurn < 250) {
        d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
          .style('right', (this.dataTurn + 20) + 'px')
          .style('left', 'unset')
      }
      else if (this.dataTurn > 250) {
        d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
          .style('left', (myX + 25) + 'px')
          .style('right', 'unset')
      }

    }
    else {
      if (this.isFullscreen == true) {
        if (this.height < 200) {
          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('bottom', (this.height) + 'px')
            .style('top', 'unset')

          if (this.mytooltipData.length == undefined) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('bottom', (this.height - 30) + 'px')
          }
          else if (this.mytooltipData.length > 0) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('bottom', (this.height - 30) + 'px')
          }
        }
        else if (this.height > 200) {

          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            // .style('top', (myY + 25) + 'px')
            .style('bottom', 'unset')

          if (this.mytooltipData.length == undefined) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('top', myY + 80 + 'px')
          }
          else if (this.mytooltipData.length > 0) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('top', myY + 80 + 'px')
          }
        }

        if (this.dataTurn < 250) {
          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('right', (this.dataTurn + 20) + 'px')
            .style('left', 'unset')
        }
        else if (this.dataTurn > 250) {

          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('left', (myX + 25) + 'px')
            .style('right', 'unset')
        }

        this.isTooltip = true
      }
      else {
        if (this.height < 200) {
          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('bottom', (this.height) + 'px')
            .style('top', 'unset')

          if (this.mytooltipData.length == undefined) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('bottom', (this.height -30) + 'px')
          } else if (this.mytooltipData.length > 0) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('bottom', (this.height -30) + 'px')
          }
        }
        else if (this.height > 200) {

          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            // .style('top', (myY + 25) + 'px')
            .style('bottom', 'unset')

          if (this.mytooltipData.length == undefined) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('top', myY + 80 + 'px')
          } else if (this.mytooltipData.length > 0) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('top', myY + 80 + 'px')
          }
        }

        if (this.dataTurn < 250) {
          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('right', (this.dataTurn + 20) + 'px')
            .style('left', 'unset')
        }
        else if (this.dataTurn > 250) {

          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('left', (myX + 25) + 'px')
            .style('right', 'unset')
        }

      }
    }
    this.isTooltip = true

  }

  private hideTooltip(myType: any): void {
    this.isTooltip = false
    // d3.select("#d3Tooltip")
    //   .style('visibility', 'hidden');
  }

  // initiateCharts renders the svg AND any non-data appended elements
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    const mySvg = d3.select('#' + myClass)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');

    mySvg.append("g").attr("class", "xAxis" + myClass);
    mySvg.append("g").attr("class", "yAxis" + myClass);
  }

  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width: any = mySvg.node().getBoundingClientRect().width;
     
    if (this.isFullscreen) {
      this.props.chartHeight = window.outerHeight - 60;
      // width = window.outerWidth - 60;
    }else{
      this.props.chartHeight=300
    }
    const height = this.props.chartHeight;
    let margins = {left: 45, right: 12, top: 40, bottom: 48};

    margins.top = !myChart.props.showLegend && myChart.props.stackVar === null ? 15 :  40;
    mySvg.attr("width", width)
      .attr("height", height);

    let yMax: any = undefined;
    let yScale: any = undefined;
    let yScaleHeight: any = undefined;

    myChart.SmallBarChartData.map((m: any) => m[myChart.props.yVar] = isNaN(m[myChart.props.yVar]) ? 0 : m[myChart.props.yVar])
    let barData: any = [];
    if (myChart.props.stackVar === null) {
      barData = d3.rollup(myChart.SmallBarChartData, (v: any) => d3.sum(v, (s: any) => s[myChart.props.yVar]), (d: any) => d[myChart.props.barVar])
      barData = Array.from(barData);
      barData = barData.map((m: any) => m = {
        name: m[0],
        value: m[1],
        start: 0,
        fill: myChart.props.barFill[myChart.props.yVar],
        borderRadius: 4
      });
      yMax = d3.max(barData, (d: any) => d.value);
      yScale = d3.scaleLinear().domain([0, yMax]).range([height - margins.top - margins.bottom, 0]);
      yScaleHeight = d3.scaleLinear().domain([0, yMax]).range([0, height - margins.top - margins.bottom]);
      barData.map((m: any) => m.height = yScaleHeight(m.value))
      barData.map((m: any) => m.borderRadius = 2);
      barData.map((m: any) => m.totalValue = m.value);
      barData.map((m: any) => m.extraY = 0);
    } else {
      let barVars: any = new Set();
      let stackVars: any = new Set()
      myChart.SmallBarChartData.forEach((d: any) => {
        barVars.add(d[myChart.props.barVar]);
        stackVars.add(d[myChart.props.stackVar]);
      })
      let stackData: any = [];
      Array.from(barVars).forEach((d: any) => {
        const myData: any = {name: d}
        Array.from(stackVars).forEach((v: any) => {
          const myValue = d3.sum(myChart.SmallBarChartData, (s: any) =>
            s[myChart.props.barVar] === d && s[myChart.props.stackVar] === v
              ? s[myChart.props.yVar] : 0);
          myData[v] = myValue;
        })
        stackData.push(myData);
      })
      stackData = d3.stack()
        .keys(Array.from(stackVars))
        .order(d3.stackOrderNone)
        .offset(d3.stackOffsetNone)
        (stackData);


      yMax = d3.max(stackData, (d: any) => d3.max(d, (m: any) => m[1]));
      yScale = d3.scaleLinear().domain([0, yMax]).range([height - margins.top - margins.bottom, 0]);
      yScaleHeight = d3.scaleLinear().domain([0, yMax]).range([0, height - margins.top - margins.bottom]);
      const extraYs: any = {};
      stackData.forEach((d: any, i: any) => {
        const myKey = d.key;
        const isLast = (i === (stackData.length - 1));
        d.map((m: any) => m.value = (m[1] - m[0]))
        d.map((m: any) => m.height = yScaleHeight(m.value));
        d.forEach((s: any, index: any) => {
          let myExtraY = 0;
          if (s.height < 2 && s.value > 0) {
            myExtraY = (2 - s.height);
            s.height = 2;
            extraYs[index] = (extraYs[index] === undefined ? myExtraY : extraYs[index] + myExtraY)
          }
          let myFill = myChart.props.barFill[myKey];
          if(myFill === undefined){
            myFill = myChart.props.stackColors[i];
            myChart.props.barFill[myKey] = myFill;
            myChart.props.barLabels[myKey] = myKey;
          }
          barData.push({
            name: s.data.name,
            start: s[0],
            value: s.value,
            height: s.height,
            fill: myFill,
            extraY: extraYs[index] === undefined ? 0 : -extraYs[index],
            totalValue: s[1],
            borderRadius: isLast ? 2 : 0,
            groupIndex: index,
            stackIndex: i,
            errorType: myKey
          })
        })
      })
    }

    const emptyLastBars = barData.filter((f: any) => f.borderRadius > 0 && f.value === 0);
    emptyLastBars.forEach((d: any) => {
      d.borderRadius = 0;
      let filledBars = barData.filter((f: any) => f.groupIndex === d.groupIndex && f.value > 0);
      filledBars = filledBars.sort((a: any, b: any) => d3.descending(a.stackIndex, b.stackIndex));
      if (filledBars.length > 0) {
        filledBars[0].borderRadius = 2;
      }
    })
    const xTicks: any = [];
    barData.forEach((d: any) => xTicks.push(d.name));

    const xScale: any = d3.scaleBand().domain(xTicks).range([0, width - margins.left - margins.right]);

    mySvg.select('.xAxis' + myClass)
      // @ts-ignore
      .call(d3.axisBottom(xScale).tickSizeOuter(0).tickValues(xTicks))
      .attr('transform', 'translate(' + margins.left + ',' + (height - margins.bottom) + ')');

    mySvg.select('.yAxis' + myClass)
      // @ts-ignore
      .call(d3.axisLeft(yScale).tickSizeOuter(0).ticks(5))
      .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

    mySvg.selectAll('.xAxis' + myClass + ' text')
      .attr('dy', 4)
      .style('font-weight', 400)
      .style('font-family', "Poppins")
      .style('fill', "#101D42")
      .style('font-size', 10)
      .text((d: any) => getXLabel(d.replace(/_/g, ' '), xScale.bandwidth()))
      .on("mouseover", (event:any, d: any) => {
        if(getXLabel(d.replace(/_/g, ' '), xScale.bandwidth()).includes("..")){
          myChart.showTooltip('groupFullName', d.replace(/_/g, ' '), event.offsetX, event.offsetY, width,height);
        }
      })
      .on("mouseout", () => {
        myChart.hideTooltip("groupFullName");
      });

    mySvg.selectAll('.yAxis' + myClass + ' text')
      .style('font-weight', 400)
      .style('font-family', "Poppins")
      .style('fill', "#8A98AB")
      .style('font-size', 8)

    mySvg.selectAll('.yAxis' + myClass + ' line')
      .attr('x1', 0)
      .attr('x2', width - margins.left - margins.right)
      .style('stroke', "#E8EAEE")
      .style('stroke-width', 1);

    mySvg.selectAll('.yAxis' + myClass + ' path')
      .style('display', "none")

    mySvg.selectAll('.xAxis' + myClass + ' path')
      .style('display', "none")

    mySvg.selectAll('.xAxis' + myClass + ' line')
      .style('display', "none")

    if(myChart.props.stackVar !== null  || myChart.props.showLegend){
      drawLegend(Object.keys(myChart.props.barFill));
    }

    const barGroup = mySvg
      .selectAll('.chartGroup' + myClass)
      .data(barData)
      .join((group: any) => {
        const enter = group.append('g').attr('class', 'chartGroup' + myClass);
        enter.append('path').attr('class', 'barRect');
        return enter;
      });

    const barWidth = xScale.bandwidth() / 4;

    barGroup.select('.barRect')
      .attr('d', (d: any) => 'M0,' + d.height + 'L0,' + d.borderRadius
        + ' Q0,0 ' + d.borderRadius + ',0 L' + (barWidth - d.borderRadius)
        + ',0 Q' + barWidth + ',0 ' + barWidth + ',' + d.borderRadius
        + ' L' + barWidth + ',' + d.height + 'Z')
      .attr('fill', (d: any) => d.fill)
      .attr('stroke-width', 0)
      .attr('transform', (d: any) => 'translate(' + (margins.left + xScale(d.name) + (xScale.bandwidth() * 0.375))
        + ',' + (margins.top + (yScale(d.totalValue) + d.extraY)) + ')')
      .on("mouseover", (event: any, d: any) => {
        let tooltipData = "";
        if (myChart.props.stackVar === null) {
          tooltipData = d;
        } else {
          tooltipData = barData.filter((f: any) => f.name === d.name);
        }
        myChart.showTooltip('bar', tooltipData, event.offsetX, event.offsetY, width,height);
      })
      .on("mouseout", () => {
        myChart.hideTooltip('bar');
      });

    function drawLegend(legendData: any) {

      const legendGroup = mySvg
        .selectAll('.legendGroup' + myClass)
        .data(legendData)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('circle').attr('class', 'legendCircle');
          enter.append('text').attr('class', 'legendLabel');
          return enter;
        });

      legendGroup.attr("transform", "translate(" + (margins.left + 36) + ",15)");

      legendGroup.select('.legendCircle')
        .attr('id', (d: any) => 'circle_legendItem' + d)
        .attr('fill', (d: any) => myChart.props.barFill[d])
        .attr('r', 4);

      legendGroup.select('.legendLabel')
        .attr('id', (d: any) => 'legendItem' + d)
        .attr('y', 5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('font-family', 'Poppins')
        .text((d: any) => myChart.props.barLabels[d].toUpperCase())

      let legendX = 0;
      mySvg.selectAll('.legendLabel').each(function (): any {
        //@ts-ignore
        const myObject = this;
        const myWidth: any = document.getElementById(myObject.id)?.getBoundingClientRect().width;
        d3.select(myObject).attr('x', legendX + 12);
        mySvg.select('#circle_' + myObject.id).attr('cx', legendX);
        legendX += (40 + myWidth)
      });

    }
    function getXLabel(myLabel: any, bandwidth: any){
      while(!checkWidth(myLabel) || myLabel === ".."){
        if(myLabel.includes("..")){
          myLabel = myLabel.substr(0, myLabel.length-2);
        }
        myLabel = myLabel.substr(0, myLabel.length - 1) + "..";
      }
      return myLabel;
      function checkWidth(myText:any){
        if((measureWidth(myText, 12) + 4) > bandwidth){
          return false
        } else {
          return true
        }
      }
    }
    function measureWidth(myText: any, myFontSize: any): any {
      const context: any = document.createElement('canvas').getContext('2d');
      context.font = myFontSize + 'px sans-serif';
      return context.measureText(myText).width;
    }
  }
  getSmallBarChartData(){
  //  debugger
      this.SmallBarChartData=this.data;
      this.props = {
        chartHeight: 300,
        barVar: 'patient_status_sub_group',
        yVar: 'no_of_patients',
        stackVar: 'patient_status', //single bar if stackVar === null
        stackColors:  [this.config[0].color,'deeppink', 'blue', 'gold'],
        barFill: {},
        barLabels: {}, // when null, colours generated from values in stackVar in data
        showLegend: true
      }
      // @ts-ignore
      if (this.SmallBarChartData !== undefined  || this.SmallBarChartData.length > 0){
        this.plotChart();
      }
      }

      numbedPipe(value: any) {
        return this.currency.transform(value, '', '', '1.0-2');
        //  return   this.numberpipe.transform(value)
      }
}
