import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import moment from 'moment';

@Component({
  selector: 'app-custom-child-group',
  templateUrl: './custom-child-group.component.html',
  styleUrls: ['./custom-child-group.component.scss']
})
export class CustomChildGroupComponent {
  
  params:any;
  fndColumns:any;
  valueFormatted:any;
  moment=moment;
  
  constructor(private currency: CurrencyPipe) { }

  agInit(params: ICellRendererParams): void {
    
    this.params = params;
    
    let listOfColumn = this.params?.['configs']?.['selected_column_parent'];
    let childGroupingName = this.params?.['childGroupingName'];
    if(!listOfColumn) listOfColumn=[];
    this.fndColumns = listOfColumn.find((res:any)=> res['api_resp_column_nm'] == childGroupingName)
    // debugger
    let valueFormats = this.params['data']?.[childGroupingName];

    let a = this.fndColumns.isPrefix || "";
    let b = this.fndColumns.issuffix || "";

    // Data Types
    if (this.fndColumns.isDataType == "date") {
      this.valueFormatted = valueFormats ? a + "" + moment(valueFormats).format("MM/DD/YYYY") + "" + b : "";
    }

    if (this.fndColumns.isDataType == "integer") {
      if(this.fndColumns.isFormats=='Currency') {
        this.valueFormatted = valueFormats ? (a == '$' ? '' : a) + "" + this.currency.transform(Number(valueFormats)) + "" + b : ''
      } else {
        this.valueFormatted = valueFormats ? a + "" + this.currency.transform(Number(params.value), "", "", "1.0-2") + "" + b : '';
      }
    }

    if (this.fndColumns.isDataType == "string") {
      if (this.fndColumns.isTextCase == "from_db") {
        this.valueFormatted = valueFormats ? a + "" + valueFormats + "" + b : "";
      } else if (this.fndColumns.isTextCase == "init") {
        this.valueFormatted = valueFormats ? a + "" + valueFormats.toLowerCase().split(" ").map((s: any) => s.charAt(0).toUpperCase() + s.substring(1)).join(" ") + " " + b: "";
      } else if (this.fndColumns.isTextCase == "cap") {
        this.valueFormatted = valueFormats ? a + "" + valueFormats.toUpperCase() + "" + b : "";
      } else {
        this.valueFormatted = valueFormats ? a + "" + valueFormats.toLowerCase() + "" + b : "";
      }
    }

    if(this.fndColumns.removeUnderScore) {
      this.valueFormatted = valueFormats ? valueFormats.replaceAll('_', ' ') : ''
    }

  }
}
