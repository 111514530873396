import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import moment from "moment";
import { FilterService } from "src/app/services/filter.service";

@Component({
    selector: 'child-cell',
    template: `
      <div class='action'>
        <mat-icon *ngIf="isNew" matTooltip="Edit" svgIcon="edit" (click)="onEditClick()" [style.color]="'#1363df'"></mat-icon>
        <mat-icon *ngIf="!isNew" matTooltip="Save/Update" svgIcon="check" (click)="onSaveClick()"></mat-icon>
        <mat-icon *ngIf="!isNew" matTooltip="Cancel" svgIcon="crossdem" (click)="onCancelClick()"></mat-icon>
      </div>
    `,
    styles: [
      `
        :host {
          display: block;
        }
        div.action {
          display: flex;
          justify-content: space-around;
        }
      `
    ]
  })
export class rowEditComponent implements ICellRendererAngularComp {
    public params: any;
    public isNew: boolean = true;
    public previousData: any;

    constructor( private filterService: FilterService) {}

    agInit(params: any): void {
        this.params = params;
    }
   
    refresh(): boolean {
        return false;
    }
    
    onEditClick() {
        // debugger
        this.params.api['isEditing']=true
        this.params.api['editIndex']= this.params.node.rowIndex;
        this.params.api.refreshCells({ columns:this.params.config || ['action_taken', 'reason_for_hold'], force: true });
        this.isNew = false;
        this.previousData = JSON.parse(JSON.stringify(this.params.node.data));
    }

    onSaveClick() {
        
        this.params.data.action_taken
        this.params.data.reason_for_with_hold
        this.params.api['isEditing']=false
        this.params.api['editIndex']= this.params.node.rowIndex;
        this.isNew = true;
        this.params.node.setData(this.params.data);
        // var empkey: any = sessionStorage.getItem("employee_key");
        // let employee_key = JSON.parse(empkey);

        let postdata: any = {
            "api_key":100290,
            "report_typ": this.params.data.comment_exist_flg=="Y" ? "U":"I",
            "action_taken": this.params.data.action_taken||null,  
            "reason_for_hold": this.params.data.reason_for_hold||null,
            // "source_key":  this.filterService.baseQuery.source_key || this.params.data.source_key || undefined,
            "extract_file_key": this.params.data.extract_file_key,
            "cycle_dt": moment( this.params.data.extract_cycle_dt).format('YYYY-MM-DD'),
            "inbound_file_key": this.params.data.inbound_file_key,
            "file_type_dim_key":this.params.data.file_typ_key,
            "inbound_source_key":this.params.data.inbound_ds_key
            // "employee_key":employee_key
          }


          this.filterService.commentsQuery(postdata).subscribe((res: any) => {
           
          })


        // this.params.node.setData(this.previousData);
        // this.params.node.setDataValue('action_taken', null);
        // this.params.node.setDataValue('reason_for_with_hold', null);
        // this.params.api.refreshCells({ columns: ['action_taken', 'reason_for_with_hold'], force: true });
    }

    public onCancelClick() {
        this.params.api['isEditing']=false
        this.isNew = true;
        this.params.node.setData(this.previousData);
    }
   
}