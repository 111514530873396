import { DataService } from 'src/app/services/data.service';
import { DebugElement, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as _ from 'lodash';
import { BehaviorSubject, observable, Observable, of, Subject } from 'rxjs';
import { ThisReceiver } from '@angular/compiler';
import moment from 'moment';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class NewFilterService {
    currentFilterWidgetConfig = new Subject<any>()

    private statusChangeData = new BehaviorSubject<any>(null);
    currentData = this.statusChangeData.asObservable();

    private key = 'SelectedStatus';
    private baseQuery = 'baseQueryPatientStatusChange';
    
    showBy = new Subject<any>();

    locationName:any

    constructor(
        private apiService: ApiService
    ) {}
        
    sendData(data: any) {
        this.statusChangeData.next(data);
    }

    getPatientStatusData(): any {
        const data:any = sessionStorage.getItem(this.key);
        return JSON.parse(data)
    }

    setPatientStatusData(data:any) {
        const serializedData:any = JSON.stringify(data);
        sessionStorage.setItem(this.key, serializedData);
    }

    setPatientStatusBaseQuery(data:any) {
        const serializedData:any = JSON.stringify(data);
        sessionStorage.setItem(this.baseQuery, serializedData);
    }
    
    getPatientStatusBaseQuery() {
        const data:any = sessionStorage.getItem(this.baseQuery);
        return JSON.parse(data);
    }


    executePatientQuery(obj:any) {
        if(sessionStorage.getItem("brandKeyForFilters")) {
            let brandKey: any = sessionStorage.getItem("brandKeyForFilters");
            let brand_key = JSON.parse(brandKey);
            obj['brand_key'] = brand_key;  
        }

        if(sessionStorage.getItem("tbl_name_suffix")) {
            let json:any = sessionStorage.getItem("tbl_name_suffix");
            json = JSON.parse(json);
            obj['tbl_name_suffix'] = json.tbl_name_suffix;  
        }
        return this.apiService.post('kpi/get-execution-details', obj)
    }
    executealertDataQuery(obj:any) {
        if(sessionStorage.getItem("brandKeyForFilters")) {
            let brandKey: any = sessionStorage.getItem("brandKeyForFilters");
            let brand_key = JSON.parse(brandKey);
            obj['brand_key'] = brand_key;  
        }

        if(sessionStorage.getItem("tbl_name_suffix")) {
            let json:any = sessionStorage.getItem("tbl_name_suffix");
            json = JSON.parse(json);
            obj['tbl_name_suffix'] = json.tbl_name_suffix;  
        }
        return this.apiService.post('kpi/user-alerts', obj)
    }
    getTransactionData(obj:any) {
        if(sessionStorage.getItem("brandKeyForFilters")) {
            let brandKey: any = sessionStorage.getItem("brandKeyForFilters");
            let brand_key = JSON.parse(brandKey);
            obj['brand_key'] = brand_key;  
        }
        
        if(sessionStorage.getItem("tbl_name_suffix")) {
            let json:any = sessionStorage.getItem("tbl_name_suffix");
            json = JSON.parse(json);
            obj['tbl_name_suffix'] = json.tbl_name_suffix;  
        }
        return this.apiService.post('kpi/get-transaction-data', obj)
    }

    // getSampleJsonData(fileName: any) {
    //     return this.apiService.getDoc(fileName)
    // }

    // setCurrentFilterWidgetConfig(config: any) {
    //     this.currentFilterWidgetConfig.next(config)
    // }
}