import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FieldType } from '@ngx-formly/core';
import { Subscription, debounceTime } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { UserActivityService } from 'src/app/services/useractivity.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'widget-select-dynamic-options',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss']
})
export class DynamicSelect extends FieldType implements OnInit, OnDestroy {
 
  constructor(
    public filterService: FilterService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private userActivityService: UserActivityService,
    private dashboardService: DashboardService,
    private httpClient: HttpClient, 
    private apiService: ApiService,
  ) {super()}

  opt: any
  //default prop setting
  valueProp = "_id";
  labelProp = "name";
  label: any;
  currentField: any;
  parentKey: any = "";
  dynamicSelect = new FormControl()
  placeholder: any
  selectedOption: any
  reqSubcription: Subscription[] = []
  oncloseCondition = false;
  addParameter:any
  showBy: any
  sourceName: any;
  fieldKey: any;
  queryFilterData:any
  resetURLData = false;
  control:any;
  cardId:any;
  dashboardKey:any;
  defaultSelection: any;
  queryParam:any;
  toolTipKey:any;
  cardName:any;
  defaultFilter:any;
  dataSourceUrl:any;
  searchByPrescriber:any;

  // Define a variable to hold the subscription
  private parentControlSubscription: Subscription | undefined;

  onFilterTextBoxChanged($event: any) {
    return ($event.target as HTMLInputElement).value
  }  
  
  tabId:any;
  serverSideSearch = false;

  ngOnInit(): void {

    // Route
    this.route.params.subscribe((p: any) => {
      this.cardName = p["cardName"];
      // Query Params
      this.route.queryParams.subscribe((params:any) => {
        let decryptedParams = this.navigationService.decryptData(params);

        this.dashboardKey = decryptedParams["ds_key"];
        this.tabId = params["tab_id"];
        
        this.searchByPrescriber = decryptedParams.prescriber || '';
        
        if(this.cardName || params?.['record']=='yes') {
          if(sessionStorage.getItem("filterData")) {
            let filter:any = sessionStorage.getItem("filterData")
            this.queryFilterData = JSON.parse(filter) || {}; 
          } else {
            this.queryFilterData = '';
          }
        } else {
          this.queryFilterData = '';
        }
      });
    });

    // show by 
    this.reqSubcription.push(
      this.filterService.showByChangeFilter.subscribe((res:any)=> {
        if(this.key == 'report_typ') {
          this.selectedOption = res;
          this.model['time_date'] = '';
          this.applyFilter();
        }
      })
    ) 
    // Reset Function
    this.reqSubcription.push(this.filterService.formReset.subscribe((res: any) => {
      
        this.queryFilterData = '';
        this.resetURLData = true;
        this.selectedOption = this.opt.options[0];
  
        // Add Parameter like source key like all the key
        if(this.addParameter) {
          this.filterService.duplicateParameter[this.addParameter] = this.selectedOption[this.addParameter]
        }
        
        this.filterService.selectedItem['single'][this.fieldKey] = this.selectedOption
        this.filterService.previousSingleSelectedItems[this.fieldKey] = this.selectedOption
        
        //For Brand Logos
        if(this.key == 'brand_key'){
          this.getBrandLogo();
        }

        if(this.fieldKey=='brand_key') {
          this.filterService.lastRefreshedDate.next(this.selectedOption)
          sessionStorage.setItem('SelectedBrandName', JSON.stringify(this.selectedOption))
          this.filterService.selectedBrandName = this.selectedOption.brand_name;
        }
  
        if(this.fieldKey=='brand_key') {
          this.filterService.lastRefreshedDate.next(this.selectedOption)
          sessionStorage.setItem('SelectedBrandName', JSON.stringify(this.selectedOption))
          this.filterService.selectedBrandName = this.selectedOption.brand_name;
        }

        if (this.currentField.parentKey == "") {
          this.field.formControl?.setValue(this.selectedOption);
        }
        this.filterService.setBadgeValue(this.key as string, 1, this.opt.options.length, this.selectedOption, 'singleSelection', this.showBy, this.labelProp)

    }))

    // Get Value from configuration
    this.opt = this.field.templateOptions || {};
    this.labelProp = this.opt.labelProp
    this.valueProp = this.opt.valueProp
    this.showBy = this.opt.showBy
    this.placeholder = this.opt.placeHolder
    this.label = this.opt.label
    this.currentField = this.field
    let ds = this.opt.dataSourceUrl
    this.dataSourceUrl = this.opt.dataSourceUrl
    this.addParameter = this.opt.addParameter
    this.fieldKey = this.key as string

    this.toolTipKey = this.opt.toolTipVisible || this.labelProp;
    this.defaultFilter = this.opt.defaultFilter;

    if(this.fieldKey=='prescriber_nm' || this.fieldKey=='hcp_dim_key') {
      this.serverSideSearch = true;
    }

    if (this.currentField?.parentKey != "") {
      (this.field.hooks as any).afterViewInit = (f: any) => {
        const parentControl = this.form.get(this.currentField.parentKey)//this.opt.parent_key);
        this.parentControlSubscription = parentControl?.valueChanges.pipe(debounceTime(500)).subscribe((val: any) => {
          let selectedOption = this.model[this.key as string];

          let key = this.key as string;
          let fndChild:any = this.filterService.globalFilterFields.find((res:any)=> key == res.parentKey)
          if(fndChild) {
            this.model[fndChild.key] = ''

            // User State
            // let ab = { [fndChild.key]: []}
            // this.userActivityService.setData({ "selectedFilters": ab })

            // delete this.filterService.previousSingleSelectedItems?.[fndChild.key]
          }
          
          if(this.fndParent()) {
            if (ds == "") {
              this.opt.options = val
              let v = val.find((d: any) => d[this.valueProp] == selectedOption[this.valueProp])
              this.field.formControl?.setValue(v)
            } else {
              this.loadApiData(this.opt.method || "POST", ds, this.opt.parameter, this.model)
            }
          }
        })
        this.opt.options = []
      }
    } else {
      this.loadApiData(this.opt.method || "POST", ds, this.opt.parameter, this.model)
    }
  }

  // Find the Object or Not
  isObjectNotArray(value: any): boolean {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  // Fine the Parent
  fndParent() {
    let data:any;
    if(this.model[this.currentField.parentKey].length) {
      data = this.model[this.currentField.parentKey].find((r:any)=> r[this.currentField.parentKey])
    } else if(this.isObjectNotArray(this.model[this.currentField.parentKey])) {
      data = this.model[this.currentField.parentKey][this.currentField.parentKey]
    } else if (this.model[this.currentField.parentKey]) {
    }
    return data
  }

  serverSideSearchFuntion() {
 
    let query:any = this.filterService.serverSideVaribles[this.fieldKey];
    query['prescriber_search'] = this.searchByPrescriber;
    
    this.reqSubcription.push(
      this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.dataSourceUrl}`, query).subscribe((data: any) => {
        if(data?.length>0) {
          
          let optionData: any = this.opt.options = data || [];

          const fndPrescriber = this.opt.options.find((f: any) => this.searchByPrescriber == f[this.fieldKey]);
          if (fndPrescriber) {
            optionData = this.opt.options.filter((f: any) => f[this.fieldKey] == this.searchByPrescriber);
          }

          const payloadData = JSON.parse(sessionStorage.getItem("payloadData") || '{}');
          if (payloadData?.prescriber_id) {
            optionData = optionData.filter((f: any) => f?.['hcp_dim_key'] === payloadData.prescriber_id);
          }

          this.opt.options = optionData;
          this.selectedOption = optionData[0];
          this.filterService.allOptionData['single'][this.fieldKey] = optionData[0]; 
        }
        this.keepConditionBasedSetValue();
      }, err => {
        this.keepConditionBasedSetValue();
      })
    )
  }

  loadApiData(method: string, url: string, key: string, data: any) {
    // debugger
    this.reqSubcription.push(this.filterService.loadData(method, url, key, data, this.dashboardService.dashboardKey, '', '', this.serverSideSearch, this.field.key).subscribe((data: any) => {
      if (data?.length > 0) {
        
        this.opt.options = data;

        // Default filter from designer
        if(this.defaultFilter) {
          let spltString = this.defaultFilter.split(',');
          let flrData = this.opt.options.find((f:any)=> spltString.find((o:any)=> o == f[this.key as string]));
          this.opt.options = [...flrData];
        }

        // All data backup to the service
        this.filterService.backupAllSingleAndMulti[this.fieldKey] = this.opt.options;

        // all sourcetype - based on the design selection
        let abcData:any=[];
        let comparedArray:any=[];
        if(this.filterService.selectedFilterValue?.['is_Selected']==true) {
          abcData = this.filterService.selectedFilterValue['all_source_type'].filter((res:any)=> 'source_type' == this.key && res.selected == true)
          if(abcData.length>0) {
            comparedArray = abcData.filter((obj1:any) => data.some((obj2:any) => obj2[this.key as string].toLowerCase() == obj1[this.key as string].toLowerCase()));
          }
        }

        // debugger

        if(this.queryFilterData instanceof Array && this.queryFilterData?.length) {
          let selectedData = this.queryFilterData.find((o:any)=> o.filterKey?.includes(this.fieldKey))
          if(selectedData) {
            const searchResult = this.opt.options.find((obj:any) => {
              for (let key in obj) {
                if (obj[key].toString().toLowerCase() == selectedData[selectedData.key].toLowerCase()) {
                  return true;
                }
              }
              return false;
            });
            this.queryFilterData = searchResult;
          }
        }

        // from url
        let queryFilter = Object.entries(this.queryFilterData).map(([key, value]) => ({ key, value }));
        let ifQuery:any = queryFilter.find((fnd:any)=> fnd.key == this.fieldKey)

         
        if(ifQuery) {
           
          let selectOption = this.opt.options.find((fnd:any)=> fnd[this.fieldKey] == ifQuery.value)
          if(selectOption) {
            if(this.cardId=='multi') {
              this.opt.options =  selectOption
              this.selectedOption = this.opt.options[0]
            } else {
              this.selectedOption = selectOption
            }
            this.filterService.allOptionData['single'][this.fieldKey] = this.opt.options[0]
          } else {
            this.selectedOption = this.userStoredData();
            this.filterService.allOptionData['single'][this.fieldKey] = this.selectedOption
          }
        } else {
          if(comparedArray.length > 0) {
            this.opt.options = comparedArray;
            this.selectedOption = comparedArray[0];
          } else {
            let selectedOption = this.userStoredData();
            this.selectedOption = selectedOption;
          }
          this.filterService.allOptionData['single'][this.fieldKey] = this.opt.options[0]
        }  


        if(this.fieldKey=='brand_key') {
          this.filterService.lastRefreshedDate.next(this.selectedOption)
          sessionStorage.setItem('SelectedBrandName', JSON.stringify(this.selectedOption))
          this.filterService.selectedBrandName = this.selectedOption.brand_name;
          sessionStorage.setItem("tbl_name_suffix_non_frm", JSON.stringify(this.selectedOption));
        }

        if(this.key == 'source_nm'){
          sessionStorage.setItem('SelectedSourceName', JSON.stringify(this.selectedOption))
        }
        
        // debugger
        // Hard Coded on Prescriber Card Filter
        if(this.key == 'prescriber_nm' && this.cardName=='Prescriber Card' && sessionStorage.getItem("navbar_prName")) {
          let fndPrescriber = this.opt.options?.find((f:any)=> this.searchByPrescriber == f[this.fieldKey]);
          if(fndPrescriber) {
            this.opt.options = this.opt.options?.filter((f:any) => f[this.fieldKey] == sessionStorage.getItem("navbar_prName"));
            this.selectedOption = this.opt.options[0];
            this.filterService.allOptionData['single'][this.fieldKey] = this.opt.options[0]; 
            this.keepConditionBasedSetValue();
          } else {
            this.serverSideSearchFuntion();
          }
        } else {
          this.keepConditionBasedSetValue();
        }
      } else {
        // when there is no data in filter
        this.selectedOption = {};
        this.opt.options=[];
        
        if(this.addParameter) {
          this.filterService.duplicateParameter[this.addParameter] = this.selectedOption[this.addParameter]
        }

        this.filterService.previousSingleSelectedItems[this.fieldKey] = this.selectedOption
        this.filterService.selectedItem['single'][this.fieldKey] = this.selectedOption

        this.field.formControl?.setValue({ [this.key as string]: 'null'})
        this.filterService.setBadgeValue(this.key as string, 1, this.opt.options.length, this.selectedOption, 'singleSelection', this.showBy,this.labelProp)
      }
      
      //For Brand Logos
      if(this.key == 'brand_key'){
        this.getBrandLogo();
      }
    }, err=> {
      // when there is no data in filter
      this.selectedOption = {};
      this.opt.options=[];

      if(this.addParameter) {
        this.filterService.duplicateParameter[this.addParameter] = this.selectedOption[this.addParameter]
      }

      this.filterService.allOptionData['single'][this.fieldKey] = this.opt.options
      this.filterService.previousSingleSelectedItems[this.fieldKey] = this.selectedOption
      this.filterService.selectedItem['single'][this.fieldKey] = this.selectedOption

      this.field.formControl?.setValue({ [this.key as string]: 'null'})
      this.filterService.setBadgeValue(this.key as string, 1, this.opt.options.length, this.selectedOption, 'singleSelection', this.showBy,this.labelProp)
    })
    )

  }

  keepConditionBasedSetValue() {
    // debugger
    
    this.filterService.findSelectedAll[this.fieldKey] = false;
    this.filterService.selectAllNullColumn[this.fieldKey] = "data";

    if(this.filterService.selectAllAsNull.length>0) {
      this.filterService.selectAllAsNull = this.filterService.selectAllAsNull.filter((f:any)=> f != this.fieldKey)
    }
    
    this.filterService.previousSingleSelectedItems[this.fieldKey] = this.selectedOption
    this.filterService.selectedItem['single'][this.fieldKey] = this.selectedOption
    
    if(this.addParameter) {
      this.filterService.duplicateParameter[this.addParameter] = this.selectedOption[this.addParameter]
    }

    this.field.formControl?.setValue(this.selectedOption);
    this.filterService.setBadgeValue(this.key as string, 1, this.opt.options.length, this.selectedOption, 'singleSelection', this.showBy,this.labelProp)

    if(this.filterService.showByInGrid) {
      this.filterService.showByUOM.next(this.selectedOption);
    }

    if(this.key=='hco_npi_key') {
      this.filterService.topNavLocation.next(this.selectedOption)
    }

    // Brand Based Timecycle
    if(this.key=='brand_key' && this.filterService.brandBasedTime) {
      this.filterService.brandBasedTimeCycle.next(this.selectedOption);
    }

    if(this.key=='report_typ') {
      let report_data = {
        opt : this.opt.options,
        select: this.selectedOption
      }
      this.filterService.showbyfilterQuery.next(report_data);
    }
    // ir_calc_default_flg
  }

  userStoredData() {
    let userStateData: any = this.userActivityService.getData()
    // Object into Array of Object
    let selectedOption:any
    
    let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
    let findData: any = updatesObj.find((o: any) => o.key == this.key as string)

    if (findData) {
      
      if (findData.value.length) {
        selectedOption = this.findObjectsById(findData.value[0][this.key as string])
      } else {
        selectedOption = this.findObjectsById(findData.value[this.key as string])
      }
    } else {
      selectedOption = this.opt.options[0] ? this.opt.options[0] : [];

       // IR SHOW BY FILTER
       if(this.key=='report_typ') {
        let fndIrShowBy = this.opt.options.find((f:any)=> f.ir_calc_default_flg == 'Y');
        if(fndIrShowBy) {
          selectedOption = fndIrShowBy;
        }
      }

    }
    return selectedOption
  }

  findObjectsById(idToFind: any) {
    let selectedOption:any
    let fnd = this.opt.options.find((o:any)=> o[this.key as string] == idToFind)
    if(fnd) {
      selectedOption = fnd
    } else {
      selectedOption = this.opt.options[0]
    }
    return selectedOption
  }

  applyOneTime = false;

  // Apply Filter
  applyFilter(ctrl?: any) {

    if(!this.applyOneTime) {
      this.applyOneTime = true;

      
      let key = this.key as string;
      let fndChild:any = this.filterService.globalFilterFields.filter((res:any)=> key == res.parentKey)
  
      if(fndChild.length) {
        fndChild.map((m:any)=> {
          this.model[m.key] = ''
  
          // User State
          let ab = { [m.key]: []}
          this.userActivityService.setData({ "selectedFilters": ab })
          delete this.filterService.previousSingleSelectedItems?.[m.key]
          
          if(m.key=='time_date') {
            this.filterService.resetForBrandBased()
          }
          
        })
      }
  
      this.navigationService.filterSingleState(key);
  
      // // KPI Break Down
      // let kpiCon:any = sessionStorage.getItem('selectedKPIcontrol')
  
      // if(kpiCon) {
      //   kpiCon = JSON.parse(kpiCon)
    
      //   let updatesObj: any = Object.entries(kpiCon).map(([key, value]) => ({ key, value }));
      //   let findKpi: any = updatesObj.find((o: any) => o.key == key)
     
      //   if(findKpi) {
      //     delete kpiCon[findKpi.key]
      //     sessionStorage.setItem('selectedKPIcontrol', JSON.stringify(kpiCon))
      //   }
      // }
  
      this.filterService.previousSingleSelectedItems[this.fieldKey] = this.selectedOption;
      this.filterService.selectedItem['single'][this.fieldKey] = this.selectedOption;
  
      this.oncloseCondition = true;
      this.filterService.brandCarry = true;
      this.filterService.previousSingleSelectedItems[this.fieldKey] = this.selectedOption;
      this.filterService.selectedItem['single'][this.fieldKey] = this.selectedOption;
      // this.filterService.findFilter[this.fieldKey] = this.selectedOption
  
      // Add Parameter like source key like all the key
      if(this.addParameter) {
        this.filterService.duplicateParameter[this.addParameter] = this.selectedOption[this.addParameter]
      }
  
      // *
      if(this.key == 'source_nm'){
        this.filterService.source_key = this.selectedOption?.source_key
        sessionStorage.setItem('SelectedSourceName', JSON.stringify(this.selectedOption))
      }
  
      // Show By Widget Selected Value;
      if(this.filterService.showByInGrid) {
        this.filterService.showByUOM.next(this.selectedOption);
      }

      this.filterService.findSelectedAll[this.valueProp] = false;
      
      // Only if Brand Key's
      if(this.key=='brand_key') {
        this.filterService.lastRefreshedDate.next(this.selectedOption);
        sessionStorage.setItem('SelectedBrandName', JSON.stringify(this.selectedOption))
  
        sessionStorage.setItem("tbl_name_suffix_non_frm", JSON.stringify(this.selectedOption));

        this.filterService.selectedBrandName = this.selectedOption.brand_name;
        
        // Resetting for Custom date range selection 
        this.filterService.resetForBrandBased();
        this.filterService.resetForBrandBasedSingle();
  
        sessionStorage.removeItem('selectedKPIcontrol')
        sessionStorage.removeItem('userStateSingleDate')
  
        // User State for session stored
        let resFil:any = sessionStorage.getItem("userState");
        let reset: any = JSON.parse(resFil)
      
         
        if (reset) {
          if (reset.selectedFilters) {
            reset.selectedFilters = {}
            reset.selectedFilters[this.fieldKey] = this.selectedOption;
            const serializedData: any = JSON.stringify(reset);
            sessionStorage.setItem("userState", serializedData);
          }
        }
  
        //For Brand Logos
        this.getBrandLogo();
      }
  
      // Brand Based Timecycle
      if(this.key=='brand_key' && this.filterService.brandBasedTime) {
        this.filterService.brandBasedTimeCycle.next(this.selectedOption);
      }
  
      let ab = { [this.key as string]: this.selectedOption }
        // User State
      this.userActivityService.setDataKpiSingle({ "selectedFilters": ab })
      this.userActivityService.setData({ "selectedFilters": ab })
  
      if(this.key=='hco_npi_key') {
        this.filterService.topNavLocation.next(this.selectedOption)
      }
      
      this.filterService.filterApplyBtn();
      
      this.field.formControl?.setValue(this.selectedOption)
      this.filterService.setBadgeValue(this.key as string, 1, this.opt.options.length, this.selectedOption, 'singleSelection',this.showBy, this.labelProp)
  
      ctrl?.close()


      setTimeout(() => {
        this.applyOneTime = false;
      }, 2000);
    }

    
  }

  // To Get Brand Logos
  getBrandLogo() {
    this.filterService.getBrandLogo(this.selectedOption);
  }


  // when on close the dropdown
  onClose() {
    if (!this.oncloseCondition) {
      if (this.filterService.previousSingleSelectedItems[this.fieldKey] != this.selectedOption[this.valueProp]) {
        this.selectedOption = this.filterService.previousSingleSelectedItems[this.fieldKey]
      }
    }
    this.oncloseCondition = false
  }

  // Destroy the subscription
  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe());
    if (this.parentControlSubscription) {
      this.parentControlSubscription.unsubscribe();
    }
  }
}