import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ITooltipAngularComp } from "ag-grid-angular";
import { FilterService } from "src/app/services/filter.service";
import moment from "moment";
import { a } from "aws-amplify";

@Component({
  selector: "app-hover-over",
  templateUrl: "./hover-over.component.html",
  styleUrls: ["./hover-over.component.scss"],
})
export class HoverOverComponent implements ITooltipAngularComp {
  today: string = moment().format("DD.MM.YY");

  public params: any;
  public valueToDisplay: any;
  status_tick = "status_tick";
  status_na = "status_na";
  status_pending = "status";
  status_circle = "status_circle";
  status_ns = "status_ns";
  api_key: any;
  dynamicParameter: any = {};
  valueFormatColors: any;
  report_type: any;
  newPosition: any;
  inputType: any;

  constructor(public filterService: FilterService) {}

  agInit(params: any): void {
    // debugger
    this.params = params;
    let valueGetter = params.value;

    this.inputType = params.value?.params.data.patient_id;

    // Conditional Formatter Colors and Value
    this.valueFormatColors = params.value?.formatValue;

    // Selected API Key
    this.api_key = parseInt(valueGetter.selectedValue.api_key);
    let component = valueGetter.params.data;
    let check = valueGetter.component.find(
      (res: any) =>
        res.popup.toLowerCase() == component.case_status.toLowerCase()
    );
    if (valueGetter.component.length > 0) {
      if (check) {
        this.report_type = check.value;
      } else {
        this.report_type = "C";
      }
    }

    // To Create Dynamic PARAMETER Based on Selection
    this.dynamicParameter = {};

    // call API
    this.loadApiData();
  }

  loadApiData() {
    let valueGetter = this.params.data;
    let pend: any = sessionStorage.getItem("pending");
    let pending = JSON.parse(pend);
    let nonPend: any = sessionStorage.getItem("non-pending");
    let nonPending = JSON.parse(nonPend);
    if (this.report_type == "P") {
      let P = pending.find(
        (res: any) => res.patient_id == valueGetter.patient_id
      );
      if (P) {
        this.valueToDisplay = P.patient_status_history;
      } else {
        this.valueToDisplay = "";
      }
    } else {
      let nP = nonPending.find(
        (res: any) => res.patient_id == valueGetter.patient_id
      );

      if (nP) {
        nP.patient_status_history.forEach((data: any) => {
          this.valueFormatColors.forEach((color: any) => {
            if (
              data.curr_status.toLowerCase() == color.col_value.toLowerCase()
            ) {
              data = Object.assign(data, color);
            }
          });
        });
        let a = nP.patient_status_history.slice(-4);
        this.valueToDisplay = a;
      } else {
        this.valueToDisplay = "";
      }
    }
  }
}
