import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Injectable, Input, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as d3 from "d3";
import { tree } from "d3";
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { NewFilterService } from 'src/app/services/new-filter.service';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-gantt-chart',
  templateUrl: './gantt-chart.component.html',
  styleUrls: ['./gantt-chart.component.scss'],
})
export class GanttChartComponent implements OnInit, OnDestroy {
  
  @Input('showGanttTooltip') showGanttTooltip: boolean = false
  @Input('showPendingTooltip') showPendingTooltip: boolean = false
  @Input('showChangeTooltip') showChangeTooltip: boolean = false
  @Input('widgets') widgets: any = ""
 
  @Output() emitLoader = new EventEmitter();

  @ViewChild('fs') fs!: ElementRef;
  @ViewChild('GanttChartContainer', { static: true }) GanttChartContainer!: ElementRef;

  ganttChartData: any;
  headerChartData: any = { dispense: [], kpi: [], program: [] };
  divId: string = "ganttChart";
  width: number = 0;
  height: number = 0;
  props: any = {};
  isActive: boolean = false;
  currentVisibleData: any = [];
  collapse: any = "collapse";
  transactionName: any;
  clientKey: any;
  moduleKey: any;
  dashboardKey: any;
  patientId: any;
  transactionData: any;
  refillData: any;
  radarChartData: any;
  pendingInformation: any = [];
  showPointerTooltip: boolean = false;
  pointerTooltipData: any;
  reqSubcription: Subscription[] = [];
  currentLegendSelections: any = [];
  selctedLegendData: any = [];
  cardName: any;
  cardId: any
  totalLegendData: any = []
  unique: any = []
  legendID: any;
  showCircleFlags: any = false
  monthsVisible: any = 6;
  
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      this.isFullscreen = false
    }
  }
  
  @HostBinding('class.is-fullscreen') isFullscreen = false;


  closeFullscreen(): void {
    this.isFullscreen = false;
    this.isActive = false;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    setTimeout(() => {
      // this.initiateCharts()
      // this.plotChart()
    }, 100);
  }

  loader(loader:any) {
    this.emitLoader.emit(loader)
  }

  constructor(
    private dataService: DataService, 
    private filterService: NewFilterService, 
    private router: ActivatedRoute,
    private navigationService: NavigationService
  ) {
    this.totalLegendData = []
    this.selctedLegendData = []
    this.currentLegendSelections = []
  }

  // Tooltip Show

  private showTooltip(myType: any, myData: any, myX: any, myY: any, svgWidth: any): void {
    let difference = 0
    this.pointerTooltipData = ''
    this.transactionData = ""
    this.transactionName = ""
    difference = svgWidth - myX
    this.transactionName = myData?.transactiontype
    this.legendID = myData?.legendId
    if (this.isFullscreen == true) {
      if (this.transactionName && this.legendID != 'change') {
        if (myData.transactiontype != undefined || myData.transactiontype != null || myData.transactiontype != '' || myData.transactiontype != 'unknown') {

          this.getTransactionPopupDetails(100108, myData.transactiontype, myData.transactionid)

          if (this.headerChartData.kpi.length > 0) {

            if (difference > 300) {
              d3.select("#d3Tooltip")
                .style('visibility', 'visible')
                .style('position', 'absolute')
                .style('top', (myY - 300) + 'px')
                .style('left', (myX + 10) + 'px')
                .style('right', 'unset')
                .style('z-index', '99999')

            }
            else if (difference < 300) {
              d3.select("#d3Tooltip")
                .style('visibility', 'visible')
                .style('position', 'absolute')
                .style('top', (myY - 300) + 'px')
                .style('right', difference + 'px')
                .style('left', 'unset')
                .style('z-index', '99999')
            }
          }
          else {
            if (difference > 300) {
              d3.select("#d3Tooltip")
                .style('visibility', 'visible')
                .style('position', 'absolute')
                .style('top', (myY - 150) + 'px')
                .style('left', (myX + 10) + 'px')
                .style('right', 'unset')
                .style('z-index', '99999')

            }
            else if (difference < 300) {
              d3.select("#d3Tooltip")
                .style('visibility', 'visible')
                .style('position', 'absolute')
                .style('top', (myY - 150) + 'px')
                .style('right', difference + 'px')
                .style('left', 'unset')
                .style('z-index', '99999')
            }

          }
          document.getElementById('kpi_gantt_popup')?.setAttribute('style', 'visibility:hidden')
          document.getElementsByClassName('cdk-overlay-pane custom-tooltip')[0]?.setAttribute('style', 'visibility:hidden')
          this.showGanttTooltip = true
          this.showPendingTooltip = false
          this.showPointerTooltip = false
          this.showChangeTooltip = false
        }
      }
      else if (this.transactionName && this.legendID == 'change') {
        if (myData.transactiontype != undefined || myData.transactiontype != null || myData.transactiontype != '' || myData.transactiontype != 'unknown') {

          this.getTransactionPopupDetails(100108, myData.transactiontype, myData.transactionid)

          if (this.headerChartData.kpi.length > 0) {

            if (difference > 300) {
              d3.select("#d3Tooltip")
                .style('visibility', 'visible')
                .style('position', 'absolute')
                .style('top', (myY - 300) + 'px')
                .style('left', (myX + 10) + 'px')
                .style('right', 'unset')
                .style('z-index', '99999')

            }
            else if (difference < 300) {
              d3.select("#d3Tooltip")
                .style('visibility', 'visible')
                .style('position', 'absolute')
                .style('top', (myY - 300) + 'px')
                .style('right', difference + 'px')
                .style('left', 'unset')
                .style('z-index', '99999')
            }
          }
          else {
            if (difference > 300) {
              d3.select("#d3Tooltip")
                .style('visibility', 'visible')
                .style('position', 'absolute')
                .style('top', (myY - 150) + 'px')
                .style('left', (myX + 10) + 'px')
                .style('right', 'unset')
                .style('z-index', '99999')

            }
            else if (difference < 300) {
              d3.select("#d3Tooltip")
                .style('visibility', 'visible')
                .style('position', 'absolute')
                .style('top', (myY - 150) + 'px')
                .style('right', difference + 'px')
                .style('left', 'unset')
                .style('z-index', '99999')
            }

          }
          document.getElementById('kpi_gantt_popup')?.setAttribute('style', 'visibility:hidden')
          document.getElementsByClassName('cdk-overlay-pane custom-tooltip')[0]?.setAttribute('style', 'visibility:hidden')
          this.showGanttTooltip = false
          this.showPendingTooltip = false
          this.showPointerTooltip = false
          this.showChangeTooltip = true

        }

      }
      else if (myData?.data?.children != undefined) {
        document.getElementById('kpi_gantt_popup')?.setAttribute('style', 'visibility:hidden')
        document.getElementsByClassName('cdk-overlay-pane custom-tooltip')[0]?.setAttribute('style', 'visibility:hidden')
        this.pendingInformation = [{
          heading: myData?.data.name + ' - Information',
          startDate: myData?.data?.startDate,
          endDate: myData?.data?.endDate,
          duration: myData?.data?.duration,
          codes: myData.statuscodes
        }]
        this.showPendingTooltip = true
        this.showGanttTooltip = false
        this.showPointerTooltip = false
        this.showChangeTooltip = false

        if (difference > 300) {
          d3.select("#d3Tooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('top', (myY) + 'px')
            .style('left', (myX + 10) + 'px')
            .style('right', 'unset')
            .style('z-index', '99999')

        } else if (difference < 300) {
          d3.select("#d3Tooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('top', (myY) + 'px')
            .style('right', (difference) + 'px')
            .style('left', 'unset')
            .style('z-index', '99999')
        }
      }
      else if (myData?.data?.children == undefined) {
        if (myData.data) {
          if (myData?.data?.transactiontype != undefined || myData?.data?.transactiontype != null || myData?.data?.transactiontype != '' || myData?.data?.transactiontype != 'unknown') {
            this.transactionName = myData.data.name
            this.getTransactionPopupDetails(100108, myData.data.transactiontype, myData.data.transactionid)

            if (difference > 300) {
              d3.select("#d3Tooltip")
                .style('visibility', 'visible')
                .style('position', 'absolute')
                .style('top', (myY - 300) + 'px')
                .style('left', (myX + 10) + 'px')
                .style('right', 'unset')
                .style('z-index', '99999')

            }
            else if (difference < 300) {
              d3.select("#d3Tooltip")
                .style('visibility', 'visible')
                .style('position', 'absolute')
                .style('top', (myY - 300) + 'px')
                .style('right', difference + 'px')
                .style('left', 'unset')
                .style('z-index', '99999')
            }
            this.showGanttTooltip = true
            this.showPendingTooltip = false
            this.showPointerTooltip = false
            this.showChangeTooltip = false


          }
        }

        else {
          d3.select("#d3Tooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('top', (myY + 20) + 'px')
            .style('left', (myX + 10) + 'px')
            .style('z-index', '99999')

          this.pointerTooltipData = myData
          this.showPointerTooltip = true
          this.showGanttTooltip = false
          this.showPendingTooltip = false


        }
      }
    }

    else {
      if (this.transactionName && this.legendID != 'change') {
        if (myData.transactiontype != undefined || myData.transactiontype != null || myData.transactiontype != '' || myData.transactiontype != 'unknown') {
          this.getTransactionPopupDetails(100108, myData.transactiontype, myData.transactionid)
          if (difference > 300) {
            d3.select("#d3Tooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', (myY - 300) + 'px')
              .style('left', (myX + 10) + 'px')
              .style('right', 'unset')
              .style('z-index', '99999')
          }
          else if (difference < 300) {
            d3.select("#d3Tooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', (myY - 300) + 'px')
              .style('right', difference + 'px')
              .style('left', 'unset')
              .style('z-index', '99999')
          }
          document.getElementById('kpi_gantt_popup')?.setAttribute('style', 'visibility:hidden')
          document.getElementsByClassName('cdk-overlay-pane custom-tooltip')[0]?.setAttribute('style', 'visibility:hidden')
          this.showGanttTooltip = true
          this.showPendingTooltip = false
          this.showPointerTooltip = false
          this.showChangeTooltip = false
        }
      }
      else if (this.transactionName && this.legendID == 'change') {
        if (myData.transactiontype != undefined || myData.transactiontype != null || myData.transactiontype != '' || myData.transactiontype != 'unknown') {
          this.getTransactionPopupDetails(100108, myData.transactiontype, myData.transactionid)
          if (difference > 300) {
            d3.select("#d3Tooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', (myY - 300) + 'px')
              .style('left', (myX + 10) + 'px')
              .style('right', 'unset')
              .style('z-index', '99999')
          }
          else if (difference < 300) {
            d3.select("#d3Tooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', (myY - 300) + 'px')
              .style('right', difference + 'px')
              .style('left', 'unset')
              .style('z-index', '99999')
          }
          document.getElementById('kpi_gantt_popup')?.setAttribute('style', 'visibility:hidden')
          document.getElementsByClassName('cdk-overlay-pane custom-tooltip')[0]?.setAttribute('style', 'visibility:hidden')
          this.showGanttTooltip = false
          this.showPendingTooltip = false
          this.showPointerTooltip = false
          this.showChangeTooltip = true


        }

      }
      else if (myData?.data?.children != undefined) {
        document.getElementById('kpi_gantt_popup')?.setAttribute('style', 'visibility:hidden')
        document.getElementsByClassName('cdk-overlay-pane custom-tooltip')[0]?.setAttribute('style', 'visibility:hidden')
        this.pendingInformation = [{
          heading: myData?.data.name + ' - Information',
          startDate: myData?.data?.startDate,
          endDate: myData?.data?.endDate,
          duration: myData?.data?.duration,
          codes: myData.statuscodes
        }]
        this.showPendingTooltip = true
        this.showGanttTooltip = false
        this.showPointerTooltip = false
        this.showChangeTooltip = false

        if (difference > 300) {
          d3.select("#d3Tooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('top', (myY - 30) + 'px')
            .style('left', (myX + 10) + 'px')
            .style('right', 'unset')
            .style('z-index', '99999')

        } else if (difference < 300) {
          d3.select("#d3Tooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('top', (myY - 30) + 'px')
            .style('right', (difference) + 'px')
            .style('left', 'unset')
            .style('z-index', '99999')
        }
      }
      else if (myData?.data?.children == undefined) {
        if (myData.data) {
          if (myData?.data?.transactiontype != undefined || myData?.data?.transactiontype != null || myData?.data?.transactiontype != '' || myData?.data?.transactiontype != 'unknown') {
            this.transactionName = myData.data.name
            this.getTransactionPopupDetails(100108, myData.data.transactiontype, myData.data.transactionid)
            if (difference > 300) {
              d3.select("#d3Tooltip")
                .style('visibility', 'visible')
                .style('position', 'absolute')
                .style('top', (myY - 300) + 'px')
                .style('left', (myX + 10) + 'px')
                .style('right', 'unset')
                .style('z-index', '99999')
            }
            else if (difference < 300) {
              d3.select("#d3Tooltip")
                .style('visibility', 'visible')
                .style('position', 'absolute')
                .style('top', (myY - 300) + 'px')
                .style('right', difference + 'px')
                .style('left', 'unset')
                .style('z-index', '99999')
            }
            this.showGanttTooltip = true
            this.showPendingTooltip = false
            this.showPointerTooltip = false
            this.showChangeTooltip = false

          }
        }

        else {
          d3.select("#d3Tooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('top', (myY - 30) + 'px')
            .style('left', (myX + 10) + 'px')
            .style('z-index', '99999')

          this.pointerTooltipData = myData
          this.showPointerTooltip = true
          this.showGanttTooltip = false
          this.showPendingTooltip = false
          this.showChangeTooltip = false


        }
      }
    }
  }

  // Tooltip Hide

  toolTipHide() {
    this.showGanttTooltip = false
    this.showChangeTooltip = false
  }
  tooltipPendingHide() {
    this.showPendingTooltip = false
  }

  hideTooltip(myType?: any): void {
    this.showPointerTooltip = false
  }

  //--- Angular LifeCycle ----//


  ngOnInit(): void {
    this.showCircleFlags = this.widgets?.config?.circle_flags?.controls
    this.monthsVisible = this.widgets?.config?.months_visible
    this.totalLegendData = []
    this.selctedLegendData = []
    this.currentLegendSelections = []
    this.dataService.radarData.next('');


    this.router.params.subscribe((p: any) => {
      this.cardName = p['cardName'] || '';

      this.router.queryParams.subscribe((params: any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = decryptedParams["cl_key"];
        this.moduleKey = decryptedParams["md_key"];
        this.dashboardKey = decryptedParams["ds_key"];
        this.patientId = decryptedParams["cardId"];
        this.cardId = decryptedParams["cardId"];
      });
    });

    this.reqSubcription.push(this.dataService.expandCollapseChart.subscribe((resp: boolean) => {
      if (this.currentLegendSelections.length == 0) {
        this.selctedLegendData = []
      } else if (this.currentLegendSelections.length == this.selctedLegendData.length) {
        this.selctedLegendData = []
      } else {
        this.selctedLegendData = this.totalLegendData
        this.currentLegendSelections.forEach((j: any) => {
          this.selctedLegendData = this.selctedLegendData.filter((i: any) => i == j)
        })
      }
      if (resp == true) {
        this.collapse = "expand"
        this.showGanttTooltip = false;
        this.showPendingTooltip = false;
        this.showPointerTooltip = false;
      } else {
        this.collapse = "collapse"
        this.showGanttTooltip = false;
        this.showPendingTooltip = false;
        this.showPointerTooltip = false;
      }

      this.props.expandCollapseAll = this.collapse;
      setTimeout(() => {
        this.plotChart()
      }, 100);
    }))


    // API Functions Called At Time of Loading
    this.initiateCharts();
    
    this.getDispenseData();
    this.getProgramsData();
    this.getkpiData();
    this.getRefillData();
    setTimeout(() => {
      this.getGanttChartData();
    }, 200);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    setTimeout(() => {
      this.plotChart()
    }, 200);
  }

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    document.addEventListener('fullscreenchange', (event) => {
      if (this.currentLegendSelections.length == 0) {
        this.selctedLegendData = [];
      } else if (this.currentLegendSelections.length == this.selctedLegendData.length) {
        this.selctedLegendData = []
      } else {
        this.selctedLegendData = this.totalLegendData
        this.currentLegendSelections.forEach((j: any) => {
          this.selctedLegendData = this.selctedLegendData.filter((i: any) => i == j)
        })
      }


      if (document.fullscreenElement) {
        document.getElementsByClassName('cdk-overlay-pane custom-tooltip')[0]?.setAttribute('style', 'visibility:hidden')
        document.getElementById('completeGanttChart')?.setAttribute('style', 'height:100vh;overflow-y:auto;overflow-x:hidden;background:#ffff')
        this.showGanttTooltip = false;
        this.showPendingTooltip = false;
        this.showPointerTooltip = false;
        this.isFullscreen = true
      } else {
        this.showGanttTooltip = false;
        this.showPendingTooltip = false;
        this.showPointerTooltip = false;
        this.isFullscreen = false
       
        document.getElementsByClassName('cdk-overlay-pane custom-tooltip')[0]?.setAttribute('style', 'visibility:hidden')
        document.getElementById('completeGanttChart')?.setAttribute('style', 'height:auto;overflow-y:unset;background:#ffff;overflow-x:unset')
      }

      setTimeout(() => {
        this.plotChart()
      }, 100);
    })
  }

  // ---- Chart Configuration ----//
  // chart svg  plotChart rendering 
  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width = mySvg.node().getBoundingClientRect().width;
    const margins = {left: 20, leftExtra: 200, right: 20, top: 210, bottom: 20, mid: 20, brush: 30, headerHeight: 120, headerLeft: 220}
    const fontSizes: any = {1: 14, 2: 12, above: 10};
    const rowHeight = 28;
    const timeRectHeight = 20;
    const kpiHeight = 30;
    let dayWidth = 12;
    const changeIconPath = "M7.5 9.43711C8.56067 8.66434 9.25 7.41257 9.25 5.9998C9.25 3.65259 7.34721 1.7498 5 1.7498H4.75M5 10.2498C2.65279 10.2498 0.75 8.34701 0.75 5.9998C0.75 4.58704 1.43933 3.33527 2.5 2.5625M4.5 11.1998L5.5 10.1998L4.5 9.1998M5.5 2.7998L4.5 1.7998L5.5 0.799805";
    const changeIconViewBox = "0 0 10 12";
    //this is a quick fix as Sitaram did mention he might change the date format
    const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    const dateFormat = d3.timeFormat('%d %b %Y')
    let programData: any = myChart.headerChartData.program;

    programData.map((m: any) => m[myChart.props.startDateVar] = new Date(m[myChart.props.startDateVar]));
    programData.map((m: any) => m[myChart.props.endDateVar] = new Date(m[myChart.props.endDateVar]));
    programData.map((m: any) => m.includeEnd = programData.find((f: any) => String(f[myChart.props.startDateVar]) === String(m[myChart.props.endDateVar])) === undefined);
    const totalProgramDays = programData.length === 0 ? 0 : d3.sum(programData, (d: any) => d.days);
    
    let programSet: any = new Set();
    programData.forEach((d: any) => programSet.add(d[myChart.props.programVar]))
    programSet = Array.from(programSet);
    const programColourScale = d3.scaleOrdinal().domain(programSet).range(myChart.props.programColors);

    let dispenseData: any = myChart.headerChartData.dispense;
    dispenseData.map((m: any) => m[myChart.props.dispenseTransactionDateVar] = new Date(m[myChart.props.dispenseTransactionDateVar]));

    let kpiData: any = myChart.headerChartData.kpi;

    // debugger

    kpiData.map((m: any) => m[myChart.props.startDateVar] = new Date(m[myChart.props.startDateVar]));
    kpiData.map((m: any) => m[myChart.props.endDateVar] = new Date(m[myChart.props.endDateVar]));
    kpiData.map((m: any) => m.dayCount = d3.timeDay.count(m[myChart.props.startDateVar], m[myChart.props.endDateVar]));

    kpiData = kpiData.sort((a: any, b: any) => d3.ascending(a[myChart.props.startDateVar], b[myChart.props.startDateVar]));

    let kpiGroups: any = [];
    kpiData.forEach((d: any, i: any) => {
      if(i === 0){
        kpiGroups.push({
          dateRange: [d[myChart.props.startDateVar], d[myChart.props.endDateVar]],
          data: [d]
        })
      } else {
        const dateOverlap = kpiGroups.find((f: any) => d[myChart.props.startDateVar] < f.dateRange[1] && d[myChart.props.endDateVar] > f.dateRange[0])
        if(dateOverlap === undefined){
          if(kpiGroups[i-1]) {
            kpiGroups[i-1].data.push(d);
            kpiGroups[i-1].dateRange[0] = Math.min(d[myChart.props.startDateVar],kpiGroups[i-1].dateRange[0]);
            kpiGroups[i-1].dateRange[1] = Math.max(d[myChart.props.endDateVar],kpiGroups[i-1].dateRange[1]);
          }
        } else {
          kpiGroups.push({
            dateRange: [d[myChart.props.startDateVar], d[myChart.props.endDateVar]],
            data: [d]
          })
        }
      }
    })
    const ext = d3.extent(programData,(d: any) => d[myChart.props.startDateVar])
    const programExtent = programData.length === 0 ? [new Date(),new Date()] : programData.length === 1 ? [programData[0][myChart.props.startDateVar],programData[0][myChart.props.endDateVar]] : ext;
    const dispenseExtent = dispenseData.length === 0 ? [new Date(),new Date()] : d3.extent(dispenseData,(d: any) => d[myChart.props.dispenseTransactionDateVar]);
    const kpiExtent = kpiData.length === 0 ? [new Date(),new Date()] : d3.extent(kpiData,(d: any) => d[myChart.props.startDateVar]);

    //@ts-ignore
    let headerXMin: any = Math.min(programExtent[0], dispenseExtent[0], kpiExtent[0]);
    //@ts-ignore
    let headerXMax: any = Math.max(programExtent[1], dispenseExtent[1], kpiExtent[1]);
    const xScaleHeader = d3.scaleTime().range([0, width - margins.headerLeft  - margins.right - 50]).domain([headerXMin, headerXMax]);

    margins.top = margins.headerHeight + (kpiGroups.length * kpiHeight) + 20

    myChart.ganttChartData.map((m: any) => m.TransactionDate = new Date(m[myChart.props.transactionDateVar]));
    myChart.ganttChartData.map((m: any) => m.endDate = new Date(m[myChart.props.transactionEndDateVar]));
    let {chartData, flagData} = formatData();

    let dateMin: any = d3.min(chartData.descendants(), (d: any) => d.data.startDate);
    let dateMax: any = d3.max(chartData.descendants(), (d: any) => d.data.endDate);


    if(dateMax < new Date()){
      dateMax = new Date();
    }
    let dateExtent: any = [dateMin, dateMax];
    dateExtent[1] = d3.timeWeek.offset(dateMax, 1);

    let flagSet: any = new Set();
    flagData.forEach((d: any) => flagSet.add(d[myChart.props.transactionTypeVar]));
    dispenseData.forEach((d: any) => flagSet.add(d.type));
    flagSet = Array.from(flagSet);
    if(flagSet.find((f: any) => f.toLowerCase().includes("change"))  !== undefined){
      flagSet = flagSet.filter((f: any) => f.toLowerCase().includes("change") === false);
      flagSet.push("change");
    }
    const flagColours = myChart.props.colors;
    if(myChart.props.newLegendSelections !== null){
      myChart.props.newLegendSelections.forEach((d: any) => {
        myChart.currentLegendSelections.push(d.toLowerCase());
      });
    } else {
      flagSet.forEach((m: any) => myChart.currentLegendSelections.push(m.replace(/ /g, '').toLowerCase()))
    }
     const brush: any = d3.brushX()
      .handleSize(10)
      .extent([[0, 0], [width - margins.left - margins.right - margins.leftExtra , margins.brush]])
      .on('start brush end', brushed);

    const xScaleBrush = d3.scaleTime().range([0, width - margins.left - margins.right - margins.leftExtra]).domain(dateExtent);
    const xScaleAll = d3.scaleTime().range([0, width - margins.left  - margins.right - margins.leftExtra]).domain(dateExtent);
    const xScaleSelected: any = d3.scaleTime().range([0, width - margins.left - margins.right - margins.leftExtra]).domain(dateExtent);
    let chartDates: any = new Set();
    chartData.descendants().forEach((d: any) => {
      if(d.data.TransactionDate !== 'NULL'){
        chartDates.add(d.data.TransactionDate)
      }})
    chartDates = Array.from(chartDates);

    chartData.descendants().map((m: any) => m.dateExtent = [m.data.startDate, m.data.endDate]);

    const startingDepth = myChart.props.expandCollapseAll === null  || myChart.props.expandCollapseAll === 'collapse' ? 0 : 7;
    chartData.descendants().forEach((d: any) => {
      if(d.depth > startingDepth){
        d._children = d.children;
        d.children = null;
      } else{
        if(d.children !== undefined){
          d._children = null;
        }
      }
      }
    )

    // let brushStartX = 0;
    // let brushEndX = width - margins.left - margins.right - margins.leftExtra;
    // const finalDate: any = d3.max(chartData.descendants(), (d: any) => d.data.endDate);
    // const brushStartDate = d3.timeMonth.offset(finalDate, -(myChart.props.monthsVisibleStart==0 ? 1 : myChart.props.monthsVisibleStart));
    
    // // debugger

    // if(brushStartDate > dateMin){
    //   brushStartX = xScaleAll(brushStartDate);
    //   brushEndX = xScaleAll(finalDate);
    // } else if (d3.timeMonth.count(finalDate, new Date())){
    //   brushEndX = xScaleAll(finalDate);
    // }

    let brushStartX = 0;
    let brushEndX = width - margins.left - margins.right - margins.leftExtra;

    // Set finalDate to the maximum date from data or the current date, whichever is earlier
    const chartEndDate = d3.max(chartData.descendants(), (d: any) => d.data.endDate) as Date | undefined;
    const finalDate: Date = d3.min(
      [chartEndDate, new Date()].filter((d): d is Date => d instanceof Date) // Filter to ensure only valid Dates
    )!;

    // Calculate the brush start date
    const brushStartDate = d3.timeMonth.offset(
      finalDate,
      -(myChart.props.monthsVisibleStart == 0 ? 1 : myChart.props.monthsVisibleStart)
    );

    if (brushStartDate > dateMin) {
      // Align brush start and end within the valid range
      brushStartX = xScaleAll(brushStartDate);
      brushEndX = xScaleAll(finalDate);
    } else {
      // Ensure brushEndX always represents the current date
      brushEndX = xScaleAll(new Date());
    }

    let chartHeight =  chartData.descendants().length * rowHeight;

    d3.select('.headerTitle' + myClass)
      .attr('fill', '#101D42')
      .attr('x', margins.left)
      .attr('y', (margins.headerHeight/2) - 2)
      .attr('font-size', 14)
      .attr('font-family','Poppins')
      .attr('font-weight',500)
      .text('Days on therapy');

    d3.select('.headerTitleValue' + myClass)
      .attr('fill', '#101D42')
      .attr('x', margins.left)
      .attr('y', (margins.headerHeight/2) + 14)
      .attr('font-size', 14)
      .attr('font-family','Poppins')
      .attr('font-weight',600)
      .text(totalProgramDays + ' days');

    d3.select('.headerBackgroundRect' + myClass)
      .attr('fill', '#E8EAEE')
      .attr('height', 8)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('y', (margins.headerHeight/2) - 4)
      .attr('x', margins.headerLeft)
      .attr('width', width - margins.headerLeft - margins.right);

    d3.select('.overlay')
      .style('fill', 'transparent');

    d3.select('.brushBackgroundRect' + myClass)
      .attr('fill', '#F6F8FB')
      .attr('width', width - margins.left - margins.right - margins.leftExtra)
      .style('height', margins.brush);

    d3.select('.chartBackgroundRect' + myClass)
      .attr('fill', '#F6F8FB');

    d3.select('#brushGroup' + myClass)
      .call(brush)
      .call(brush.move, [brushStartX, brushEndX])
      .attr('transform', 'translate(' + (margins.left + margins.leftExtra) + ',' + (margins.top + chartHeight + margins.mid) + ')');

    d3.selectAll('#brushGroup' + myClass)
      .selectAll('.selection')
      .attr('fill', '#1363DF')
      .attr('fill-opacity', '0.05')
      .style('stroke', '#1363DF')
      .style('stroke-width', '1');

    const brushChartGroup = mySvg
      .select('#brushChartGroup' + myClass)
      .selectAll('.brushChartItems' + myClass)
      .data(chartData.children)
      .join(function(group: any): any {
        const enter = group.append('g').attr('class', 'brushChartItems' + myClass);
        enter.append('rect').attr('class', 'brushTimeRect');
        return enter;
      });

    brushChartGroup.select(".brushTimeRect")
      .attr('x', (d: any) => xScaleBrush(d.dateExtent[0]))
      .attr('y', (d: any, i: any) => i * (margins.brush/(d.parent.children.length)) + 1.5)
      .attr('fill', (d: any) => myChart.props.groupColours[d.data.name] === undefined ? myChart.props.groupColours["default"]: myChart.props.groupColours[d.data.name])
      .attr('height', (d: any) => (margins.brush/d.parent.children.length) - 3)
      .attr('width', (d: any) => xScaleBrush(d.dateExtent[1]) - xScaleBrush(d.dateExtent[0]))

    drawChart();
    drawLegend();

    function drawChart(){

      let treeData = chartData.descendants().filter((f: any) => f.depth > 0);

      chartHeight = (treeData.length * rowHeight);

      treeData = treeData.sort((a: any, b: any) => d3.ascending(a.data.descendantIndex, b.data.descendantIndex))
      mySvg.attr('height', chartHeight  + margins.top + margins.bottom + margins.mid + margins.brush);

      treeData.forEach((d: any, i: any) => {
         d.startLeft = margins.left + ((d._children === undefined ? d.depth - 2 : d.depth - 1) * 10);
         d.yPos = (margins.top + (i * rowHeight));
         const textX = d._children === undefined ? 10 : 18;
         const fontSize = d.depth > 2 ? fontSizes["above"] : fontSizes[d.depth];
         const myLeftWidth = d.startLeft + measureWidth(d.data.name, fontSize+1) ;
         if(myLeftWidth > (margins.leftExtra + margins.left)){
           const nameRequiredLength = margins.leftExtra - (d.startLeft + textX) + 10;
           let textWidth = measureWidth(d.data.name, fontSize+1);
           d.data.fullName = JSON.stringify(d.data.name);
           // stores full name (for tooltip)
           while(textWidth > nameRequiredLength){
             // loops through and takes one letter off the end of the name
             // until it is the correct length
             d.data.name = d.data.name.substr(0, d.data.name.length - 1);
             textWidth = measureWidth(d.data.name, fontSize+1);
           }
           d.data.name = d.data.name + ".."
         }
      })

      xScaleSelected.range([0, width - margins.left - margins.right - margins.leftExtra]);

      d3.select('.todayLineTriangle' + myClass)
        .attr('stroke', '#1363DF')
        .attr('d', 'M-2,0 L2,0 L0,1.5 Z')
        .attr('transform', 'translate(' + (margins.left + margins.leftExtra + xScaleSelected(new Date())) + ',' + (margins.top -2) + ')');

      d3.select('.todayLine' + myClass)
        .attr('stroke', '#1363DF')
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', '4,2')
        .attr('x1', margins.left +  margins.leftExtra + xScaleSelected(new Date()))
        .attr('x2', margins.left +  margins.leftExtra + xScaleSelected(new Date()))
        .attr('y1', margins.top + 2)
        .attr('y2', margins.top + chartHeight);

      d3.selectAll('.brushChartItems' + myClass)
        .attr('transform', 'translate(' + (margins.left + margins.leftExtra) + ',' + (margins.top + chartHeight + margins.mid) + ')');

      d3.select('.chartBackgroundRect' + myClass)
        .attr('fill', '#F6F8FB')
        .attr('width', width - margins.left - margins.right - margins.leftExtra)
        .style('height', chartHeight)
        .attr('transform', 'translate(' + (margins.left + margins.leftExtra) + ',' + margins.top + ')');

      d3.select('.brushBackgroundRect' + myClass)
        .attr('transform', 'translate(' + (margins.left + margins.leftExtra) + ',' + (margins.top + chartHeight + margins.mid) + ')');

      // dates header group
      const treeGroup = mySvg
        .selectAll('.treeGroup' + myClass)
        .data(treeData)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'treeGroup' + myClass);
          enter.append('circle').attr('class', 'expandCircle');
          enter.append('line').attr('class', 'expandLine');
          enter.append('text').attr('class', 'expandLabel');
          enter.append('text').attr('class', 'nodeLabel');
          enter.append('line').attr('class', 'nodeLineLeft');
          enter.append('line').attr('class', 'nodeHorizontalLine');
          return enter;
        });

      treeGroup.attr('transform', (d: any) => 'translate(' + d.startLeft + ',' + d.yPos + ')');

      treeGroup.select('.nodeHorizontalLine')
        .attr('x1', 0)
        .attr('x2', (d: any) => margins.leftExtra  - d.startLeft)
        .attr('y1', timeRectHeight)
        .attr('y2', timeRectHeight)
        .attr('stroke-width', 1)
        .attr('stroke', '#F0F3F6');

      treeGroup.select('.expandLine')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', 16)
        .attr('y2', (d: any) => d.children === undefined || d.children === null ? 0 : (d.children.length * rowHeight) + 8)
        .attr('stroke-width', (d: any) => d.children === undefined || d.children === null ? 0 : (d.children.find((f: any) => f.data.children === undefined) === undefined ? 0 : 1))
        .attr('stroke', '#8A98AB');

      treeGroup.select('.nodeLineLeft')
        .attr('x1', (d: any) => d.data.children === undefined ? 7 : 0)
        .attr('x2', 0)
        .attr('y1', 8)
        .attr('y2', 8)
        .attr('stroke-width', 1)
        .attr('stroke', '#8A98AB');

      treeGroup.select('.expandCircle')
        .attr('cy', 8)
        .attr('r', (d: any) => d._children === undefined || d.children === null? 0 : 8)
        .attr('fill', '#E8EAEE');

      treeGroup.select('.expandLabel')
        .attr('font-family','Poppins')
        .attr('x', (d: any) => d._children === undefined ? 0 : (d.children === null ? 0 : 0.5))
        .attr('y', (d: any) => d._children === undefined ? 0 : (d.children === null ? 12 : 16))
        .attr('font-size', (d: any) => d._children === undefined ? '' : (d.children === null ? 14 : 22))
        .attr('font-weight',500)
        .attr('text-anchor','middle')
        .attr('fill',(d: any) => d._children === undefined ? '' : (d.children === null ? '#1363DF' : '#8A98AB'))
        .attr('cursor', 'pointer')
        .attr('pointer-events', (d: any) => d._children === undefined ? 'none' : 'all')
        .text((d: any) => d._children === undefined ? '' : (d.children === null ? '+' : '-'))
        .on('click', (event: any, d: any) => {
          if(d.children === null){
            d.children = d._children;
            d._children = null;
          } else {
            d._children = d.children;
            d.children = null;
          }
          drawChart();
        });

      treeGroup.select('.nodeLabel')
        .attr('font-family','Poppins')
        .attr('x', (d: any) => d._children === undefined ? 10 : 18)
        .attr('y', (d: any) => 8 + (d.depth > 2 ? (fontSizes["above"] * 0.4) : (fontSizes[d.depth] * 0.4)))
        .attr('font-size', (d: any) => d.depth > 2 ? fontSizes["above"] : fontSizes[d.depth])
        .attr('font-weight',500)
        .text((d: any) => d.data.name)
        .on("mouseover", (event:any, d: any) => {
          if(d.data.name.includes("..")){
            myChart.showTooltip('groupFullName', d.data.fullName, event.offsetX, event.offsetY, width);
          }
        })
        .on("mouseout", () => {
          myChart.hideTooltip("groupFullName");
        });;


      d3.select('#ganttBrushClipRect')
        .style('width', width - margins.left - margins.right - margins.leftExtra + 6)
        .style('height', chartHeight + margins.top + margins.mid + margins.brush + margins.bottom + 10)
        .attr('transform', 'translate(' + (margins.left + margins.leftExtra-1) + ',-10)');

      mySvg.select("#chartGroup" + myClass).attr('clip-path', 'url(#ganttBrushClip)');

      treeData.map((m: any) => m.rectWidth = Math.max(xScaleSelected(m.dateExtent[1]) - xScaleSelected(m.dateExtent[0]), dayWidth))

      // dates header group
      const treeChartGroup = mySvg
        .select('#chartGroup' + myClass)
        .selectAll('.treeChartGroup' + myClass)
        .data(treeData)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'treeChartGroup' + myClass);
          enter.append('path').attr('class', 'timeRect');
          enter.append('g').attr('class', 'flagGroup');
          enter.append('g').attr('class', 'refillGroup');
          enter.append('rect').attr('class', 'recordGenerateRect');
          enter.append('text').attr('class', 'recordGenerateLabel');
          return enter;
        });

      treeChartGroup.select('.recordGenerateRect')
        .attr("width",(d: any) => d.data.record_generate_type === undefined || d.data.record_generate_type === null ? 0
          : measureWidth(d.data.record_generate_type,12) + 2)
        .attr("height", timeRectHeight -4)
        .attr('transform', (d: any) => 'translate(' + (xScaleSelected(d.dateExtent[0]) + 2)
          + ',' + (2) + ')')
        .attr("rx", 4)
        .attr("ry", 5)
        .attr('fill',"white")
        .style('filter',  'url(#drop-shadow)')
        .on('mouseover', (event: any, d: any) => {
          if(d.data.record_generate_type !== undefined && d.data.record_generate_type !== null ){
            myChart.showTooltip('RGT',d.record_generate_type_description, event.offsetX, event.offsetY,width);
          }
        })
        .on("mouseout", () => {
          myChart.hideTooltip('RGT')
        })


      treeChartGroup.select('.recordGenerateLabel')
        .attr("pointer-events", "none")
        .attr("height", timeRectHeight -4)
        .attr('transform', (d: any) => 'translate(' + (xScaleSelected(d.dateExtent[0]) + 5)
          + ',' + ((timeRectHeight/2)  + 4) + ')')
        .attr('fill','#1363DF')
        .attr('font-size', 10)
        .attr('font-weight',500)
        .attr('font-family','Poppins')
        .text((d: any) => d.data.record_generate_type === undefined || d.data.record_generate_type === null ? "" : d.data.record_generate_type)

      treeChartGroup.attr('transform', (d: any, i: any) => 'translate(' + (margins.left + margins.leftExtra) + ',' + (margins.top + (i * rowHeight)) + ')');

      treeChartGroup.select('.timeRect')
        .attr('transform', (d: any) => 'translate(' + xScaleSelected(d.dateExtent[0]) + ',0)')
        .attr('stroke', (d: any) => d.colors)
        .attr('fill', (d: any) => d.colors)
        .attr('fill-opacity', (d: any) => d.data.children === undefined ? 1 : (d.children === null ? 1 : 0.4))
        .attr('stroke-opacity', (d: any) => d.data.children === undefined ? 1 : (d.children === null ? 1 : 0.4))
        .attr('stroke-width', 1.5)
        .attr('d', getRectPath)
        .on('mousemove', function(event: any, d: any){
          //temp cheat for demo on Friday
          d3.select('#tempd3Tooltip')
            .style('visibility', 'visible')
            .style('border', '0px solid #707070')
            .style('background-color', '#FFFFFF')
            .style('border-radius', '4px')
            .style('position', 'absolute')
            .style('pointer-events', 'none')
            .style('width', 'auto')
            .style('padding', '4px')
            .style('font-size', '10px')
            .style('left', (event.pageX + 10) + 'px')
            .style('top', event.pageY + 'px')
            .style('height', 'auto')
            .html('<strong>' + d.data.name + '</strong><br>From: ' + dateFormat(d.data.startDate)  +  '<br>To: ' + dateFormat(d.data.endDate))
          //@ts-ignore
          d3.select(this).attr('fill-opacity', 0.2);

        })
        .on('mouseout', function(){
          d3.select('#tempd3Tooltip').style('visibility', 'hidden')
          mySvg.selectAll('.timeRect')     .attr('fill-opacity', (d: any) => d.data.children === undefined ? 1 : (d.children === null ? 1 : 0.4));
          myChart.hideTooltip('timeRect');
        })
        .on('click', function(event: any, d: any){
          if(d.data.transactionname === undefined){
           myChart.showTooltip('timeRect',d, event.offsetX, event.offsetY,width);
          } else {
           myChart.showTooltip('timeRectLeaf',d, event.offsetX, event.offsetY,width);
          }
        })

      const flagMinWidth = 6 + measureWidth(d3.timeFormat("%d %b, %Y")(new Date()), 11)
      // dates header group
      const flagGroup = treeChartGroup.select('.flagGroup')
        .selectAll('.flagGroup' + myClass)
        .data((d: any) => {
          //get flags related to my root
          let myFlags: any = [];
          if(d._children !== null){
            //get all flags for this root
            myFlags = flagData.filter((f: any) => d.childIds.indexOf(f.parentId) > -1);
            myFlags = myFlags.sort((a: any, b: any) => d3.descending(a.TransactionDate, b.TransactionDate))
            const flagGroup:any = Array.from(d3.group(myFlags, (g: any) => g.TransactionDate));
            myFlags.map((m: any) => m.flagDateCount = flagGroup.find((f: any) => String(f[0]) === String(m.TransactionDate))[1].length)
            myFlags.map((m: any) => m.flagDateIndex = flagGroup.find((f: any) => String(f[0]) === String(m.TransactionDate))[1].findIndex((a: any) => a.flagId === m.flagId))
            myFlags.map((m: any) => m.yPos = d.yPos);
            myFlags.map((m: any) => m.legendId = m[myChart.props.transactionTypeVar].replace(/ /g, '').toLowerCase())
            myFlags.map((m: any) => m.legendId = m[myChart.props.transactionTypeVar].toLowerCase().includes("change") ? "change" : m.legendId)
          }
          myFlags = myFlags.reverse();
          return myFlags;
      })
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'flagGroup' + myClass);
          enter.append('line').attr('class', 'flagDateLine');
          enter.append('path').attr('class', 'flagLinePath');
          enter.append('rect').attr('class', 'flagRect');
          enter.append('circle').attr('class', 'flagCircle');
          enter.append('svg').attr('class', 'flagIconSvg').append('path').attr('class', 'flagIconPath');
          enter.append('text').attr('class', 'flagLabel');
          enter.append('text').attr('class', 'flagDateLabel');
          enter.append('text').attr('class', 'flagRefillLabel');
          return enter;
        });

      flagGroup
        .attr("id", (d: any) => d.flagId)
        .attr('class', (d: any) => 'flagGroup' + myClass + ' legendFlagGroup ' + d.legendId)

      d3.selectAll('.flagGroup' + myClass)
        .attr('opacity', (d: any) => myChart.currentLegendSelections.indexOf(d.legendId) > - 1 ? 1 : 0);

      flagGroup.select('.flagLinePath')
        .attr("id", (d: any) => d.flagId)
        .attr('stroke','#8A98AB')
        .attr('fill','transparent')
        .attr('stroke-width', 1)
        .attr('d', 'M2,0 Q0,0 0,2 L0, 11')
        .attr('transform', (d: any) => 'translate(' + xScaleSelected(d.TransactionDate) +  ',' + ( -11.5 + (-20 * d.flagDateIndex)) + ')')

      flagGroup.select('.flagDateLine')
        .attr('visibility', 'hidden')
        .attr("id", (d: any) => d.flagId)
        .attr('stroke',(d: any) => d.refillTooSoon !== null ? '#D11044': '#101D42')
        .attr('stroke-dasharray', '4,2')
        .attr('stroke-width', 1)
        .attr('x1', (d: any) => xScaleSelected(d.TransactionDate))
        .attr('x2', (d: any) => xScaleSelected(d.TransactionDate))
        .attr('y1', (d: any) =>   (-20 * d.flagDateIndex))
        .attr('y2', (d: any) =>   margins.top + chartHeight - d.yPos)

      flagGroup.select( '.flagIconSvg')
        .attr('pointer-events', 'none')
        .attr("visibility", (d: any) => d.name.toLowerCase().includes("change") ? "visible" : "hidden")
        .attr('x',  (d: any) => xScaleSelected(d.TransactionDate) + 7)
        .attr('y', (d: any) => - 17 + (-20 * d.flagDateIndex))
        .attr('viewBox', changeIconViewBox)
        .attr( 'width', 10)
        .attr( 'height', 10)

      flagGroup.select( '.flagIconPath')
        .attr('pointer-events', 'none')
        .attr('fill',  '#1363DF')
        .attr( 'd',  changeIconPath);

      flagGroup.select('.flagCircle')
        .attr("visibility", (d: any) => d.name.toLowerCase().includes("change") ? "hidden" : "visible")
        .attr("pointer-events", "none")
        .attr('cx', (d: any) => xScaleSelected(d.TransactionDate) + 12)
        .attr('cy', (d: any) =>  - 12 + (-20 * d.flagDateIndex))
        .attr('fill','white')
        .attr('stroke-width', 2.5)
        .attr('stroke', (d: any) => flagColours[d[myChart.props.transactionTypeVar].toLowerCase()])
        .attr('r', 4)

      flagGroup.select('.flagLabel')
        .attr("id", (d: any) => d.flagId)
        .attr("pointer-events", "none")
        .attr("visibility", "hidden")
        .attr('x', (d: any) => xScaleSelected(d.TransactionDate) + 20)
        .attr('y', (d: any) =>  - 9 + (-20 * d.flagDateIndex))
        .attr('fill','#101D42')
        .attr('font-size', 10)
        .attr('font-weight',500)
        .attr('font-family','Poppins')
        .style('text-transform','capitalize')
        .text((d: any) => d.name.replace(/_/g,' '));

      flagGroup.select('.flagRefillLabel')
        .attr("id", (d: any) => d.flagId)
        .attr("pointer-events", "none")
        .attr("visibility", "hidden")
        .attr('font-family','Poppins')
        .attr('x', (d: any) => xScaleSelected(d.TransactionDate) + 5)
        .attr('y', (d: any) =>   15 + (-20 * d.flagDateIndex))
        .attr('fill','#D11044')
        .attr('font-size', 10)
        .attr('font-weight',500)
        .text((d: any) => d.refillTooSoon === null ? '': 'Refill Too Soon')

      flagGroup.select('.flagDateLabel')
        .attr("pointer-events", "none")
        .attr("visibility", "hidden")
        .attr('font-family','Poppins')
        .attr("id", (d: any) => d.flagId)
        .attr('x', (d: any) => xScaleSelected(d.TransactionDate) + 5)
        .attr('y', (d: any) =>  3 + (-20 * d.flagDateIndex))
        .attr('fill','#8A98AB')
        .attr('font-size', 10)
        .attr('font-weight',500)
        .text((d: any) => d3.timeFormat("%d %b, %Y")(d.TransactionDate))

      flagGroup.select('.flagRect')
        .attr("id", (d: any) => d.flagId)
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('stroke',(d: any) => d.refillTooSoon !== null ? '#D11044': (d.name.toLowerCase().includes("change")
          ? '#1363DF' :  flagColours[d[myChart.props.transactionTypeVar].toLowerCase()]))
        .attr('stroke-width', (d: any) => d.refillTooSoon !== null ? 1 : 0)
        .attr('x', (d: any) => xScaleSelected(d.TransactionDate) + 2)
        .attr('y', (d: any) => - 20 + (-20 * d.flagDateIndex))
        .attr('width',20)
        .attr('height',16)
        .attr('fill',"white")
        .style('filter',  'url(#drop-shadow)')
        .on('mouseover', function(event: any, d: any) {
          d3.selectAll('.flagDateLabel').interrupt().attr('visibility','hidden');
          d3.selectAll('.flagDateLine').interrupt().attr('visibility','hidden');
           d3.selectAll('.flagRect').interrupt().attr('height', 16)
             .attr('stroke-width', (d: any) => d.refillTooSoon !== null ? 0.5 : 0);
          d3.selectAll('.flagLinePath').interrupt().attr('stroke','#8A98AB').attr('stroke-dasharray', '')
           //@ts-ignore
            const myObject: any = this;
            d3.select(myObject.parentElement).interrupt().raise().raise();
            d3.select(myObject).interrupt().transition().duration(100)
              .attr('width',  (d: any) => Math.max(21 + measureWidth(d.name, 11.5), flagMinWidth,
                (d.refillTooSoon !== null ? 3 + measureWidth('Refill Too Soon', 11.5) : 0)))
              .attr('height', 28 + (d.refillTooSoon !== null ? 12 : 0))
              .attr('stroke-width', 1);
              d3.selectAll("text#" + d.flagId).interrupt().transition().delay(100).duration(100).attr('visibility', 'visible');
              d3.select("line#" + d.flagId).interrupt().transition().delay(100).duration(100).attr('visibility', 'visible');
              d3.select("path#" + d.flagId).interrupt() .attr('stroke',(d: any) => d.refillTooSoon !== null ? '#D11044': '#101D42').attr('stroke-dasharray', '4,2');
        })
        .on('mouseout', () => {
          myChart.hideTooltip('flag');
          d3.selectAll('.flagRect').interrupt().attr('height', 16).attr('width', 20).attr('stroke-width', (d: any) => d.refillTooSoon !== null  ? 1 : 0);
          d3.selectAll('.flagDateLabel').interrupt().attr('visibility','hidden');
          d3.selectAll('.flagLabel').interrupt().attr('visibility','hidden');
          d3.selectAll('.flagRefillLabel').interrupt().attr('visibility','hidden');
          d3.selectAll('.flagDateLine').interrupt().attr('visibility','hidden');
         d3.selectAll('.flagLinePath').interrupt().attr('stroke','#8A98AB').attr('stroke-dasharray', '')
        })
        .on('click', (event: any, d: any) => {
          myChart.showTooltip('flag', d, event.offsetX, event.offsetY, width);
        })
        .text((d: any) => d.Description);

      d3.select('#xAxisChart' + myClass)
        //@ts-ignore
        .call(d3.axisTop(xScaleSelected).tickSizeOuter(0).tickValues(xScaleSelected.ticks(7)))
        .attr('transform', 'translate(' + (margins.left + margins.leftExtra) + ',' + (margins.top) + ')');

      d3.selectAll('#xAxisChart' + myClass + ' path')
        .style('display', 'none');

      d3.selectAll('#xAxisChart' + myClass + ' text')
        .text('');

      d3.selectAll('#xAxisChart' + myClass + ' line')
        .attr('y1', '-25')
        .attr('y2', chartHeight)
        .style('stroke', '#E4E4E4')
        .style('stroke-width', 1);

      d3.select('#xAxisChartLabels' + myClass)
        //@ts-ignore
        .call(d3.axisTop(xScaleSelected).tickSizeOuter(0).tickValues(xScaleSelected.ticks(7)))
        .attr('transform', 'translate(' + (margins.left + margins.leftExtra) + ',' + (margins.top) + ')');

      d3.selectAll('#xAxisChartLabels' + myClass + ' path')
        .style('display', 'none');

      d3.selectAll('#xAxisChartLabels' + myClass + ' line')
        .style('display', 'none');

      d3.selectAll('#xAxisChartLabels' + myClass + ' text')
        .style('font-weight', 700)
        .style('font-family', 'Poppins')
        .style('font-size', 10)
        .style('text-anchor', (d: any, i: any) => i === 0 ? "middle" : "middle")
        .attr('y', -30)
        .text(function (d: any) {
          // @ts-ignore
          const myText = d3.select(this).text()
          return months.indexOf(myText) > -1 ? myText.substr(0,3) : myText;
        });

      d3.select('#xAxis' + myClass)
        //@ts-ignore
        .call(d3.axisTop(xScaleBrush).tickSizeOuter(0).ticks(7))
        .attr('transform', 'translate(' + (margins.left + margins.leftExtra) + ',' + (margins.top + chartHeight + margins.mid) + ')');

      d3.selectAll('#xAxis' + myClass + ' path')
        .style('display', 'none');

      d3.selectAll('#xAxis' + myClass + ' text')
        .style('font-family', 'Poppins')
        .style('font-weight', 700)
        .style('font-size', 10)
        .attr('y', 12 + margins.brush)
        .text(function (d: any) {
            // @ts-ignore
            const myText = d3.select(this).text()
            return months.indexOf(myText) > -1 ? myText.substr(0,3) : myText;
          });


      d3.selectAll('#xAxis' + myClass + ' line')
        .attr('y1', '0')
        .attr('y2', margins.brush * 0.75)
        .style('stroke', '#E4E4E4')
        .style('stroke-width', 1);

      d3.select('#brushGroup' + myClass)
        .attr('transform', 'translate(' + (margins.left + margins.leftExtra) + ',' + (margins.top + chartHeight + margins.mid) + ')')

      d3.selectAll('.handleLines')
        .attr('y1', margins.top + chartHeight + margins.mid + (margins.brush - 12) / 2)
        .attr('y2', margins.top + chartHeight + margins.mid + 12 + (margins.brush - 12) / 2)
        .style('stroke', '#8A98AB')
        .style('stroke-width', '1')
        .attr('transform', 'translate(' + (margins.left + margins.leftExtra) + ',0)');


      const markerPath = "M0 6.10083V1C0 0.447715 0.447715 0 1 0H6C6.55228 0 7 0.447716 7 1V6.10083C7 6.43274 6.83532 6.74302 6.56044 6.92903L4.06044 8.62076C3.72192 8.84983 3.27808 8.84983 2.93957 8.62076L0.439565 6.92903C0.164681 6.74302 0 6.43274 0 6.10083Z"

      // dates header group
      const headerProgramGroup = mySvg
        .selectAll('.headerProgram' + myClass)
        .data(programData)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'headerProgram' + myClass);
          enter.append('rect').attr('class', 'programRect');
          enter.append('text').attr('class', 'programLabel');
          enter.append('line').attr('class', 'markerLineStart');
          enter.append('path').attr('class', 'markerStart');
          enter.append('line').attr('class', 'markerLineEnd');
          enter.append('path').attr('class', 'markerEnd');
          return enter;
        });

      headerProgramGroup
        .attr('transform', 'translate(' + margins.headerLeft + ',0)')
        .on('mouseover',  (event: any, d: any) => {
          const tooltipText = d[myChart.props.programVar] + '<br>' + d.days + ' days';
          myChart.showTooltip('program', tooltipText, event.offsetX, event.offsetY, width);
        })
        .on('mouseout',  () => {
          myChart.hideTooltip('averageDot');
        });

      headerProgramGroup.select('.markerLineEnd')
        .attr('stroke', '#101D42')
        .attr('x1', (d: any) => xScaleHeader(d[myChart.props.endDateVar]))
        .attr('x2', (d: any) => xScaleHeader(d[myChart.props.endDateVar]))
        .attr('y1', (margins.headerHeight/2) - 14)
        .attr('y2', (d: any) => d.includeEnd === true ? (margins.headerHeight/2) - 8 : (margins.headerHeight/2) - 14);

      headerProgramGroup.select('.markerEnd')
        .attr('fill', '#101D42')
        .attr('d', (d: any) => d.includeEnd === true ? markerPath : '')
        .attr('transform', (d: any) => 'translate(' + (xScaleHeader(d[myChart.props.endDateVar]) - 3.5) +
          ',' +  ((margins.headerHeight/2) - 4 - 19) + ')');

      headerProgramGroup.select('.markerLineStart')
        .attr('stroke', '#101D42')
        .attr('x1', (d: any) => xScaleHeader(d[myChart.props.startDateVar]))
        .attr('x2', (d: any) => xScaleHeader(d[myChart.props.startDateVar]))
        .attr('y1', (margins.headerHeight/2) - 14)
        .attr('y2', (margins.headerHeight/2) - 8);

      headerProgramGroup.select('.markerStart')
        .attr('fill', '#101D42')
        .attr('d', markerPath)
        .attr('transform', (d: any) => 'translate(' + (xScaleHeader(d[myChart.props.startDateVar]) - 3.5) +
          ',' +  ((margins.headerHeight/2) - 4 - 19) + ')');

       headerProgramGroup.select('.programRect')
        .attr('fill', (d: any) => programColourScale(d[myChart.props.programVar]))
        .attr('height', 8)
        .attr('rx', 4)
        .attr('ry', 4)
        .attr('x', (d: any) => xScaleHeader(d[myChart.props.startDateVar]))
        .attr('width', (d: any) => xScaleHeader(d[myChart.props.endDateVar]) - xScaleHeader(d[myChart.props.startDateVar]))
        .attr('y', (margins.headerHeight/2) - 4)

      headerProgramGroup.select('.programLabel')
        .attr('fill', '#101D42')
        .style('font-weight', 500)
        .style('font-family', 'Poppins')
        .style('font-size', 10)
        .style('text-anchor', 'middle')
        .attr('x', (d: any) => xScaleHeader(d[myChart.props.startDateVar]) + (xScaleHeader(d[myChart.props.endDateVar]) - xScaleHeader(d[myChart.props.startDateVar]))/2)
        .attr('y', (margins.headerHeight/2) - 14)
        .text((d: any) => (measureWidth(d[myChart.props.programVar] + ': ' + d.days + ' days',11) + 5) > (xScaleHeader(d[myChart.props.endDateVar]) - xScaleHeader(d[myChart.props.startDateVar]))
          ? '' : d[myChart.props.programVar] + ': ' + d.days + ' days');


      // dates header group
      const headerDispenseGroup = mySvg
        .selectAll('.headerDispenseGroup' + myClass)
        .data(dispenseData)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'headerDispenseGroup' + myClass);
          enter.append('line').attr('class', 'dispenseCircleLine');
          enter.append('circle').attr('class', 'dispenseCircle');
          return enter;
        });

      headerDispenseGroup
        .attr('visibility', myChart.props.showTopCircleFlags === true ? "visible" : "hidden")
        .attr('class', (d: any) => 'headerDispenseGroup' + myClass + ' legendFlagGroup ' + d.type.replace(/ /g, '').toLowerCase())
        .attr('opacity', (d: any) => myChart.currentLegendSelections.indexOf(d.type.replace(/ /g, '').toLowerCase()) > - 1 ? 1 : 0)
        .attr('transform', 'translate(' + margins.headerLeft + ',0)')

      headerDispenseGroup.select('.dispenseCircle')
        .attr('fill', 'white')
        .attr('stroke', (d: any) => flagColours[d.type.toLowerCase()])
        .attr('r', 4)
        .attr('stroke-width', 2.5)
        .attr('cx', (d: any) => xScaleHeader(d[myChart.props.dispenseTransactionDateVar]))
        .attr('cy', (margins.headerHeight/2) + 4 + 12);

      headerDispenseGroup.select('.dispenseInnerCircle')
        .attr('fill', 'white')
        .attr('r', 1.5)
        .attr('cx', (d: any) => xScaleHeader(d[myChart.props.dispenseTransactionDateVar]))
        .attr('cy', (margins.headerHeight/2) + 4 + 12);

      headerDispenseGroup.select('.dispenseCircleLine')
        .attr('stroke', (d: any) => flagColours[d.type.toLowerCase()])
        .attr('x1', (d: any) => xScaleHeader(d[myChart.props.dispenseTransactionDateVar]))
        .attr('x2', (d: any) => xScaleHeader(d[myChart.props.dispenseTransactionDateVar]))
        .attr('y1', (margins.headerHeight/2) + 8)
        .attr('y2', (margins.headerHeight/2) + 4 + 9);

      // dates header group
      const kpiGroupsGroup = mySvg
        .selectAll('.kpiGroupsGroup' + myClass)
        .data(kpiGroups)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'kpiGroupsGroup' + myClass);
          enter.append('g').attr('class', 'kpiGroupRows');
          return enter;
        });

      kpiGroupsGroup.select('.kpiGroupRows')
        .attr('transform', (d: any, i: any ) => 'translate(' + margins.headerLeft + ',' + ((margins.headerHeight/2) + 40 + (i * kpiHeight)) + ')')

      const kpiRowsGroup = kpiGroupsGroup.select('.kpiGroupRows')
        .selectAll('.kpiRowsGroup' + myClass)
        .data((d: any) => d.data)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'kpiRowsGroup' + myClass);
          enter.append('line').attr('class', 'kpiLine');
          enter.append('text').attr('class', 'kpiLabel');
          enter.append('line').attr('class', 'kpiMarkerLineStart');
          enter.append('path').attr('class', 'kpiMarkerStart');
          enter.append('line').attr('class', 'kpiMarkerLineEnd');
          enter.append('path').attr('class', 'kpiMarkerEnd');
          return enter;
        });

      kpiRowsGroup
        .on('mouseover',  (event: any, d: any) => {
          const tooltipText = d[myChart.props.kpiVar] + '<br>' + d.dayCount + ' days';
          myChart.showTooltip('program', tooltipText, event.offsetX, event.offsetY, width);
        })
        .on('mouseout',  () => {
          myChart.hideTooltip('averageDot');
        });
      kpiRowsGroup.select('.kpiMarkerLineEnd')
        .attr('stroke', '#101D42')
        .attr('x1', (d: any) => xScaleHeader(d[myChart.props.endDateVar]))
        .attr('x2', (d: any) => xScaleHeader(d[myChart.props.endDateVar]))
        .attr('y1',  -8)
        .attr('y2', 0);

      kpiRowsGroup.select('.kpiMarkerEnd')
        .attr('fill', '#101D42')
        .attr('d', markerPath)
        .attr('transform', (d: any) => 'translate(' + (xScaleHeader(d[myChart.props.endDateVar]) + 3.5) +
          ',' +  ( 4) + ')  rotate(180)');

      kpiRowsGroup.select('.kpiMarkerLineStart')
        .attr('stroke', '#101D42')
        .attr('x1', (d: any) => xScaleHeader(d[myChart.props.startDateVar]))
        .attr('x2', (d: any) => xScaleHeader(d[myChart.props.startDateVar]))
        .attr('y1',  -8)
        .attr('y2', 0);

      kpiRowsGroup.select('.kpiMarkerStart')
        .attr('fill', '#101D42')
        .attr('d', markerPath)
        .attr('transform', (d: any) => 'translate(' + (xScaleHeader(d[myChart.props.startDateVar]) + 3.5) +
          ',' +  (4) + ') rotate(180)');

      kpiRowsGroup.select('.kpiLine')
        .attr('stroke', '#E8EAEE')
        .attr('stroke-dasharray', '4,4')
        .attr('x1', (d: any) => xScaleHeader(d[myChart.props.startDateVar]))
        .attr('x2', (d: any) => xScaleHeader(d[myChart.props.endDateVar]))
        .attr('y1', 0)
        .attr('y2', 0);

      kpiRowsGroup.select('.kpiLabel')
        .attr('fill', '#101D42')
        .style('font-weight', 500)
        .style('font-family', 'Poppins')
        .style('font-size', 10)
        .style('text-anchor', 'middle')
        .attr('x', (d: any) => xScaleHeader(d[myChart.props.startDateVar]) + (xScaleHeader(d[myChart.props.endDateVar]) - xScaleHeader(d[myChart.props.startDateVar]))/2)
        .attr('y', -5)
        .text((d: any) => (measureWidth(d[myChart.props.kpiVar] + ': ' + d.dayCount + ' days',11) + 5) > (xScaleHeader(d[myChart.props.endDateVar]) - xScaleHeader(d[myChart.props.startDateVar]))
        ? '' : d[myChart.props.kpiVar] + ': ' + d.dayCount + ' days');


      function getRectPath(d: any){
        if(d.data.children === undefined  || d.rectWidth <= 2){
          return 'M0,0 L' + d.rectWidth + ',0 L' + d.rectWidth +  ' ' + timeRectHeight +  ' L0,' + timeRectHeight +  'Z';
        } else {
          return 'M0,0 L' + d.rectWidth + ',0 L' + d.rectWidth +  ' ' + (timeRectHeight + 3) +
            ' Q' + d.rectWidth + ' ' + (timeRectHeight + 5) + ' , ' + (d.rectWidth-1.5) + ' ' + (timeRectHeight + 2)
            +  ' L' + (d.rectWidth - 2) + ',' + timeRectHeight + 'L2,' + timeRectHeight + ' L1.5,' + (timeRectHeight + 2) +
            ' Q0 ' + (timeRectHeight + 5) + ', 0 ' + (timeRectHeight + 3) + ' Z'
        }
      }
    }

    function measureWidth(myText: any, myFontSize: any): any {
      const context: any = document.createElement('canvas').getContext('2d');
      context.font = (myFontSize) + 'px sans-serif';
      return context.measureText(myText).width;
    }

    function brushed(event: any): void {

      const selection = event.selection;
      if (selection !== null) {
        const xDomain = selection.map(xScaleAll.invert);
        xScaleSelected.domain(xDomain);

        dayWidth = Math.max(12, xScaleSelected(d3.timeDay.offset(xDomain[0],1)));
        const handleEastX = +d3.select('.handle--e').attr('x');
        const handleWestX = +d3.select('.handle--w').attr('x');

        d3.select('.handleLeftLine1' + myClass)
          .attr('x1', handleEastX + 3)
          .attr('x2', handleEastX + 3);

        d3.select('.handleLeftLine2' + myClass)
          .attr('x1', handleEastX + 7)
          .attr('x2', handleEastX + 7);

        d3.select('.handleRightLine1' + myClass)
          .attr('x1', handleWestX + 3)
          .attr('x2', handleWestX + 3);

        d3.select('.handleRightLine2' + myClass)
          .attr('x1', handleWestX + 7)
          .attr('x2', handleWestX + 7);

        d3.select('#brushGroup' + myClass)
          .selectAll('.handle')
          .attr('fill', 'white')
          .attr('rx', 4)
          .attr('ry', 4)
          .attr('y', (margins.brush - 24) / 2)
          .attr('height', 24)
          .style('filter',  'url(#drop-shadow)');

        if(event.sourceEvent !== undefined){
          drawChart();
        }

      }
    }

    function drawLegend(): void {

      // draw legend
      const legendGroup = mySvg
        .selectAll('.legendGroup' + myClass)
        .data(flagSet)
        .join(function (group: any): any {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('circle').attr('class', 'legendItem legendCircle');
          enter.append('text').attr('class', 'legendItem legendLabel');
          return enter;
        });

      legendGroup
        .attr('opacity', (d: any) => myChart.currentLegendSelections.indexOf(d.toLowerCase().replace(/ /g, '')) > -1 ? 1 : 0.4)
        .attr('id', (d: any, i: any) => 'group_legendLabel' + i)
        .on('click', legendClick)

      legendGroup
        .select('.legendCircle')
        .attr('fill-opacity', 1)
        .attr('id', (d: any, i: any) => 'circle_legendLabel' + i)
        .attr('cy', 10)
        .attr('r', 4)
        .attr('fill', 'white')
        .attr('stroke-width', '2.5')
        .attr('stroke', (d: any) => flagColours[d.toLowerCase()])
        .attr('cursor', 'pointer');

      legendGroup
        .select('.legendLabel')
        .attr('fill-opacity', 1)
        .attr('font-size', 12)
        .attr('font-weight', 500)
        .attr('font-family', 'Poppins')
        .style('text-transform', 'capitalize')
        .attr('id', (d: any, i: any) => 'legendLabel' + i)
        .attr('y', 10 + 4)
        .attr('fill', '#101D42')
        .attr('cursor', 'pointer')
        .text((d: any) => d);

      function legendClick(event: any, d: any) {
        //@ts-ignore
        const myItem: any = this;
        if(+d3.select(myItem).attr('opacity') === 1){
          myChart.currentLegendSelections = myChart.currentLegendSelections.filter((f: any) => f !== d.replace(/ /g, '').toLowerCase());
          //@ts-ignore
          d3.selectAll('#' + myItem.id).interrupt().transition().duration(200).attr('opacity', 0.4);
          d3.selectAll('.' + d.replace(/ /g, '').toLowerCase()).interrupt().transition().duration(200).attr('opacity', 0);
        } else {
          myChart.currentLegendSelections.push(d.replace(/ /g, '').toLowerCase());
          //@ts-ignore
          d3.selectAll('#' + myItem.id).interrupt().attr('opacity', 1);
          d3.selectAll('.' + d.replace(/ /g, '').toLowerCase()).interrupt().transition().duration(200).attr('opacity', 1);
        }
      }

      let legendX = 15;

      d3.selectAll('.legendLabel').each(function (): void {
        const myItem: any = this;
        const myWidth: any = document.getElementById(myItem.id)?.getBoundingClientRect().width;
        d3.select('#circle_' + myItem.id).attr('cx', legendX);
        d3.select(myItem).attr('x', legendX + 5 + 8);
        legendX += myWidth + 5 * 2 + 25;
      });
      legendX -= 5 * 2;

      legendGroup.attr('transform', 'translate(' + (margins.leftExtra + ((width - margins.leftExtra) - legendX)/2) + ',0)');

      d3.select('#legendBackgroundRect' + myClass)
        .attr('width', legendX + 5)
        .attr('height', 20)
        .attr('rx', 10)
        .attr('ry', 10)
        .attr('fill', 'transparent')
        .attr('stroke', '#E8EAEE')
        .attr('stroke-width', '1.5px')
        .attr('transform', 'translate(' + (margins.leftExtra + ((width - margins.leftExtra)  - legendX)/2) + ',0)');
    }

      function formatData(){

        myChart.ganttChartData.map((m: any) => m[(myChart.props.transactionTypeVar).toLowerCase()] === m[(myChart.props.transactionTypeVar).toLowerCase()]);

      const hierarchyLevels = myChart.props.levels;

      const buildHierarchyString = (d: any) => {
        return d[hierarchyLevels[0]] + ":" + d[hierarchyLevels[1]] + ":" + d[hierarchyLevels[2]] + ":" + d[hierarchyLevels[3]] + ":" + d[hierarchyLevels[4]] + ":" + d[hierarchyLevels[5]]
      }
      let levelsSet: any = new Set();
      myChart.ganttChartData.forEach((d: any) => {
        levelsSet.add(buildHierarchyString(d));
      })
      levelsSet = Array.from(levelsSet);
      myChart.ganttChartData.map((m: any) => m.allHierarchyIndex = levelsSet.findIndex((f: any) => f === buildHierarchyString(m)))
    //adding temp hierarchy index so parents will map properly
        //long term solution with Arshad
        //so I am sure there is a more efficient way of doing this without repeating the code but
      //I started by going through it step by step to ensure I was being accurate.
      let hierarchy: any = {"name": "root", "children":[], rootParent: "root", descendantIndex: 0};
      //filter out non-status transactions
      let hierarchyData = myChart.ganttChartData.filter((f: any) => f[myChart.props.transactionTypeVar] === "status");
      const transactionData = myChart.ganttChartData.filter((f: any) => f[myChart.props.transactionTypeVar] !== "status");

      //sort hierarchy data by TransactionDate
      hierarchyData = hierarchyData.sort((a: any, b: any) => d3.ascending(a.allHierarchyIndex, b.allHierarchyIndex) || d3.ascending(a.TransactionDate, b.TransactionDate));
      //calculate the endDate - the first TransactionDate > this one (last will be undefined)
      let currentIndex = 1;
      //get the Level0Set
      let level0Set: any = new Set();

      hierarchyData.forEach((d: any) => level0Set.add(d[hierarchyLevels[0]]));
      level0Set = Array.from(level0Set);
      level0Set.forEach((d: any) => {
        //loop through
        //filter the data for this entry
        let filteredData = hierarchyData.filter((f: any) => f[hierarchyLevels[0]] === d);
        //sort
        filteredData = filteredData.sort((a: any, b: any) => d3.ascending(a.TransactionDate, b.TransactionDate));
        //add to root (calculating startDate + endDate (used when children not expanded)
        const myRoot: any = {
          name: d,
          children: [],
          startDate: d3.min(filteredData, (t: any) => t.TransactionDate),
          endDate: d3.max(filteredData, (t: any) => t.endDate),
          rootParent: d,
          descendantIndex: currentIndex,
          type: 'default'
        };
        currentIndex += 1;
        //repeat the process for level1
        let level1Set: any = new Set();
        filteredData.forEach((f: any) => level1Set.add(f[hierarchyLevels[1]]));
        level1Set = Array.from(level1Set).filter((f: any) => f !== null);
        level1Set.forEach((l: any, i: any) => {
          //filtering the level0 data for this level1 entry
          let filteredData1 = filteredData.filter((f: any) => f[hierarchyLevels[1]] === l);
          filteredData1 = filteredData1.sort((a: any, b: any) => d3.ascending(a.TransactionDate, b.TransactionDate));
          myRoot.children.push({
              name: l,
              children: [],
              startDate: d3.min(filteredData1, (t: any) => t.TransactionDate),
              endDate: d3.max(filteredData1, (t: any) => t.endDate),
              rootParent: d,
              descendantIndex: currentIndex,
              type: 'default'
          })
          currentIndex += 1;
          //and again for level 2
          let level2Set: any = new Set();
          filteredData1.forEach((f: any) => level2Set.add(f[hierarchyLevels[2]]));
          level2Set = Array.from(level2Set).filter((f: any) => f !== null);
          level2Set.forEach((l2: any, i2: any) => {
            let filteredData2 = filteredData1.filter((f: any) => f[hierarchyLevels[2]] === l2);
            filteredData2 = filteredData2.sort((a: any, b: any) => d3.ascending(a.TransactionDate, b.TransactionDate));
            myRoot.children[i].children.push({
              name: l2,
              children: [],
              startDate: d3.min(filteredData2, (t: any) => t.TransactionDate),
              endDate: d3.max(filteredData2, (t: any) => t.endDate),
              rootParent: d,
              descendantIndex: currentIndex,
              type: 'default'
            })
            currentIndex += 1;
            //and level 3
            let level3Set: any = new Set();
            filteredData2.forEach((f: any) => level3Set.add(f[hierarchyLevels[3]]));
            level3Set = Array.from(level3Set).filter((f: any) => f !== null);
            level3Set.forEach((l3: any, i3: any) => {
              let filteredData3 = filteredData2.filter((f: any) => f[hierarchyLevels[3]] === l3);
              filteredData3 = filteredData3.sort((a: any, b: any) => d3.ascending(a.TransactionDate, b.TransactionDate));
              myRoot.children[i].children[i2].children.push({
                name: l3,
                children: [],
                startDate: d3.min(filteredData3, (t: any) => t.TransactionDate),
                endDate: d3.max(filteredData3, (t: any) => t.endDate),
                rootParent: d,
                descendantIndex: currentIndex,
                type: 'default'
              })

              currentIndex += 1;
              //then for level 4
              let level4Set: any = new Set();
              filteredData3.forEach((f: any) => level4Set.add(f[hierarchyLevels[4]]));
              level4Set = Array.from(level4Set).filter((f: any) => f !== null);
              level4Set.forEach((l4: any, i4: any) => {
                let filteredData4 = filteredData3.filter((f: any) => f[hierarchyLevels[4]] === l4);
                filteredData4 = filteredData4.sort((a: any, b: any) => d3.ascending(a.TransactionDate, b.TransactionDate));
                if(filteredData4.length > 0 && filteredData4[0][hierarchyLevels[5]] === null){
                  currentIndex += 1;
                  filteredData4.forEach((f: any, fi: any) => {
                    f.name = f[hierarchyLevels[4]]
                    f.startDate = f.TransactionDate
                    f.rootParent = d;
                    f.descendantIndex = currentIndex;
                    f.type = f.record_typ;
                    currentIndex += 1;
                    if(f.name !== null){
                      myRoot.children[i].children[i2].children[i3].children.push(f)
                    }
                  })
                } else {
                  myRoot.children[i].children[i2].children[i3].children.push({
                    name: l4,
                    children: [],
                    startDate: d3.min(filteredData4, (t: any) => t.TransactionDate),
                    endDate: d3.max(filteredData4, (t: any) => t.endDate),
                    rootParent: d,
                    descendantIndex: currentIndex,
                    type: 'default'
                  })
                  currentIndex += 1;
                  //the final level is just filteredData4..
                  //add name and startDate for consistency across hierarchy descendants
                  filteredData4.forEach((f: any, fi: any) => {
                    f.name = f[hierarchyLevels[5]]
                    f.startDate = f.TransactionDate
                    f.rootParent = d;
                    f.descendantIndex = currentIndex;
                    f.type = f.record_typ;
                    currentIndex += 1;
                    if(f.name !== null){
                      myRoot.children[i].children[i2].children[i3].children[i4].children.push(f)
                    }
                  })
                }

              })
            })
          })
        })
        hierarchy.children.push(myRoot);
      })
      hierarchy = d3.hierarchy(hierarchy);
      hierarchy.descendants().map((m: any) => m.rootIndex =  m.depth === 1 ? m.data.descendantIndex : hierarchy.descendants().find((f: any) => f.data.name === m.data.rootParent).data.descendantIndex);
      hierarchy.descendants().map((m: any) => m.childIds =  m.descendants().map((c: any) => c.data.descendantIndex));
      hierarchy.descendants().map((m: any) => m.data.duration =  d3.timeDay.count(m.data.startDate, m.data.endDate));
      hierarchy.descendants().map((m: any) => m.colors = m.data.type === "NEXT_FILL"? (m.data.startDate < new Date () ? "#E34071" : "#EAE0FF" )
        : myChart.props.groupColours[m.data.rootParent]  === undefined ? myChart.props.groupColours['default'] : myChart.props.groupColours[m.data.rootParent])

        hierarchy.descendants().forEach((d: any) => {
        let statusCodeSet: any = new Set();
        d.childIds.forEach((c: any) => {
          const myChild = hierarchy.descendants().find((f: any) => f.data.descendantIndex === c);
          statusCodeSet.add(myChild.data.statuscodes)
        })
        d.statuscodes = Array.from(statusCodeSet).filter(f => f !== undefined);
      })
        //now pull out the milestones and transactions and link to descendants index
      const flags: any = [];
      transactionData.forEach((d: any, i: any) => {
        const myParent: any = hierarchy.descendants().find((f: any) => f.data.transactionid === d.parenttransactionid);
        const refillTooSoon: any = myChart.refillData?.find((f: any) => f.second_transaction_id === d.transactionid);
         if(myParent === undefined){
          console.log('this flag has no matching parent', d)
        } else {
          d.parentDepth = myParent.depth;
          d.parentId = myParent.data.descendantIndex;
          d.rootId = myParent.rootIndex;
          d.name = d.transactionname === null ? d[myChart.props.transactionTypeVar] : d.transactionname
          d.flagId = 'flag' + i
          d.refillTooSoon = refillTooSoon === undefined ? null : refillTooSoon.first_transaction_id
          flags.push(d)
        }
      })

        flags.forEach((m: any) => m.refillTooSoon = m.refillTooSoon === null ? null : flags.find((f: any) => f.transactionid === m.refillTooSoon).flagId);

      return {chartData: hierarchy, flagData: flags};

    }

  }

  initiateCharts(): void {
    // only need to call this once on initialisation
    const myClass = this.divId;
    const mySvg = d3.select('#' + myClass)
        .append('svg')
        .attr('id', 'svg_' + myClass)
        .attr('width', '100%')
        .attr('height', 0)
        .style('background-color', 'white');

    //header chart
    mySvg.append('text').attr('class', 'headerTitle' + myClass);
    mySvg.append('text').attr('class', 'headerTitleValue' + myClass);
    mySvg.append('rect').attr('class', 'headerBackgroundRect' + myClass);

    //main chart
    mySvg.append('rect').attr('class', 'chartBackgroundRect' + myClass);
    mySvg.append('rect').attr('class', 'brushBackgroundRect' + myClass);
    mySvg.append('g').attr('id', 'xAxis' + myClass);

    const chartGroup = mySvg.append('g').attr('id', 'chartGroup' + myClass);
    chartGroup.append('g').attr('id', 'xAxisChart' + myClass);
    chartGroup.append('g').attr('id', 'xAxisChartLabels' + myClass);
    chartGroup.append('line').attr('class', 'todayLine' + myClass);
    chartGroup.append('path').attr('class', 'todayLineTriangle' + myClass);
    mySvg.append('g').attr('id', 'brushChartGroup' + myClass);

    const brushGroup = mySvg.append('g').attr('id', 'brushGroup' + myClass);

    mySvg.append('line').attr('class', 'handleLines handleLeftLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines handleLeftLine2' + myClass);
    mySvg.append('line').attr('class', 'handleLines handleRightLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines handleRightLine2' + myClass);

    const defs = mySvg.append('defs');

    defs.append('clipPath').attr('id', 'ganttBrushClip')
      .append('rect').attr('id', 'ganttBrushClipRect');

    const filter = defs.append('filter').attr('id', 'drop-shadow').attr('width', 10).attr('height', 24);
    filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 1).attr('result', 'blur');
    filter.append('feOffset').attr('in', 'blur').attr('dx', 1).attr('dy', 1).attr('result', 'offsetBlur');
    filter.append('feFlood').attr('in', 'offsetBlur')
      .attr('flood-color', '#000000')
      .attr('flood-opacity', 0.4).attr('result', 'offsetColor');
    filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur');

    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

    mySvg.append('rect')
      .attr('id', 'legendBackgroundRect' + myClass);
  }

  findMonthBetweenDates(data: any) {
    // debugger
    // Initialize minDate and maxDate
    let minDate = new Date(data[0].transactiondate);
    let maxDate = new Date(); // Always set to the current date (current month)
  
    // Find minDate from the data
    data.forEach((item: any) => {
      const startDate = new Date(item.transactiondate);
  
      if (startDate < minDate) minDate = startDate;
    });
  
    // Function to calculate the difference in months
    function getMonthDifference(startDate: Date, endDate: Date) {
      return (
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        (endDate.getMonth() - startDate.getMonth())
      );
    }
  
    // Calculate the difference in months
    const monthDifference = getMonthDifference(minDate, maxDate);
    return monthDifference;
  }

  // Get Chart Data
  getGanttChartData() {
    this.ganttChartData = [];
    const obj = {
      api_key: 100104,
      report_typ: "D",
      client_key: this.clientKey || "",
      patient_id: this.patientId
    }

    this.loader({loader: true, data: this.ganttChartData});
    
    this.reqSubcription.push(this.filterService.executePatientQuery(obj).subscribe((resp: any) => {
      this.ganttChartData = resp ? resp : [];  // change it to resp for taking data from the API. or res.mainChart from Local
      if (this.ganttChartData.length>0) {
        // this.dataService.getJson('GanttChart').subscribe((res: any) => {
        this.dataService.chartData.next(this.ganttChartData)
        // this.headerChartData = res.headerChart;
        // this.refillData = res.refillData;
        const monthDifference = this.findMonthBetweenDates(this.ganttChartData)
        console.log('monthDifference',monthDifference)
        this.props = {
          "colors": {"dispense": "#8C23E6", "nurse call":"#E223E6","copay": "#FFCD4A", "testnotindata": "purple", "change": "#1468E1"},
          "programColors": ["#4AA8FF", "#1468E1", "#FF964A", "#fd6a00"],
          "transactionDateVar": "transactiondate",
          "transactionEndDateVar": "transactionenddate",
          "programVar": "program_nm",
          "startDateVar": "start_dt",
          "transactionTypeVar": "transactiontype",
          "endDateVar": "end_dt",
          "dispenseTransactionDateVar": "transaction_dt",
          "kpiVar": "program_nm",
          "newLegendSelections": this.selctedLegendData,
          "monthsVisibleStart":  monthDifference,// monthDifference, // this.monthsVisible
          "showTopCircleFlags": this.showCircleFlags == 'yes' ? true : false,
          "groupColours": { "HUB": "#ffaf4a", "SP": "#6332CA", "default": "#6332CA" },
          "expandCollapseAll": this.collapse,   //default is collapse, other option is expand
          "levels": ["level1", "level2", "level3", "level4", "level5", "level6"] //there MUST be 6 at the moment AND must match data
          // , otherwise will crash
        }
        setTimeout(() => {this.plotChart();}, 100);
      }
      this.loader({loader: false, data: this.ganttChartData});
    }, err=> {
      this.loader({loader: false, data: []});
    }))

  }

  // ------API Calls For Header Chart and Popup Data's---------//
  // Transaction Popup
  getTransactionPopupDetails(apiKey: any, report_type: any, id: any) {
    const obj = {
      api_key: apiKey || "",
      report_typ: report_type[0].toUpperCase(),
      client_key: this.clientKey || "",
      patient_id: this.patientId,
      transaction_id: id
    }
    this.reqSubcription.push(this.filterService.getTransactionData(obj).subscribe((resp: any) => {
      if (resp) {
        this.transactionData = (resp)
      }
      if(this.ganttChartData.length>0) {
        setTimeout(() => {this.plotChart();}, 100);
      }
    }))
  }

  // Programs Data for Header
  getProgramsData() {
    const obj = {
      api_key: 100105,
      report_typ: "D",
      client_key: this.clientKey || "",
      patient_id: this.patientId
    }
    this.reqSubcription.push(this.filterService.executePatientQuery(obj).subscribe((resp: any) => {
      if (resp) {
        this.headerChartData.program = resp
        if(this.ganttChartData.length>0) {
          setTimeout(() => {this.plotChart();}, 100);
        }
      }
    }))
  }

  // Dispense Data for Header
  getDispenseData() {
    this.selctedLegendData = []
    this.currentLegendSelections = []
    this.totalLegendData = []
    const obj = {
      api_key: 100106,
      report_typ: "D",
      client_key: this.clientKey || "",
      patient_id: this.patientId
    }
    this.reqSubcription.push(this.filterService.executePatientQuery(obj).subscribe((resp: any) => {
      if (resp) {
        this.headerChartData.dispense = resp
        this.headerChartData.dispense.forEach((i: any) => {
          if (i.type) {
            this.totalLegendData.push(i.type)
          }
        })
        this.unique = Array.from(new Set(this.totalLegendData.map((item: any) => item)))
        this.totalLegendData = this.unique
        let data = this.totalLegendData.map((i: any) => {
          if (i == 'Nurse Call') {
            return i.replace('Nurse Call', 'nursecall')
          } else return i.toLowerCase()
        })
        const uniqueArr = data.filter((value: any, index: any, self: any) => {
          return self.indexOf(value) === index;
        });
        this.selctedLegendData = uniqueArr
        this.totalLegendData = uniqueArr
        setTimeout(() => {
          this.initiateCharts();
          
          if(this.ganttChartData.length>0) {
            setTimeout(() => {this.plotChart();}, 100);
          }

        }, 1000)
      }
    }))
  }

  // KPI data for Header
  getkpiData() {
    const obj = {
      api_key: 100107,
      report_typ: "D",
      client_key: this.clientKey || "",
      patient_id: this.patientId
    }
    this.reqSubcription.push(this.filterService.executePatientQuery(obj).subscribe((resp: any) => {
      if (resp.length>0) {
        this.headerChartData.kpi = resp;
      }

      // setTimeout(() => {this.plotChart();}, 100);
      
    }))
  }

  // Refill Data for Chart
  getRefillData() {
    const obj = {
      api_key: 100112,
      report_typ: "D",
      client_key: this.clientKey || "",
      patient_id: this.patientId  //10000976
    }
    this.reqSubcription.push(this.filterService.executePatientQuery(obj).subscribe((resp: any) => {
      if (resp) {
        this.refillData = resp
        // this.plotChart();
        if(this.ganttChartData.length>0) {
          setTimeout(() => {this.plotChart();}, 100);
        }
      }
    }))
  }


  ngOnDestroy() {
    this.reqSubcription.forEach((res: any) => res.unsubscribe())
    this.ganttChartData = '';
    this.headerChartData = ''
    this.transactionName = '';
    this.clientKey = '';
    this.moduleKey = '';
    this.dashboardKey = '';
    this.patientId = '';
    this.transactionData = '';
    this.refillData = '';
    this.radarChartData = '';
    this.pendingInformation = [];
    this.pointerTooltipData = '';
    this.currentLegendSelections = []
    this.selctedLegendData = []
    this.totalLegendData = []
    this.dataService.expandCollapseChart.next(false);
    this.dataService.radarData.next('')

  }
}